import {useState} from 'react'

const StateSelect = ({selected, options, handleSelect}) => {
    const [isActive, setIsActive] = useState(false);
  
    const handleChange = (state) => {
      handleSelect(state)
    }
  
    return (
      <div className="select_me filter_drop">
        <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
          {selected}
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 9L12.5 15L18.5 9"
              stroke="#545454"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {isActive && (
          <div className="select_content" style={{zIndex : 999}}>
            {/* <h5>Duration</h5> */}
            {options.map((option) => (
              <div
                className="select_items"
                onClick={(e) => {
                  handleChange(option.state);
                  setIsActive(false);
                }}
              >
                {option.state}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  export default StateSelect;


export const LGASelect = ({selected, options, handleSelect}) => {
const [isActive, setIsActive] = useState(false);

const handleChange = (lga) => {
    handleSelect(lga)
}

return (
    <div className="select_me filter_drop">
    <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        </svg>
    </div>
    {isActive && (
        <div className="select_content" style={{zIndex : 999}}>
        {/* <h5>Duration</h5> */}
        {options.map((option) => (
            <div
            className="select_items"
            onClick={(e) => {
                handleChange(option);
                setIsActive(false);
            }}
            >
            {option}
            </div>
        ))}
        </div>
    )}
    </div>
);
};

export const EstateRoleSelect = ({ selected, options, handleSelect }) => {
  const [isActive, setIsActive] = useState(false);

  const handleChange = (role) => {
    handleSelect(role);
  };

  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected ? selected : options[0]}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content" style={{ zIndex: 999 }}>
          {/* <h5>Duration</h5> */}
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                handleChange(option);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


export const StreetSelect = ({ selected, options, handleSelect }) => {
  const [isActive, setIsActive] = useState(false);

  const handleChange = (role) => {
    handleSelect(role);
  };

  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected ? selected : options[0]}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content">
          {/* <h5>Duration</h5> */}
          <div
              className="select_items"
              onClick={(e) => {
                handleChange("others");
                setIsActive(false);
              }}
            >
              Others
            </div>
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                handleChange(option.name);
                setIsActive(false);
              }}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};