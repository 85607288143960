import { WithSuperAuth } from "../S-Overview";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { HOST_URL, reqOptions } from "../../../assets/js/helpFunction";
import { SVGs } from "../../../assets/svg/SVGs";
import { Link } from "react-router-dom";
import AdminCards from '../../../components/AdminCards'
import styled from "styled-components";

const EstateDetail = () => {
  const { estate_id } = useParams()
  const [estate, setEstate] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    const estates_api = `${HOST_URL()}estates/super_admin/${estate_id}/`;
    fetch(estates_api, requestOptions)
      .then(res => res.json())
      .then(response => {
        console.log(response)
        setEstate(response)
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <WithSuperAuth>
      {estate.detail ? (
        <div style={{ textAlign: "center" }}>
          <h1>Oops, Estate Not Found 😥</h1>
          <Link to={"/superadmin/estates"}>
            <button className="important-btn">
              Go Back
            </button>
          </Link>
        </div>
      ) : <div>
        <h4>{estate && estate.name} Info</h4>
        <OverviewRap className="fourcard_grid all_overview">
          <Card className="grid-row per_overview">
            <Link to={`/superadmin/estates/${estate_id}/residents`} className="mark">
              <img src={SVGs.users} alt="" />
              <div className="num">
                <p>Admins</p>
                <h3>{estate && estate.users_data?.all_admins}</h3>
              </div>
            </Link>
          </Card>
          <Card className="grid-row per_overview">
            <Link to={`/superadmin/estates/${estate_id}/residents`} className="mark">
              <img src={SVGs.overview_svg} alt="" />
              <div className="num">
                <p>Residents</p>
                <h3>{estate?.users_data?.all_residents}</h3>
              </div>
            </Link>
          </Card>
          <Card className="grid-row per_overview">
            <Link to={`/superadmin/estates/${estate_id}/residents`} className="mark">
              <img src={SVGs.visitors} alt="" />
              <div className="num">
                <p>Visitors</p>
                <h3>{estate?.users_data?.all_visitors}</h3>
              </div>
            </Link>
          </Card>
          <Card className="grid-row per_overview">
            <Link to={`/superadmin/estates/${estate_id}/residents`} className="mark">
              <img src={SVGs.security} alt="" />
              <div className="num">
                <p>Securities</p>
                <h3>{estate.users_data?.all_securities}</h3>
              </div>
            </Link>
          </Card>
        </OverviewRap>
      </div>}
      <DeleteEstateModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
    </WithSuperAuth>
  )
}

export default EstateDetail;


const DeleteEstateModal = ({ open, onClose }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // handleDelete()
    onClose()
  }
  if (!open) return null;
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Delete Estate</h3>
              </div>
            </div>
          </div>
          <button className="btn btn-success btn-block">Yes, Delete</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};


const OverviewRap = styled.div`
  .all_overview{
    justify-content: start!important;;
  }
  @media (min-width: 1320px) {
    .per_overview {
      width: 19.2%;
    }
  }
`;

let Card = styled.div`
  .mark {
    img {
      margin-bottom: 20px;
    }
    .num {
      display: flex;
      justify-content: space-between;
    }
  }
`;