export const Images = {
  logo: require("../images/estate_Logo.png"),
  img1: require("../images/img1.jpg"),
  img2: require("../images/img2.jpg"),
  img3: require("../images/img3.jpg"),
  img4: require("../images/img4.jpg"),
  img5: require("../images/img5.jpg"),
  banner: require("../images/banner.jpg"),
  banner2: require("../images/slide2.jpg"),
  banner3: require("../images/banner.png"),
  banner000: require("../images/banner4.jpg"),
  Makinde: require("../images/Makinde.jpg"),
  oyedotun: require("../images/oyedotun.jpeg"),
  Olaitan: require("../images/olaitan.jpeg"),
  Enable: require("../images/Enable.png"),
  App: require("../images/app.png"),
  baseey: require("../images/bassey.jpeg"),
  pelumi: require("../images/pelumi.jpeg"),
  john: require("../images/John.jpeg"),
  chartFrame: require("../images/chartFrame.png"),
  img6: require("../images/img6.jpg"),
  blog1: require("../images/blog1.jpg"),
  receiptBoard: require("../images/receiptBoard.png"),
  owosakin: require("../images/Owosakin.jpg"),
  communityuse: require("../images/website3.jpg"),
  estateDev: require("../images/website1.jpg"),
  selfManaged: require("../images/website2.jpg"),
  About1: require("../images/aboutPng.png"),
  About2: require("../images/about2.png"),
  dashBoardImg: require("../images/dashboardImg.png"),
  blog2: require("../images/blog2.jpg"),
  blog3: require("../images/blog3.jpg"),
  blog4: require("../images/blog4.jpg"),
  casual: require("../images/Casual-life.png"),
  casual2: require("../images/casual2.png"),
  carosel1: require("../images/carosel1.png"),
  testimonial_b: require("../images/testimonial-b.png"),
  feature1: require("../images/feature1.png"),
  LoginPreview: require("../images/login_preview.jpg"),
  talentQl: require("../images/talentQl.png"),
  enterprise: require("../images/enterprise.png"),
  // kidscancode: require("../images/kidscancode.png"),
  kidscancode: require("../images/kidscancode.gif"),
  about: require("../images/about.png"),
  eyowo: require("../images/eyowo.png"),
  andela: require("../images/andela.png"),
  about_process: require("../images/about_process.png"),
  piggyvest: require("../images/piggyvest.png"),
  andela: require("../images/andela.png"),
  yellowCard: require("../images/yellow_card.png"),
  individual: require("../images/individual.png"),
  kidsgif: require("../images/kidsgif.gif"),
  enterprise_landing: require("../images/enterprise_landing.png"),
  workspace: require("../images/workspace.png"),
  workstation: require("../images/workstation.png"),
  laptop: require("../images/laptop.png"),
  videogif: require("../images/videogif.gif"),
  on_phone: require("../images/on_phone.jpg"),
  DayOne: require("../images/Dayone.png"),
  empathy: require("../images/empathy.png"),
  Humility: require("../images/Humility.png"),
  Ownership: require("../images/Ownership.png"),
};
