import { React, useEffect } from "react";
import styled from "styled-components";

const TextField = (props) => {
  const showLabel = (e) => {
    if (e.value) e.parentElement.classList.add("value");
    else e.parentElement.classList.remove("value");
  };

  useEffect(() => {
    // Push up label if default value.
    if (props.defaultValue) {
      document.querySelectorAll('[data-default="true"]').forEach((e) => {
        showLabel(e);
      });
    }
  }, []);

  return (
    <div className="field">
      <Wrapper>
        <input
          type={props.types}
          name={props.names}
          required={props.required}
          readOnly={props.readOnly}
          // onKeyUp={(e) => {
          //   props.onKeyUp(e);
          // }}
          defaultValue={props.defaultValue}
          onChange={(e) => {
            showLabel(e.target);
            props.onChange(e);
          }}
          data-default={props.defaultValue ? true : false}
        />
        <span>{props.labels}</span>
      </Wrapper>
    </div>
  );
};

export default TextField;

const Wrapper = styled.div`
  position: relative;
  margin-top: 25px;

  span {
    position: absolute;
    top: 3px;
    left: 15px;
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 16px;
    color: #d3d3d3;
  }
  input:focus + span,
  &.value span {
    background: #ffffff;
    top: -10px;
    height: 20px;
    // border: 1px solid var(--Gunmetal);
    padding: 5px;
    z-index: 2;
    margin: 0;
    color: var(--Brand-color);
    font-size: 14px;
    pointer-events: initial;
    left: 15px;
    line-height: 10px;
  }
`;
