import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { SVGs } from "../../assets/svg/SVGs";
import ProfileType from "./E-ProfileType";
import { HOST_URL, SK_VALUE, getCookie, reqOptions } from "../../assets/js/helpFunction";
import { getEstateID } from "../partials/utils";


const CreateSOS = () => {
  const [loading, setLoading] = useState(false)
  const [localImage, setLocalImage] = useState(null)
  const [imageToUse, setImageToUse] = useState(null)
  const [inputValue, setInputValue] = useState({
    address: '',
    contact_email: '',
    contact_phone: '',
    description: '',
    title: '',
    type: '',
    estate_id: getEstateID()
  });

  const createEmergency = (data) => {
    const objectToArray = Object.entries(data)
    const formData = new FormData();
    if (imageToUse)
      formData.append('image', imageToUse)

    objectToArray.forEach(entries => {
      formData.append(entries[0], entries[1])
    })

    const requestOptions = {
      method: "post",
      headers: {
        "EstateIQ-Sk-Header": SK_VALUE(),
        "X-CSRFToken": getCookie("CSRF-TOKEN"),
        Origin: window.location.origin,
        "Authorization": "Bearer " + getCookie("access"),
      },
      body: formData
    }

    const api = `${HOST_URL()}estate_adverts/emergency/`;
    setLoading(true)
    fetch(api, requestOptions)
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const errResponse = await res.json()
          console.log('errResponse', JSON.stringify(errResponse))
          Swal.fire({
            title: JSON.stringify(errResponse),
            icon: "error",
            showConfirmButton: true,
            showCloseButton: true,
          });
          throw new Error()
        }
      })
      .then((dataReturned) => {
        console.log('dataReturned', dataReturned)
        Swal.fire({
          title: `<div>You created profile for <h4> ${data.title}</h4> </div>`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
        });
      })
      .catch((err) => { })
      .finally(() => setLoading(false))
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    createEmergency(inputValue)
  };

  const handleImage = image => {
    const encodeFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    encodeFileToBase64(image).then((res) => {
      console.log('res', Object.assign({ image: res }, image, {
        preview: URL.createObjectURL(image),
      }))
      setLocalImage(
        Object.assign({ image: res }, image, {
          preview: URL.createObjectURL(image),
        })
      )
    })
    return setImageToUse(image)
  }

  return (
    <form
      onSubmit={handleSubmit}
      // method="post"
      // action="/profile"
      className="new_estates_form fade-in-bck"
    >
      <div className="form-group">
        {/* <label htmlFor="title">Email address</label> */}

        <div className="create_est ">
          <div className="form_txt">
            <div className="capture_close">
              <h4>Create SOS emergency</h4>
            </div>
          </div>

          <div className="inputplabel">
            <label>Service flier with a dimension of 8.5 x 11 in (2550 x 3300 px)</label>
            <input
              type="file"
              id="imageChanger"
              onChange={e => handleImage(e.target.files[0])}
              accept="image/png, image/gif, image/jpeg"
            />
          </div>

          {localImage && (
            <div style={{ width: '100%', marginRight: 'autp', marginLeft: 'auto' }}>
              <img
                // className="inputplabel"
                style={{ width: 'auto', height: '200px', position: 'relative', right: 0, top: 0, bottom: 0 }}
                src={localImage.image} alt=""
              />
            </div>
          )}

          {/* Create Account Type */}
          <ProfileType selected={inputValue.type} setSelected={(val) => setInputValue(initValues => ({ ...initValues, type: val }))} />
          <input
            value={inputValue.title}
            onChange={e => setInputValue(initValues => ({ ...initValues, title: e.target.value }))}
            type="text"
            id="title"
            name="title"
            placeholder="Emergency Service Name"
          />

          <input
            value={inputValue.description}
            onChange={e => setInputValue(initValues => ({ ...initValues, description: e.target.value }))}
            type="text"
            id="title"
            name="title"
            placeholder="Emergency Service Details"
          />
          <input
            value={inputValue.address}
            onChange={e => setInputValue(initValues => ({ ...initValues, address: e.target.value }))}
            type="text"
            id="address"
            name="address"
            placeholder="Address"
          />
          <input
            value={inputValue.contact_phone}
            onChange={e => setInputValue(initValues => ({ ...initValues, contact_phone: e.target.value }))}
            type="tel"
            id="phone"
            name="phone"
            placeholder="Mobile"
          />
          <input
            value={inputValue.contact_email}
            onChange={e => setInputValue(initValues => ({ ...initValues, contact_email: e.target.value }))}
            type="email"
            id="email"
            name="email"
            placeholder="Email"
          />
        </div>
        <button disabled={loading} type='submit'>{loading ? 'Loading...' : 'Create Emergency'}</button>
      </div>
    </form >
  );
};

const Modal = ({ open, onClose, emergencyData, onFinish }) => {
  if (!open) return null;
  return (
    <ModalWrapper onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <CreateSOS emergencyData={emergencyData} onFinish={onFinish} />
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </ModalWrapper>
  );
};
export default Modal;

let ModalWrapper = styled.div`
  .modalContainer {
    max-width: 600px;
    .new_estates_form {
      max-width: 600px;
    }
  }
  .create_est {
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

let RevokeAccess = styled.div`
  .revokeOnes {
    height: 200px;
    overflow: auto;
  }
  h2 {
    font-size: 20px;

    font-weight: bold;
    margin-bottom: 0px;
  }
  .cntr {
    margin-top: 10px;
  }
`;
