import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";
import { genWhatsappLink, openInNewTab } from "./partials/utils";
import { Link } from "react-router-dom";

const Adverts = () => {
  const [data, setData] = useState({})
  const settings = {
    dots: true,
    margin: 10,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    //
    const api = `${HOST_URL()}estate_adverts/adverts/`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setData(data.results)
        console.log(data, 'adddsss')
      })
      .catch((err) => {});
  }, [])
  return (
    <HandleCarousel className="handleCarouselBanner">
          {data && data.length && (
          <Slider {...settings} className="handleCarousel">
            {data.map(ad => (
              <div>
              <PerSlide>
                <div className="handleImg">
                  <img
                    src={ad.image}
                    alt={ad.business_name}
                  />
                  <span>Advert</span>
                </div>
                <div className="handleText">
                  <h2>{ad.title}</h2>
                  <p>
                    {ad.description}
                  </p>
                  <button className="important-btn" onClick={() => openInNewTab(genWhatsappLink(ad.phone_number, ad.business_name))}>
                      Make Inquiry
                  </button>
                </div>
              </PerSlide>
            </div>
            ))}
            
          </Slider>
          )}
        </HandleCarousel>
  )
}

export default Adverts;

let HandleCarousel = styled.section`

  .slick-dots li button:before {
    font-size: 15px;
  }
  .slick-dots {
    left: 0;
    bottom: -5px !important;
    li {
      button {
        color: #92b0fc;
      }
    }
  }
  .handleCarousel {
    background: #ffffff;

    border: 2px solid #e1e1e1;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 50px;
  }
  .handleText {
    padding: 20px 15px;
    h2 {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 29px;
      letter-spacing: -0.06em;
      text-align: left;
    }
  }
`;
let PerSlide = styled.div`
  background: #f6f6f6;
  border: 1px solid #999999;
  border-radius: 5px;
  margin: 5px 10px;
  .handleImg {
    position: relative;
    height: 250px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
    }
    span {
      background: #ffffff;
      border: 1px solid #545454;
      border-radius: 5px;
      font-size: 18px;
      padding: 5px 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
