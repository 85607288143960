import React, { useEffect, useState } from "react";
import { SVGs } from "../../assets/svg/SVGs";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getCookie, HOST_URL, SK_VALUE } from "../../assets/js/helpFunction";
import { useParams } from "react-router-dom";
import { DelayedLink, getEstateID, getImage } from "../partials/utils";
let Notification = styled.div`
  position: relative;
  display: none;
  .n_badge {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    right: 0;
    top: 56px;
    width: 360px;

    border-radius: 8px;
    .all_notification {
      height: 360px;
      overflow: auto;
      padding: 10px 15px;
      .each_noti {
        padding: 10px 5px;
        border-bottom: 1px solid rgba(33, 33, 33, 0.15);
        padding-left: 60px;
        cursor: pointer;
        &:hover {
          background-color: #f3f3f3;
        }
        p {
          margin: 0;
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.03em;
          text-align: left;

          span {
            color: #2d4bf3;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.06em;
            text-align: left;
            margin-left: 8px;
            &:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              margin-right: 5px;
              background: #2d4bf3;
            }
          }
        }
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center !important;
      letter-spacing: 0em;
      padding: 12px;
      margin: 0 !important;
      background-color: #ffffff;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 0px 0px;
      text-align: left;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      background: #ffffff;
      height: 20px;
      border-radius: 3px;
      transform: rotate(45deg);
      top: -10px;
      position: absolute;
      right: 25px;
    }
  }
`;

const TopNav = ({user}) => {
  const [notify, setNotification] = useState("notification");
  const [derivatives, setDerivatives] = useState("");
  const [notifications, setNotifications] = useState([])

  let { estate_id } = useParams();

  useEffect(() => {
    if (!derivatives) {
      let myHeaders = new Headers();
      myHeaders.append("EstateIQ-Sk-Header", SK_VALUE());
      myHeaders.append("Authorization", "Bearer " + getCookie("access"));

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(HOST_URL() + "estate_users/user_list/", requestOptions)
        .then((response) => response.text())
        .then((result) => setDerivatives(JSON.parse(result)))
        .catch((error) => console.log("error", error));
        
      fetch(HOST_URL() + `estate_user_notifications/estate_users/?estate_id=${getEstateID()}`,requestOptions)
      .then(res => res.json())
      .then(data => {
        setNotifications(data.results)
      })
      .catch(err => console.log(err))
    }
  }, [estate_id, derivatives]);

  const handleNotification = () => {
    notify === "notification"
      ? setNotification("notification active")
      : setNotification("notification");
  };
  return (
    <div className="navbar_container">
      <div className="dashboard_container input_avatar">
        <h6></h6>
        <div className="violet">
          <img
            src={SVGs.notification}
            alt="Bell"
            onClick={handleNotification}
            className="note_bell"
          />
          <Notification className={notify} id="swing-in-top-fwd">
            <div className="n_badge">
              <h2>Notification</h2>
              <div className="all_notification">
                {notifications && notifications.lenggth ? notifications.map((item) => {
                  return (
                    <div
                      className="each_noti"
                      id={
                        item.types === "Message"
                          ? "messagesBg"
                          : "Alert"
                          ? "alertBg"
                          : "messagesBg"
                      }
                      key={item.id}
                    >
                      <p>{item.types}</p>
                      <h4>
                        {item.estate}
                        <span>{item.msg_id}</span>
                      </h4>
                    </div>
                  );
                }) : <p style={{textAlign : 'center'}}>No Notification Yet</p>}
              </div>
            </div>
          </Notification>
          <div className="user_mode">
            {user?.estate_user?.user ? (
              <Link to={`/admin/settings/`}>
              <div>
                <h3>
                  {user?.estate_user?.user?.first_name} {user?.estate_user?.user?.last_name}
                </h3>
                <p>
                  Admin
                </p>
              </div>
              </Link>
            ) : (
              ""
            )}
            <SwitchAccount className="wraperImg">
              <Link to={`/admin/settings/`}>
                <img
                  src={user && getImage(user.profile_image)}
                  alt={user?.estate_user?.user?.first_name}
                />
              </Link>
              
              <div className="switchAccount">
                <ul>
                  <h5>Manage Accounts</h5>

                  {/* Add click event to switch to user with id "EIQ123" */}
                  {derivatives && derivatives.results.slice(0, 2).map((derivative) => (
                    <DelayedLink key={derivative.id} delay={1000} estate_id={derivative.estate.estate_id} to={`/admin/`}>
                      <div className="clickable">
                        <h2>{derivative.estate.name}</h2>
                        <p>{derivative.estate.estate_id}</p>
                      </div>
                    </DelayedLink>
                  ))}
                  <Link to={"/admin/-/estates"}>View all Estates</Link>
                </ul>
              </div>
            </SwitchAccount>
            {/* <Link to="/admin/settings">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
          </Link> */}
          </div>
        </div>
      </div>
    </div>
  );

};

export default TopNav;
let SwitchAccount = styled.div`
position: relative;

  &:hover{
    .switchAccount{
      display: block;
    }
  }

.switchAccount{
  position: absolute;
  background: #fff;
  width: 213px;
  right: 0px;
  display: none;
  padding: 20px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  h5{
    font-family: Satoshi;
font-size: 14px;
font-weight: 700;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 10px;
color: #1737E6;
transform: scale(1);


  }
}
    ul {
      li {
      }
      .clickable {
        background: #F6F6F6;
border: 1px solid #999999;
border-radius: 5px;
padding: 10px;
margin-bottom: 13px;
transition: .4s;
cursor: pointer;
&:hover{
  transform: scale(.98);
}
        h2 {
          color: #111111;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
        }
        p{
          margin: 0;
          background: none;
          text-align: left;
          font-weight: 400;
font-size: 14px;


color: #545454;
        }
      }
    }
  }
`;
