import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminProfile from "../../components/AdminProfileTest";
import AdminEmail from "../../components/AdminEmail";
import AdminPassword from "../../components/AdminPassword";
import AdminNotice from "../../components/AdminNotification";
import { WithResidentAuth } from "../Residents/Dashboard";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { getEstateID, MssgContainer } from "../../components/partials/utils";

const ResidentsSettings = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) => activeIndex === index ? className : "";
  const [userdata, setUserData] = useState({});
  const [mssg, setMssg] = useState({error : false, mssg : ""})
  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }
  

  useEffect(() => {
    const req = reqOptions("GET", null, 'access');
        fetch(
          HOST_URL() + `estate_users/estate_user_detail/?estate_id=${getEstateID()}`,
          req
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data, 'sdhdshdshdsh')
            setUserData(data)
          })
          .catch((error) => setMssg({...mssg, mssg : error.message, error : true}));
  }, [])

  

  return (
    <WithResidentAuth>
      <profileContainer>
        <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
          <HandleSearchAndTab>
            <div className="tabs">
              <span
                className={`tab ${checkActive(1, "active")}`}
                onClick={() => handleClick(1)}
              >
                <button>Profile</button>
              </span>

              <span
                className={`tab ${checkActive(2, "active")}`}
                onClick={() => handleClick(2)}
              >
                <button>Email</button>
              </span>
              <span
                className={`tab ${checkActive(3, "active")}`}
                onClick={() => handleClick(3)}
              >
                <button>Password</button>
              </span>
              {/* <span
                className={`tab ${checkActive(4, "active")}`}
                onClick={() => handleClick(4)}
              >
                <button>Notification</button>
              </span> */}
            </div>
          </HandleSearchAndTab>
        </div>

        <div className="panels">
        {userdata && (
            <>
            <div className={`panel ${checkActive(1, "active")}`}>
              <AdminProfile userdata={userdata} setUserData={setUserData} setMssg={setMssg}/>
            </div>
            <div className={`panel ${checkActive(2, "active")}`}>
              <AdminEmail userdata={userdata} setUserData={setUserData} setMssg={setMssg}/>
            </div>
            <div className={`panel ${checkActive(3, "active")}`}>
              <AdminPassword setMssg={setMssg} />
            </div>
            {/* <div className={`panel ${checkActive(4, "active")}`}>
              <AdminNotice />
            </div>
            <div className={`panel ${checkActive(5, "active")}`}>
              Packages COmes here
            </div> */}
            </>
          )}
          
        </div>
      </profileContainer>
    </WithResidentAuth>
  );
};

export default ResidentsSettings;

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;

    .tab {
      margin: 0;
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 10px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 5px 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
