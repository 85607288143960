import "./chart.css";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Chart({
  title,
  data,
  EssentialPlan,
  FreePlan,
  Standard,
  Premium,
  grid,
}) {
  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={4 / 2}>
        <LineChart data={data}>
          <XAxis dataKey="month" stroke="#5550bd" />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="ACTIVE_USER"
            stroke="#2c4af3"
          />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="ADMIN"
            stroke="#FF0000"
          />
          {/* <Line
            strokeWidth={2}
            type="monotone"
            dataKey="EXTERNAL"
            stroke="#39E040"
          /> */}
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="HOUSEHOLD"
            stroke="#F2CD16"
          />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="RESIDENT"
            stroke="brown"
          />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="SECURITY"
            stroke="purple"
          />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="VENDOR"
            stroke="black"
          />
          <Line
            strokeWidth={2}
            type="monotone"
            dataKey="STAFF"
            stroke="#617504"
          />
          <Tooltip />
          {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
