import { Link } from "react-router-dom"
import { HOST_URL, reqOptions } from "../../../assets/js/helpFunction"
import { WithSuperAuth } from "../S-Overview"
import {useEffect, useState} from "react"

const Adverts = () => {
    const [adverts, setAdverts] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const requestOptions = reqOptions("get", null, true);
        const estates_api = `${HOST_URL()}estate_adverts/adverts/`;
        fetch(estates_api, requestOptions)
        .then(res => res.json())
        .then(response => {
            setLoading(false)
            setAdverts(response.results)
        })
        .catch(err => {
            setLoading(false)
        })
    }, [])
    return (
        <WithSuperAuth>
            <h2>Adverts</h2>
            <div className="threecards_grid estate_cards">

            {adverts && adverts.length ? adverts.map(advert => (
                <div className="per_estate" key={advert.id}>
                    <img src={advert.image} style={{maxHeight : "200px"}}/>
                    <div className="">
                        <h4>{advert.title}</h4>
                        <p style={{textAlign : "justify"}}>{advert.description}</p>
                    </div>
                </div>
            )) : loading ? <p>Loading......</p> : <p>No advert yet</p>}
            </div>

        </WithSuperAuth>
    )
}

export default Adverts;