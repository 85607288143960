import { useState } from "react";
import styled from "styled-components";
import TextField from "./form/TextField";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminPassword = ({setMssg}) => {
  const [passwords, setPasswords] = useState({
    password: "",
    verify_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const api = `${HOST_URL()}auth/set_password/`;
    const requestOptions = reqOptions("post", JSON.stringify(passwords), true);
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setMssg({mssg : data.message})
      })
      .catch((err) => setMssg({mssg : err.message, error : true}));
  };
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });

  };
  return (
    <AdminPasswordForm onSubmit={handlePasswordSubmit}>
      <p>Enter New password to change password</p>
      {/* <TextField
          types="password"
          labels="Current Password"
          names="current_password"
          onChange={handlePasswordChange}
        /> */}
      <TextField
        types={showPassword ? "text" : "password"}
        labels="New Password"
        names="password"
        onChange={handlePasswordChange}
      />
      <div style={{ position: "relative" }}>
        <TextField
          types={showPassword ? "text" : "password"}
          labels="Verify Password"
          required={true}
          // defaultValue={formData.password}
          names="verify_password"
          onChange={handlePasswordChange}
        />
        <p
          style={{ position: "absolute", cursor: "pointer", top: 7, right: 20 }}
          onClick={togglePasswordVisibility}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </p>
      </div>

      <div className="action-btn">
        <button type="submit" className="important-btn" disabled={!(passwords.password && (passwords.password === passwords.verify_password))}>
          Change Password
        </button>
      </div>
    </AdminPasswordForm>
  );
};

export default AdminPassword;

let AdminPasswordForm = styled.form`
  max-width: 530px;
  p {
    margin-bottom: 30px;
  }
  input {
    margin-bottom: 15px;
  }
`;
