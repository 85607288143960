import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box, Center, Stack, Text, SimpleGrid, Image, Heading } from '@chakra-ui/react';
import appLogo from '../../assets/images/estate_Logo.png';
import CustomAccordion from './Accordion';


const FAQComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);


  const sections = [
    {
      title: 'About EstateIQ',
      id: 'about',
      faqContents: [
        {
          question: 'What is EstateIQ?',
          answer: (
            <Box>
              <Text>EstateIQ is a digital solution designed and
                developed to help residents and estate
                managers of gated communities attain higher
                levels of convenience and efficiency. It is also
                a simple-to-use app, you don’t have to be a
                techie to use. Regardless of age, education
                level, and occupation, users find the EstateIQ
                app easy to use.
                The EstateIQ Residents app and EstateIQ
                Admin app offer users five modules for
                intended actions and results.</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  <p>Access Control</p>
                  <p>EstateIQ offers a secure, convenient, flexible
                    way of controlling who has access to your
                    building and when access is allowed.</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p>Billing and Payment System</p>
                  <p>The collection of fees and dues is critical to
                    maintaining infrastructure. EstateIQ makes
                    payments convenient, and collections
                    seamless.</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p>Residents Collaboration</p>
                  <p>EstateIQ enables collaboration among
                    residents as well as with the estate board.</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p>Safety and Security</p>
                  <p>Security is among the most notable
                    considerations when people opt for gated
                    communities. Estate managers can support
                    and strengthen their security architecture with
                    EstateIQ.</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p>Estate Secretariat/Board</p>
                  <p>The estate office can enhance productivity
                    and ensure effective communications with
                    residents and vendors.</p>
                </li>
              </ul>
            </Box>
          )
        },
        {
          question: 'What are the benefits of creating an EstateIQ account?',
          answer:
            <Box>
              <Text>If you create an account, you will be able to:</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  As an estate admin, you will be able to
                  onboard residents of your estate so that they
                  can enjoy the numerous services that come
                  with using the app – enjoying an additional
                  layer of security and keeping your community
                  informed and engaged with real-time
                  communication capabilities.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  As a resident, you will be able to enjoy an
                  additional layer of convenience like cutting
                  down waiting times and minimizing human
                  errors.
                </li>
              </ul>
            </Box>
        },
        {
          question: 'How can I get the EstateIQ app?',
          answer:
            <Box>
              <Text>EstateIQ is currently available for download on
                Google Play Store and App Store. It is a
                lightweight mobile application that runs
                seamlessly on Android and iOS platforms and
                is intuitive to use. We have also provided a
                one-minute video to show you around the
                EstateIQ world. Click the link here.</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  The EstateIQ app can also be launched directly
                  from its website (www.estateiq.ng). The web
                  app has been designed to operate like a
                  mobile app. With this option, it can always be
                  used, just remember to save the URL on the
                  homepage of your device so that you can
                  always use it again.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  In both cases, an active internet connection is
                  required to complete the onboarding process,
                  as well as using the app
                </li>
              </ul>
            </Box>
        },
        {
          question: 'What Is Required to Join an Estate on the EstateIQ app?',
          answer:
            <Box>
              <Text>A unique EstateIQ ID for your estate is
                to be provided by your Estate Admin,
                otherwise, you will not be able to
                initiate the onboarding process.</Text>
            </Box>
        },
        {
          question: 'What is required for the EstateIQ app to work on my device?',
          answer:
            <Box>
              <Text>Regardless of the subscription package that
                your estate has subscribed to; paid or free,
                you will need to have downloaded from Play
                Store or Appstore and installed the app on
                your mobile device. You will also need a
                functional internet connection. You will need to
                have the latest version of the app.</Text>
            </Box>
        },
      ]
    },
    {
      title: 'First Things, First',
      id: 'first',
      body: `The first step to enjoying a whole new world
      that EstateIQ offers is to create an account,
      after downloading the app from Google Play
      Store and iOS App Store.`,
      faqContents: [
        {
          question: 'Do I need to create an account to use EstateIQ?',
          answer: (
            <Box>
              <Text>Yes, all users must create accounts for EstateIQ
                services. It is important that you provide a valid
                email address which is used to authenticate
                your request to create an EstateIQ account
                before you use the services. We also use this
                email to dispatch only EstateIQ-related
                notifications.</Text>
            </Box>
          )
        },
        {
          question: 'How do I create an EstateIQ account?',
          answer:
            <Box>
              <Text>Quickly create an account by clicking ‘SIGN UP’
                above and then ‘Create account’ in the pop-up
                box that follows.</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  As an estate admin, download the EstateIQ
                  Admin app from the Google Play Store or iOS
                  Appstore. Provide the basic admin and estate
                  information.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  As an estate resident, download the EstateIQ
                  Resident app from the Google Play store or
                  Appstore. Provide the basic admin and estate
                  information. A unique EstateIQ ID for your estate
                  is to be provided to you by your Estate Admin,
                  otherwise, you will not be able to complete the
                  account creation process.
                </li>
              </ul>
            </Box>
        },
        {
          question: 'How do I change/reset my EstateIQ account password?',
          answer:
            <Box>
              <Text>You can reset your password using the ‘forgot
                password’ link on the Sign in page.</Text>
            </Box>
        },
        {
          question: 'How do I sign in/out?',
          answer:
            <Box>
              <Text>You can sign out by clicking on the 'Log out' link,
                which appears when you open the profile page
                at the bottom left of the page.</Text>
            </Box>
        },
        {
          question: 'I am having trouble logging in, what do I do?',
          answer:
            <Box>
              <Text>If you are having trouble logging into your
                account, please check that 'Caps Lock' is not
                enabled – all passwords are case-sensitive. If
                that doesn’t work, click ‘‘forgot password” and
                an OTP will be emailed to your registered
                address for you to reset your password.</Text>
            </Box>
        },
        {
          question: 'How do I update my account details/information?',
          answer:
            <Box>
              <Text>You can update your personal bio and contact
                info details when you open the profile page.</Text>
            </Box>
        },
        {
          question: 'I am having trouble using my account for access code and other services, what do I do? (Authorization & Authentication)',
          answer:
            <Box>
              <Text>If you are unable to generate an access code
                or use other services, it is likely that your
                account is yet to be validated by your estate
                admin (please contact your admin) or you are
                yet to set your address on the personal bio
                page. Please note that without your contact
                address, you will not be able to generate
                access codes for your guests.</Text>
            </Box>
        },
      ]
    },
    {
      title: 'Modules',
      body: `The EstateIQ app offers users five modules for
      intended actions and results; namely, Access
      Control, Billing & Payment System, Residents
      Collaboration, Safety & Security and Estate
      Board.`,
      id: 'modules',
      faqContents: [
        {
          question: 'How do I generate an access code?',
          answer: (
            <Box>
              <Text>Click the “Generate Access Code” tab on the
                dashboard and select your preferred type of
                code. There are three choices, depending on
                the situation, namely:</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  <p><b>Private:</b> If you want to generate an access
                    code for planned visits to your home or office
                    situated within the estate,</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p><b>Business:</b> To generate exit passcodes for
                    customers or shoppers that visit stores within
                    the estate. In some cases, some estates do not
                    allow exit if shoppers or customers are unable
                    to provide exit passcodes from the stores that
                    they have visited.</p>
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  <p><b>Waybill:</b> Generate waybill numbers for items
                    that require authorization inbound and/or
                    outbound from the estate.</p>
                </li>
              </ul>
            </Box>
          )
        },
        {
          question: 'What do I need to know about the codes?',
          answer:
            <Box>
              <ol>
                <li style={{ listStyleType: 'disc' }}>
                  How long does it take to generate a code?

                  It takes less than 60 seconds to generate and
                  share the codes with your guests.
                  Please make sure that the guest information is
                  filled out correctly; these details are also used
                  for security checks by your estate's security
                  staff. If you don't do this correctly, your guests
                  may have trouble getting through if their
                  answers don't match what you provided.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  Is the access code reusable?
                  No, the access code once it has been used is
                  not reusable.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  Can an access code be revoked?
                  Yes, the access code, if it has not been used,
                  can be revoked before it is verified by the
                  security operatives.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  What could be the possible causes if I am
                  unable to generate access codes?
                </li>
              </ol>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  It is likely that your account has not been
                  activated by your estate admin. If you have just
                  created a new EstateIQ account, please
                  contact your estate admin.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  It is likely that your account has been
                  deactivated by your estate admin, in the case
                  of a security breach traceable to the use of
                  your account or in the case of defaulting on
                  your obligations as a member of your estate.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  It is likely that you do not have an active
                  internet connection.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  It is likely that you have not filled out your home
                  address on the profile page. You will not be
                  able to generate an access code or exit pass if
                  you have not correctly completed your home
                  address.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  It is also possible that you entered the wrong
                  dates and times for the guest’s arrival and
                  departure. Please note that the arrival date
                  and time cannot be earlier than the current
                  date and time, and neither can the departure
                  date and time be earlier than the arrival date
                  and time.
                </li>
              </ul>
            </Box>
        },
        {
          question: 'How can I get the EstateIQ app?',
          answer:
            <Box>
              <Text>EstateIQ is currently available for download on
                Google Play Store and App Store. It is a
                lightweight mobile application that runs
                seamlessly on Android and iOS platforms and
                is intuitive to use. We have also provided a
                one-minute video to show you around the
                EstateIQ world. Click the link here.</Text>
              <ul>
                <li style={{ listStyleType: 'disc' }}>
                  The EstateIQ app can also be launched directly
                  from its website (www.estateiq.ng). The web
                  app has been designed to operate like a
                  mobile app. With this option, it can always be
                  used, just remember to save the URL on the
                  homepage of your device so that you can
                  always use it again.
                </li>
                <li style={{ listStyleType: 'disc' }}>
                  In both cases, an active internet connection is
                  required to complete the onboarding process,
                  as well as using the app
                </li>
              </ul>
            </Box>
        },
        {
          question: 'What Is Required to Join an Estate on the EstateIQ app?',
          answer:
            <Box>
              <Text>A unique EstateIQ ID for your estate is
                to be provided by your Estate Admin,
                otherwise, you will not be able to
                initiate the onboarding process.</Text>
            </Box>
        },
        {
          question: 'What is required for the EstateIQ app to work on my device?',
          answer:
            <Box>
              <Text>Regardless of the subscription package that
                your estate has subscribed to; paid or free,
                you will need to have downloaded from Play
                Store or Appstore and installed the app on
                your mobile device. You will also need a
                functional internet connection. You will need to
                have the latest version of the app.</Text>
            </Box>
        },
      ]
    },
    {
      title: 'Marketplace',
      id: 'marketplace',
      faqContents: []
    },
  ]


  return (
    <Box pt='150px' pb='50px' w='full' mx='auto' bg={'#F0F6F9'} py={{ base: '0', sm: '10px', md: '20px' }}>
      <Center w='full' maxW='984px' mx='auto'>
        <Stack w='100%' align='center'>
          <Text fontWeight={700} color={'#1737e6'} fontSize={'50px'} mb='100px'>Table of Contents</Text>
          <SimpleGrid columns={2} columnGap='50px' rowGap={'20px'}>
            {sections.map(section => (
              <a href={`#${section.id}`}>
                <Box w='full'>
                  <Box p='20px' borderRadius={'25px'} bg='#fff'>
                    <Image w='150px' h='auto' src={appLogo} />
                  </Box>
                  <Text fontSize={'16px'} fontWeight={500} collr='#191919' textAlign={'center'}>{section.title}</Text>
                </Box>
              </a>
            ))}
          </SimpleGrid>
          {sections.map(section => (
            <Stack id={section.id} align='center' w='100%' my={{ base: '32px', md: '56px' }} mx='auto' mt='100px'>
              <Heading fontWeight={600}>{section.title}</Heading>
              <Text>{section?.body}</Text>
              {section.faqContents.map((faq, i) => (
                <CustomAccordion
                  key={i}
                  headerHoverStyle={{ bg: '#fff', borderRadius: '5px', }}
                  bg={'#fff'}
                  h='fit-content'
                  header={
                    <Box
                      color={'#1D1D1D'}
                      fontSize={{ base: '18px', md: '24px' }}
                      fontWeight='500'
                      lineHeight='24px'
                      as='span'
                      flex='1'
                      textAlign='left'
                      px='20px'
                      pt='20px'
                    >
                      {faq.question}
                    </Box>
                  }
                >
                  <Stack spacing={4} px={4} pb={4}>
                    <Text
                      px='20px'
                      color={'#000'}
                      fontSize='18px'
                      fontWeight='400'
                      lineHeight='30px'
                      data-aos='fade-up'
                      data-aos-offset='180'
                      data-aos-easing='ease-in-sine'
                    >
                      {faq.answer}
                    </Text>
                  </Stack>
                </CustomAccordion>
              ))}
            </Stack>
          ))}
        </Stack>
      </Center>
    </Box>
  )
}

export default FAQComponent