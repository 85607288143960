import React, { useEffect, useState } from "react";
import WithAuth from "../../components/partials/withAuth";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { PaginationCompUI, getEstateID, createEstateID, MssgContainer, DelayedLink } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";
import { Link } from "react-router-dom";

function AdminEstates() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [estates, setEstates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editedEstate, setEditedEstate] = useState({})
  const [mssg, setMssg] = useState({error : false, mssg : ""})
  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estates/`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setEstates(data.results);
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => setMssg({...mssg, mssg : err.message, error : true}));
  }, []);

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setEditedEstate({})
    setEdit(false)
  }

  const addEstate = estate => {
    if (edit){
      const api = `${HOST_URL()}estates/estate_detail/${editedEstate.estate_id}/`
      const requestOptions = reqOptions('put', JSON.stringify(estate), true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        const edited_data =  estates.map(item => {
          if(item.id == editedEstate.id){
            return estate
          }
          return item
        })
        setEstates(edited_data)
        setMssg({...mssg, mssg : "Estate Updated", error : false})
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
      
    }else{
      const api = `${HOST_URL()}estates/`
      const requestOptions = reqOptions('post', JSON.stringify(estate), true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data && data.estate_id){
          setMssg({...mssg, mssg : "Estate Created successfully"})
          setEstates([...estates, data])
        }else{
          setMssg({...mssg, mssg : "Error creating estate", error : true})
        }
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))

    }
    setEditedEstate({})
    setEdit(false)
  }

  const editEstate = id => {
    const item =  estates.find(element => element.id == id);
    setEditedEstate(item)
    setEdit(true)
    setOpenModal(true)
  };
  return (
    <WithAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
      <div className="event-container">
        {isLoading ? (
          <p>Loading...</p>
        ) : estates && estates.length ? (
          <>
            <h2>Estate</h2>
            <div style={{textAlign : 'right'}}>
            <button style={{margin : '0 5px', background : '#2D4BF3', color : 'white'}}
            onClick={() => setOpenModal(true)}
            >Add Estate</button>
            </div>
            <table>
              <thead>
                <tr className="transaction-heading">
                  <th className="checkmarking">S/N</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {estates.map((estate, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{estate.name}</td>
                    <td>
                    <span 
                      className="edit-btn"
                      onClick={() => editEstate(estate.id)}
                      style={{cursor : 'pointer', color : 'blue', fontWeight : 'bolder'}}
                    >
                      Edit
                    </span> | 
                    <DelayedLink  delay={1000} estate_id={estate.estate_id} to={`/admin/`}>
                      Login to Estate
                    </DelayedLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <PaginationCompUI data = {data}/>
          </>
        ) : (
          <p>
            No Estate added yet!
            <a
              onClick={() => setOpenModal(true)}
              style={{
                cursor: "pointer",
                color: "#2D4BF3",
                fontWeight: "bolder",
              }}
            >
              Create one
            </a>
          </p>
        )}
      </div>
      <Modal open={openModal} onClose={handleCloseModal} addEstate={addEstate} edit_data={editedEstate} edit={edit}/>
    </WithAuth>
  );
}

export default AdminEstates;

const Modal = ({ open, onClose, addEstate, edit_data, edit}) => {
  // const data = edit_data
  const default_comp = {
    estate_id : `${createEstateID(10)}`,
    name : "",
    country : "",
    address : "",
    state : "",
    lga : ""
  }
  const [estate, setEstate] = useState({})
  useEffect(() => {
    setEstate(edit ? edit_data : default_comp)
  }, [edit_data])

  if (!open) return null;
  const handleChange = e => {
    const {name, value} = e.target
    setEstate({...estate, [name] : value})
    
  }

  const handleSubmit = e => {
    e.preventDefault()
    addEstate(estate)
    setEstate(default_comp)
    onClose()
  }
  
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>{edit ? "Edit" : "Add"} Estate</h3>
              </div>
              <input
                type="text"
                value={estate.name}
                name="name"
                placeholder="Estate Name"
                onChange={handleChange}
              />
              <input
                type="text"
                value={estate.country}
                name="country"
                placeholder="Country"
                onChange={handleChange}
              />
              <input
                type="text"
                value={estate.address}
                name="address"
                placeholder="Address"
                onChange={handleChange}
              />
              <input
                type="text"
                value={estate.state}
                name="state"
                placeholder="State"
                onChange={handleChange}
              />
              <input
                type="text"
                value={estate.lga}
                name="lga"
                placeholder="LGA"
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="btn btn-success btn-block">{edit ? "Edit" : "Add"} Estate</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};