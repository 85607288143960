import React from "react";
import styled from "styled-components";
import { Images } from "../../assets/images/Images";
import { SVGs } from "../../assets/svg/SVGs";
import { useCookies } from "react-cookie";

const ConfirmRegister = () => {
  const [cookies] = useCookies(["userEmail", "firstName"]);

  return (
    <ConfirmPage>
      <div>
        <div></div>
        <img src={Images.logo} alt="" className="logo-img" />
        <img src={SVGs.completed} alt="" className="checked" />
        <h1>One More Step {cookies.firstName} !</h1>
        <p>
          You have successfully registered your account. Verify the confirmation
          link we sent to <b>{cookies.userEmail}. </b>
        </p>
        {/* <button className="important-btn">Go to profile</button> */}
      </div>
    </ConfirmPage>
  );
};

export default ConfirmRegister;

let ConfirmPage = styled.section`
  max-width: 400px;
  margin: 100px auto;
  padding: 0 30px;
  text-align: center;
  display: block;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.06em;
    color: rgba(23, 55, 229, 1);
    margin-top: 50px;
  }
  .logo-img {
    display: block;
    width: 150px !important;
    margin: 0 auto;
    margin-bottom: 50px;
    object-fit: cover;
    text-align: center !important;
  }
  p {
    text-align: center;
  }
  button {
    width: 100%;
  }
  .checked {
    width: 123px;
    height: 123px;
  }
`;
