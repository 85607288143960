import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SVGs } from "../assets/svg/SVGs";

let Card = styled.div`
  .mark {
    img {
      margin-bottom: 20px;
    }
    .num {
      display: flex;
      justify-content: space-between;
    }
  }
`;
const Overview_card = ({ data, is_admin }) => {
  console.log(data, 'datatatat')
  const PerOverview = (props) => {
    return (
      <Card className="grid-row per_overview">
        <Link to={props.link} className="mark">
          <img src={props.img} alt="" />
          <div className="num">
            <p>{props.type}</p>
            <h3>{props.count}</h3>
          </div>
        </Link>
      </Card>
    );
  };
  return (
    <OverviewRap className="fourcard_grid all_overview">

      <PerOverview
        img={SVGs.overview_svg}
        type="Streets"
        count={data.streets ? data.streets.length : 0}
        link={is_admin && "/admin/streets/"}
      />

      {console.log('data', data)}

      <PerOverview
        img={SVGs.users}
        type="Residents"
        count={data.estate && data.estate.users_data ? data.estate.users_data.all_residents : 0}
        link={is_admin && "/admin/residents/"}
      />

      <PerOverview
        img={SVGs.security}
        type="Security"
        count={data.estate && data.estate.users_data ? data.estate.users_data.all_securities : 0}
        link={is_admin && "/admin/residents/"}
      />
      {/* <PerOverview
        img={SVGs.vendor}
        type="Vendors"
        count={data.estate && data.estate.users_data ?  data.estate.users_data.all_vendors : 0}
      /> */}
      <PerOverview
        img={SVGs.visitors}
        type="Visitors"
        count={data.estate && data.estate.users_data ? data.estate.users_data.all_visitors : 0}
        link={is_admin && "/admin/activitylog"}
      />
    </OverviewRap>
  );
};

export default Overview_card;

const OverviewRap = styled.div`
  .all_overview{
    justify-content: start!important;;
  }
  @media (min-width: 1320px) {
    .per_overview {
      width: 19.2%;
    }
  }
`;
