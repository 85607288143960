import React, { useEffect, useState } from "react";
import TextField from "../../components/form/TextField";
import {
  reqOptions,
  fetchAPI,
  HOST_URL,
  getCookie,
  // setCookie,
  // loginRequired,
} from "../../assets/js/helpFunction";
import {
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { States } from "../../assets/json/States";
import { useCookies } from "react-cookie";

import styled from "styled-components";
import { Images } from "../../assets/images/Images";
import FilterObjects from "../../components/form/FilterObjects";
import StatusMessage from "../../components/form/StatusMessage";
import SubmitBtn from "../../components/form/SubmitBtn";
import { Link } from "react-router-dom";
import { EstateRoleSelect, StreetSelect } from "../../components/form/SelectFields";



const JoinEstate = () => {
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [cookies, setCookie] = useCookies(["userEmail"]);
  const [formError, setFormError] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    mobile: "",
    estate_name: "",
    estate_address: "",
    estate_state: "",
    estate_country: "",
    estate_lga: "",
    estate_choice: "JOIN",
    estate_id: "",
    user_house_address : "",
    street : "Others"
  });

  const FormTitles = ["Personal Info", "Estate Info"];
  useEffect(() => {
    if (status === 201 || status === 200) {
      window.location.href = "/checkemail";
      setCookie("userEmail", formData.email, { path: "/checkemail" });
      setCookie("firstName", formData.first_name, { path: "/checkemail" });
    }
  }, [status, formData.email, formData.first_name]);

  const handleIsChecked = checked => {
    setIsChecked(checked)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Enable Spinning button
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;
    e.target.reset();
    let requestOptions = reqOptions("POST", JSON.stringify(formData));
    console.log(formData);
    try{
      const response = await fetch(HOST_URL() + "auth/registration/", requestOptions)
      if(!response.ok){
        const errorResponse = await response.json();
        let errorMessage = ''
        let index = 1
        for (const key in errorResponse) {
          errorMessage += `${index}). ${key}: ${''.concat(...errorResponse[key]).replace('.', '. ')}`
          index+=1
        }
        setFormError(errorMessage || "An error occurred.");
        e.target.querySelector("[type=submit]").disabled = false;
        e.target.querySelector("[type=submit]").classList.remove("rolling");
        return;
      }
      setStatus(response.status);
    }catch (error) {
      setFormError("An error occurred.");
      e.target.querySelector("[type=submit]").disabled = false;
      e.target.querySelector("[type=submit]").classList.remove("rolling");
      return;
    }
    // fetch(HOST_URL() + "auth/registration/", requestOptions)
    //   .then((response) => {

    //     setStatus(response.status);
    //   })
    //   .catch((error) => {
    //     console.error("Request failed", error);
    //   });
  };

  const PageDisplay = () => {
    if (page === 0) {
      return <ResidentInfo formData={formData} setFormData={setFormData} />;
    } else {
      return <EstateInfo formData={formData} setFormData={setFormData} isChecked={isChecked} handleIsChecked={handleIsChecked}/>;
    }
  };

  if (getCookie("access")){
    return window.location.replace("/my-estates")
  }

  return (
    <HandleFormMultiStep>
      <StatusMessage
        setMessageType={setMessageType}
        messageType={messageType}
        message={error.non_field_errors ? error.non_field_errors[0] : ""}
      />
      <div className="form_frame">
        <div className="renovated">
          <div className="plus_img">
            <Link to={"/"}>
              <img src={Images.logo} alt="Estate-IQ" />
            </Link>
            <form onSubmit={handleSubmit} className=" fade-in-bck">
              <div className="progressbar">
                <div
                  style={{
                    width: page === 0 ? "50%" : "100%",
                  }}
                ></div>
              </div>
              <div className="form-container">
                <div className="body">{PageDisplay()}</div>

                <div className="take_actions">
                  <button
                    type="button"
                    disabled={page == 0}
                    className={page == 0 ? "hide" : "outlined-btn prev-btn"}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button>
                  <SubmitBtn
                    types="button"
                    add_class="next_btn important-btn"
                    disabled = {page === (FormTitles.length - 1) ?  isChecked ? false : true : false}
                    onClick={(e) => {
                      if (page === FormTitles.length - 1) {
                        // alert("FORM SUBMITTED");
                          
                        e.target.type = "submit";
                      } else {
                        setPage((currPage) => currPage + 1);
                      }
                    }}
                    labels={
                      page === FormTitles.length - 1 ? "Join Estate" : "Next"
                    }
                  />
                </div>
                {formError && <p className="error">{formError}</p>}
                <div className="form_txt">
                  <p className="with_link" style={{textAlign : 'center'}}>
                    Existing user? <Link to="/login" style={{color:'blue', fontWeight : 'bolder'}}> Log in</Link> or <Link to="/create-estate" style={{color:'blue', fontWeight : 'bolder'}}> Create an estate</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="create_img">
          <img src={Images.LoginPreview} alt="" />
        </div>
      </div>
    </HandleFormMultiStep>
  );
};

export default JoinEstate;

function ResidentInfo({ formData, setFormData }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(null);
    // setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value === "") {
      setError(null);
    } else if (event.target.value !== password) {
      setError("Passwords do not match");
    } else {
      setError(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="fade-in-bck">
      <div className="form_txt">
        <p>Step 1 of 2</p>
        <h1>Join Estate</h1>
        <h4>Resident Info</h4>
      </div>

      <TextField
        types="text"
        labels="First Name"
        names="first_name"
        defaultValue={formData.first_name}
        onChange={(event) =>
          setFormData({ ...formData, first_name: event.target.value })
        }
      />
      <TextField
        types="text"
        labels="Last Name"
        names="last_name"
        defaultValue={formData.last_name}
        onChange={(event) =>
          setFormData({ ...formData, last_name: event.target.value })
        }
      />
      <TextField
        types="email"
        labels="Email"
        names="email"
        defaultValue={formData.email}
        onChange={(event) =>
          setFormData({ ...formData, email: event.target.value })
        }
      />
      <TextField
        types="tel"
        labels="Phone Number"
        names="mobile"
        defaultValue={formData.mobile}
        onChange={(event) =>
          setFormData({ ...formData, mobile: event.target.value })
        }
      />
      <TextField
        types="hidden"
        labels=""
        names="estate_choice"
        defaultValue={formData.estate_choice}
        onChange={(event) =>
          setFormData({ ...formData, estate_choice: event.target.value })
        }
      />

      <div className="password_v">
        <TextField
          labels="Password"
          names="password1"
          defaultValue={formData.password1}
          types={showPassword ? "text" : "password"}
          onChange={(e) => {
            handlePasswordChange(e);
            setFormData({ ...formData, password1: e.target.value });
          }}
        />
        <p onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </p>
      </div>
      <div className="password_v">
        <TextField
        labels="Confirm Password"
        names="password2"
        types={showPassword ? "text" : "password"}
        defaultValue={formData.password2}
        onChange={(e) => {
          handleConfirmPasswordChange(e);
          setFormData({ ...formData, password2: e.target.value });
        }}
      />
        <p onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </p>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}
// const AllStates = States;
function EstateInfo({ formData, setFormData, isChecked, handleIsChecked }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [state, setState] = useState(States.find(state => state.state === "Lagos"))
  const [streets, setStreets] = useState([])

  function handleCheckboxChange(event) {
    handleIsChecked(event.target.checked);
  }
  const getEstate = id => {
    if(id.length >= 8){
      let requestOptions = reqOptions("get", null, false);
      const estate_api = `${HOST_URL()}estates/estate_info/${id}/`
      const street_api = `${HOST_URL()}estates/estates_street_no_zone/?estate_id=${id}`
      Promise.all([
          fetch(estate_api, requestOptions),
          fetch(street_api, requestOptions),
      ]).then(function (responses) {
        return Promise.all(responses.map(function (response) {
          return response.json();
        }));
      }).then(function (resp) {
        const [data, street_response] = resp
        if(data.name){
          setFormData({
            ...formData, estate_name : data.name, estate_lga : data.lga,
            estate_state : data.state, estate_address : data.address, estate_id : id,
            estate_country : data.country
          })
        }
        setStreets(street_response.results)
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
  const estate_roles = [
    "Facilities Manager", "Property Manager", "Resident", "Community Association ExCo",
    "Property Developer", "Others"
  ]
  return (
    <div className="fade-in-bck">
      <div className="form_txt">
        <p>Step 2 of 2</p>
        <h1>Join Estate</h1>
        <h4>Estate Info</h4>
      </div>
      <TextField
        types="text"
        labels="Estate ID"
        // id="estateId"
        names="estate_id"
        // required
        // readOnly="readOnly"
        defaultValue={formData.estate_id}
        onChange={e =>getEstate(e.target.value)}
      />
      <label style={{fontSize : 'small', color : "blue"}}>Estate Name</label>
      <input type="text" id="" 
      name="estate_name"
      readOnly
      className="form-control"
      value={formData.estate_name}
      placeholder="Estate Name"
      />
      {/* <TextField
        types="text"
        labels="Estate name"
        names="estate_name"
        defaultValue={formData.estate_name}
        onChange={(event) =>
          setFormData({ ...formData, estate_name: event.target.value })
        }
        readOnly={true}
      /> */}
      <div style={{margin : "10px 0"}}>
        <label style={{fontSize : 'small', color : "blue"}}>Street</label>
        <StreetSelect selected={formData.street} options={streets} 
        handleSelect={e => setFormData({...formData, street : e})}/>
      </div>

      <TextField
        types="text"
        labels="Home Address within the Estate"
        // id="estateId"
        names="user_house_address"
        // required
        // readOnly="readOnly"
        defaultValue={formData.user_house_address}
        onChange={(event) =>
          setFormData({ ...formData, user_house_address: event.target.value })
        }
      />


      <div class="cntr">
        <label htmlFor="cbx" class="label-cbx">
          <input
            id="cbx"
            name="cbx"
            type="checkbox"
            class="invisible"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <div class="checkbox">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
              <polyline points="4 11 8 15 16 6"></polyline>
            </svg>
          </div>
        </label>
        <span>
          I accept EstateIQ <Link style={{color : 'blue'}} to="/terms-and-conditions">Terms and Conditions</Link>
        </span>
      </div>
    </div>
  );
}

function BuildingInfo({ formData, setFormData }) {
  return (
    <div className="fade-in-bck">
      <div className="form_txt">
        <p>Step 3 of 3</p>
        <h1>Join Estate</h1>
        <h4>Building Info</h4>
      </div>
      <TextField
        types="text"
        labels="Street"
        names="street"
        defaultValue={formData.street}
        onChange={(event) =>
          setFormData({ ...formData, street: event.target.value })
        }
      />
      {/* <TextField
        types="number"
        labels="Your role in the community"
        names="block_number"
        defaultValue={formData.block_number}
        onChange={(event) =>
          setFormData({ ...formData, block_number: event.target.value })
        }
      /> */}
      <select name="community_role"
        className="form-control" 
        onChange={event => setFormData({ ...formData, community_role: event.target.value })}
      >
        <option>Your role in the community</option>
        <option value="Facilities Manager">Facilities Manager</option>
        <option value="Property Manager">Property Manager</option>
        <option value="Resident">Resident</option>
        <option value="Community Association ExCo">Community Association ExCo</option>
        <option value="Property Developer">Property Developer</option>
        <option value="Others">Others</option>
      </select>
      <TextField
        types="text"
        labels="House Address"
        names="house_address"
        defaultValue={formData.house_address}
        onChange={(event) =>
          setFormData({ ...formData, house_address: event.target.value })
        }
      />

      <div class="cntr">
        <label htmlFor="cbx" class="label-cbx">
          <input
            id="cbx"
            name="cbx"
            type="checkbox"
            class="invisible"
            // checked={isChecked}
            // onChange={handleCheckboxChange}
          />
          <div class="checkbox">
            <svg width="20px" height="20px" viewBox="0 0 20 20">
              <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
              <polyline points="4 11 8 15 16 6"></polyline>
            </svg>
          </div>
        </label>
        <span>
          I accept EstateIQ <Link style={{color : 'blue'}} to="/terms-and-conditions">Terms and Conditions</Link>
        </span>
      </div>
    </div>
  );
}

let HandleFormMultiStep = styled.section`
  .hide {
    display: none;
  }
  .take_actions {
    display: flex;
    justify-content: space-between;
  }
  .prev-btn {
    width: auto !important;
    padding: 10px 25px;
  }
  .next_btn {
    width: auto !important;
    padding: 10px 25px;
  }
  .password_v {
    position: relative;
    p {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 20px;
    }
  }
  .actions_on {
    display: flex;
    justify-content: space-between;
  }
  h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    margin-bottom: 8px;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 20px;
  }
  input {
    margin-bottom: 15px;
  }
  .switch_to {
    background-color: var(--Brand-color);
    color: #fff;
  }
  .important-btn {
    width: 100%;
    margin-top: 25px;
    background-color: var(--Brand-color);
  }
`;
