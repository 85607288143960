import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Images } from "../assets/images/Images";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import Cookies from "js-cookie";
import {
  faCaretLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookLogin from '@greatsumini/react-facebook-login';

// import Stat
import {
  reqOptionFor,
  fetchAPI,
  HOST_URL,
  setCookie,
  loginRequired,
  getCookie,
} from "../assets/js/helpFunction";
import TextField from "./form/TextField";
import StatusMessage from "./form/StatusMessage";
import styled from "styled-components";
import SubmitBtn from "./form/SubmitBtn";

const Login = ({ onSubmit, id }) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(null);
    // setPassword(event.target.value);
  };

  /* 
    Show message for "success" or "error".
    The default state is ''.
    Render the StatusMessage below the Header
    props: [type, setType, message]
  */
  const [messageType, setMessageType] = useState("");
  const [error, setError] = useState([]);

  useEffect(() => {
    if (data.access_token) {
      // let json_str = JSON.stringify(data);
      setCookie("access", data.access_token, 30);
      setCookie("u_id", data.user.id, 30);
      setCookie("u_email", data.user.email, 30);

      console.log('data.user', data.user)

      if (searchParams.get("next")) {
        window.location.href = searchParams.get("next");
      } else if (data.user.is_staff) {
        window.location.href = `/superadmin`;
      } else {
        window.location.href = `/my-estates`;
      }
    }
  }, [data, messageType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // // Enable Spinning button
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;

    var myHeaders = new Headers();
    myHeaders.append("EstateIQ-Sk-Header", "22-wchvhshcjbdnvjb-2244-vsjdjbd");

    var formdata = new FormData();
    formdata.append("email", formData.email);
    formdata.append("password", formData.password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // fetchAPI(
    //   setData,
    //   HOST_URL() + "auth/login/",
    //   requestOptions,
    //   true,
    //   setMessageType,
    //   setError
    // );

    fetch(HOST_URL() + "auth/login/", requestOptions)
      .then(res => res.json())
      .then(response => {
        e.target.querySelector("[type=submit]").classList.remove("rolling");
        e.target.querySelector("[type=submit]").disabled = false;
        console.log(response, 'response')
        if (response.access_token) {
          setData(response)
        } else if (response.non_field_errors) {
          setMessageType("error")
          setError(response)
        } else if (data.error) {
          setMessageType("error")
          setError({ non_field_errors: [response.error] })
        } else {
          setError({ non_field_errors: [response.error] })
        }
      })
      .catch(err => {
        setMessageType("error")
        e.target.querySelector("[type=submit]").classList.remove("rolling");
        e.target.querySelector("[type=submit]").disabled = false;
      })
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      var myHeaders = new Headers();
      myHeaders.append("EstateIQ-Sk-Header", "22-wchvhshcjbdnvjb-2244-vsjdjbd");

      var formdata = new FormData();
      formdata.append("auth_token", codeResponse.access_token);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(HOST_URL() + "auth/google_login/", requestOptions)
        .then(res => res.json())
        .then(response => {
          if (response.error) {
            setMessageType("error")
            setError({ non_field_errors: [response.error] })
          } else {
            setData(response)
          }
        })
        .catch(err => {
          setMessageType("error")
        })
    }
  });

  if (getCookie("access")) {
    console.log('getCookie("access")', getCookie("access"))
    // return window.location.replace("/my-estates")
  }
  return (
    <Auth className="acct_info">
      <StatusMessage
        setMessageType={setMessageType}
        messageType={messageType}
        message={error.non_field_errors ? error.non_field_errors[0] : ""}
      />
      <div className="create_bg">
        <div className="create_est">
          <Link to={"/"}>
            <img src={Images.logo} alt="Estate-IQ" />
          </Link>
          <form action="" onSubmit={handleSubmit} className="fade-in-bck">
            <div className="form_txt">
              <h1>Welcome Back</h1>
              <h4>Log In</h4>
              <p className="with_link">
                New user? <Link to="/get-started">Create account</Link>
              </p>
            </div>

            <TextField
              types="email"
              labels="Username or Email"
              names="email"
              required={true}
              // defaultValue={formData.email}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
            <TextField
              types="password"
              labels="Password"
              required={true}
              // defaultValue={formData.password}
              names="password"
              onChange={(event) =>
                setFormData({ ...formData, password: event.target.value })
              }
            />
            {/* <div className="password_v">
              <TextField
                labels="Password"
                names="password"
                defaultValue={formData.password}
                types={showPassword ? "text" : "password"}
                onChange={(e) => {
                  handlePasswordChange(e);
                  setFormData({ ...formData, password: e.target.value });
                }}
              />
              <p onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </p>
            </div> */}
            <div className="reset-password">
              <Link to="/request_password" style={{ color: "#2d4bf3", fontWeight: "bolder" }}>Forgot Password ?</Link>
            </div>

            {/* <Link to="/"> */}
            <SubmitBtn
              types="submit"
              add_class="important-btn"
              labels="Login"
            />
            {/* <SubmitBtn /> */}
            {/* </Link> */}

            <div className="or_line">
              <p>Or</p>
            </div>
            {/* USE FACEBOOK OR GOOGLE */}
            <div className="google_facebook">
              {/* <GoogleLogin className="google_f google"
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
              }}
            
              onError={() => {
                console.log('Login Failed');
              }}
            
            /> */}
              <a onClick={() => googleLogin()}>
                <div className="google_f google pointer">Continue with Google</div>
              </a>
              <a href="">
                <div className="google_f facebook pointer">Continue with Facebook</div>
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="create_img">
        <img src={Images.LoginPreview} alt="" />
      </div>
    </Auth>
  );
};

export default Login;

let Auth = styled.section`
  .pointer{
    cursor: pointer;
  }
  .important-btn {
    color: #fff !important;
  }
  .password_v {
    position: relative;
    p {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 20px;
    }
  }
`;
