import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { getCookie, HOST_URL, SK_VALUE } from "../../assets/js/helpFunction";
import CreateHomeService from "./CreateHomeService";
import SetHomeService from "./SetHomeService";
import { getEstateID } from "../partials/utils";

const D_Filter = () => {
  const [selected, setSelected] = useState("Fiter by:");
  const [download, setDownload] = useState("Onboarding");
  const [emergency, setEmergency] = useState("All Home services");
  const [SOS, setSOS] = useState(false);
  const [ViewSOS, setViewSOS] = useState(false);
  const [userdata, setUserData] = useState({});
  const [trackUserDetails, settrackUserDetails] = useState("");

  /** ====to do
   * 1. Handle Emergency
   */
  // const [trackUserDetails, settrackUserDetails] = useState("");

  let estate_id = getEstateID()

  useEffect(() => {
    if (!trackUserDetails) {
      let myHeaders = new Headers();
      myHeaders.append("EstateIQ-Sk-Header", SK_VALUE());
      myHeaders.append("Authorization", "Bearer " + getCookie("access"));

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      // fetch(HOST_URL() + "estate_users/user_list/", requestOptions)
      //   .then((response) => response.text())
      //   .then((result) => settrackUserDetails(JSON.parse(result)))
      //   .catch((error) => console.log("error", error));
      fetch(
        HOST_URL() + `estate_users/estate_user_detail/?estate_id=${estate_id}`,

        requestOptions
      )
        .then((response) => response.json())
        .then((data) => setUserData(data))
        .catch((error) => console.log("error", error));
    }
  }, [estate_id, trackUserDetails]);
  const Emergency = ({ selected, setSelected }) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <div className="select_me with_blue outlined">
        <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
          {selected}
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5799 0.610625L0.399902 1.79729L6.9999 8.39062L13.5999 1.79063L12.4199 0.610625L6.9999 6.03062L1.5799 0.610625Z"
              fill="#2D4BF3"
            />
          </svg>
        </div>
        {isActive && (
          <div className="select_content">
            {/* Advert */}
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("Register Home Service");
                setIsActive(false);
              }}
            >
              <p onClick={() => setSOS(true)}>Register Home Service</p>
            </div>
            {/* Announcement */}
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("View Home services");
                setIsActive(false);
              }}
            >
              <p onClick={() => setViewSOS(true)}>View home services</p>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <OnboardingTag>
      <div className="download_filter">
        <div className="filter_download">
          <Emergency selected={emergency} setSelected={setEmergency} />
        </div>
      </div>

      <CreateHomeService open={SOS} onClose={() => setSOS(false)} />
      <SetHomeService open={ViewSOS} onClose={() => setViewSOS(false)} />
    </OnboardingTag>
  );
};

export default D_Filter;

const FilterBy = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);
  const options = ["Last 7days", "Last 14 days", "This month", "This year"];
  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content">
          {/* <h5>Duration</h5> */}
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                setSelected(option);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

let OnboardingTag = styled.div`
  .outlined {
    .select-btn {
      border: 1px solid #1737e6;

      border-radius: 5px;
      background: transparent;
      color: #1737e6;
    }
  }
  .ff-right {
    margin-bottom: 30px;
  }
  @media (min-width: 768px) {
    .outlined {
      margin-right: 10px;
    }
  }
`;
