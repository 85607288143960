import GNavbar from "../../components/Navbar/A-Navigator";
import Mobile from "../../components/Navbar/AdminMobile";
import TopNav from "../../components/Navbar/AdminNav";
import { HOST_URL, getCookie, loginRequired, reqOptions } from "../../assets/js/helpFunction";
import { useNavigate } from "react-router-dom";
import { getEstateID } from "./utils";
import {useEffect, useState} from "react"

const WithAuth = ({children, active_tab}) => {
    const [user, setUser]  = useState({})
    const navigate = useNavigate();

    if(getCookie("access") === null){
        loginRequired(401)  
    }  

    useEffect(() => {
        if(!localStorage.getItem("estate_id")) navigate('/my-estates') 
        let requestOptions = reqOptions("get", null, true);
        const api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`;
        fetch(api, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            if(data.code === "user_not_found"){
                loginRequired(401) 
            }else if(data.estate_user.user_type !== "ADMIN"){
                navigate("/my-estates")
            }else{
                setUser(data)
            } 
        })
        .catch((err) => console.log(err));
    }, [])
    
    return (
        <section className="change_ratio">
            <GNavbar />
            <Mobile user={user}/>
            <section className="selected_tab">
                <TopNav user={user}/>
                <div className="dashboard_container">
                    {children}
                </div>
            </section>
        </section>
    )
}

export default WithAuth;
