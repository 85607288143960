import { useParams, useNavigate } from "react-router-dom"
import { HOST_URL, getCookie, loginRequired, reqOptions } from "../../assets/js/helpFunction"
import { useEffect, useState } from "react"
import { Images } from "../../assets/images/Images";
import styled from "styled-components";
import { MssgContainer, getImage, titleCase } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";
import { ConfirmAction } from "./Household";

const ActivateUser = () => {
  const [data, setData] = useState({})
  const [actionOpen, setActionOpen] = useState(false);
  const [actionResident, setActionResident] = useState(null)
  const { estate_id } = useParams()
  const navigate = useNavigate();
  const [mssg, setMssg] = useState({ error: false, mssg: "" })

  if (!getCookie("access")) loginRequired(401)

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  useEffect(() => {
    const requestOptions = reqOptions('get', null, true)
    const api = `${HOST_URL()}estate_users/admin_list?estate_id=${estate_id}`
    const user_api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${estate_id}`
    Promise.all([fetch(api, requestOptions), fetch(user_api, requestOptions)])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          }));
      })
      .then(function (resp) {
        if (resp[1].estate_user.user_type !== "ADMIN") {
          setMssg({ ...mssg, mssg: "Only Admin can active users", error: true })
          setTimeout(() => {
            return navigate('/my-estate')
          })
        } else {
          setData(resp[0].results.filter(resident => resident.status === "INACTIVE"))
        }
      })
      .catch(function (error) {
        setMssg({ ...mssg, mssg: error.message, error: true });
      });
  }, [estate_id])

  const handleActivate = resident => {
    const modifyURL = `${HOST_URL()}estate_users/modify_user/?estate_id=${estate_id}`
    const status = resident.status === "INACTIVE" ? "ACTIVE" : "INACTIVE"
    const requestOptions = reqOptions('post',
      JSON.stringify({ status: status, estate_user_id: resident.id }), true)
    fetch(modifyURL, requestOptions)
      .then(res => res.json())
      .then(response => {
        setMssg({ ...mssg, mssg: `Account ${status.toLocaleLowerCase() === "active" ? "activated" : "deactivated"} successfully` })
        setData(data.filter(item => item.id !== resident.id))
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }

  return (
    <DerivativesPage>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
      <div>
        <img src={Images.logo} alt="" className="logo-img" />
        {/* <img src={SVGs.completed} alt="" className="checked" /> */}
        <h1>Welcome back admin</h1>
        <p>Kindy activate your newer residents</p>
        {
          data && data.length ? (
            <TableFrame>
              <div className="scrollable_table">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th>Address</th>
                      <th>Contact</th>
                      <th>User Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {data.map((resident, index) => (
                    <tr key={index}>

                      <td>
                        <div className="img-avatar">
                          <img
                            src={getImage(resident.profile_image)}
                            alt=""
                          />
                          <div className="user-name">
                            {resident.user.last_name} {resident.user.first_name}
                          </div>
                        </div>
                      </td>
                      <td>{resident.estate_user_id}</td>
                      <td>{resident.estate.address}</td>

                      <td className="contact_data">
                        <div className="contact">
                          <a href={`tel:${resident.user.mobile}`}>
                            <img src={SVGs.Call} alt="" />
                          </a>
                          <a href={`mailto:${resident.user.email}`}>
                            <img src={SVGs.Email} alt="" />
                          </a>
                        </div>
                      </td>
                      <td> {titleCase(resident.user_type)}</td>
                      <td className="resume_data">
                        <div class="checkbox_item citem_1">
                          <label class="checkbox_wrap">
                            <input
                              type="checkbox" name="checkbox"
                              class="checkbox_inp"
                              checked={resident.status === "INACTIVE" ? false : true}
                              // onChange={() =>handleActivate(resident)}
                              onChange={() => {
                                setActionResident(resident)
                                setActionOpen(true)
                              }}
                            />
                            <span class="checkbox_mark"></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </TableFrame>
          ) : <p>No newer resident yet</p>
        }
      </div>
      <ConfirmAction open={actionOpen} onClose={() => { setActionResident(null); setActionOpen(false) }} resident={actionResident} handleActivate={handleActivate} />
    </DerivativesPage>
  )
}

export default ActivateUser;

let DerivativesPage = styled.section`
  max-width: 1000px;
  margin: 50px auto;
  text-align: center;
  display: block;
  @media (max-width: 400px) {
    padding: 0 20px;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0;
    color: rgba(23, 55, 229, 1);
    margin-top: 50px;
  }
  .logo-img {
    display: block;
    width: 150px !important;
    margin: 0 auto;
    margin-bottom: 50px;
    object-fit: cover;
    text-align: center !important;
  }
  p {
    text-align: center;
  }
  button {
    width: 100%;
  }
  .checked {
    width: 123px;
    height: 123px;
  }

  .gratitude {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    padding: 20px;
    background: #fbfbfb;
    transform: scale(1);
    transition: 0.4s;
    border: 1px solid rgba(44, 51, 58, 0.2);
    border-radius: 17.9149px;
    &:hover {
      transform: scale(1.02);
      background: #f2f6ff;
      border: 0.895745px solid #2d4bf3;
      border-radius: 17.9149px;
      .svg {
        transition: 0.4s;
        svg {
          path {
            fill: #ffffff;
          }
          rect {
            fill: #2d4bf3 !important;
          }
        }
      }
    }
    .svg {
      margin-top: 15px;
      transition: 0.4s;
      margin-right: 20px;
      svg {
        path {
          fill: #2d4bf3;
        }
        rect {
          fill: #f2f6ff !important;
        }
      }
    }
    .choose_txt {
      width: 90%;
      text-align: left;
      h4 {
        font-family: DM Sans;
        font-size: 22px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: -0.06em;
        text-align: left;
        color: #171717;
      }
      p {
        text-align: left;
      }
    }
  }
`;

const TableFrame = styled.div`
    max-height: 350px;
    overflow-y: scroll;
   /* .scrollable_table {
    font-family: "Satoshi";
     margin-left: 40px;
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 121%;
     display: flex;
     align-items: center;
     /* Grey/2 */
     color: #545454;
   } */

  @media (max-width: 900px) {
    .scroll {
      min-width: 950px;
      width: 100%;
    }
  }

  .select_me {
    width: 150px;
    .select-btn {
      padding-left: 14px;
    }
  }

  .scrollable_table tr {
    margin-left: 20px;
  }

  .img-avatar img {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
  }
  .img-avatar {
    display: flex;
    align-items: center;
  }
  .user-name {
    margin-left: 10px;
    width: 134px;
    height: 23px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.06em;

    /* Grey/1 */
    color: #111111;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .team-members {
    // display: flex;
    // justify-content: space-between;
    margin-top: 40px;
  }

  .contact {
    margin: 5px;
    display: flex;
  }
  .contact a {
    margin: 8px;
    width: 30px;
    height: 30px;
  }
`;