import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import {
  reqOptions,
  fetchAPI,
  HOST_URL,
  // setCookie,
  // loginRequired,
} from "../../assets/js/helpFunction";
import {
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import TextField from "../../components/form/TextField";
import StatusMessage from "../../components/form/StatusMessage";
import { Images } from "../../assets/images/Images";
import SubmitBtn from "../../components/form/SubmitBtn";
import styled from "styled-components";

const RequestPassword = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(null);
    // setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value === "") {
      setError(null);
    } else if (event.target.value !== password) {
      setError("Passwords does not match");
    } else {
      setError(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [formData, setFormData] = useState({
    email: "",
  });

  useEffect(() => {
    if (submitted && data.status === "error") {
      window.location.href = "/login";
    } else if (submitted && data.status === "error") {
      setError(data.message);
    }
  }, [submitted, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestOptions = reqOptions("post", new FormData(e.target));
    // Enable Spinning button
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;
    fetchAPI(
      setData,
      HOST_URL() + "auth/request_email_otp/",
      requestOptions,
      true,
      (data) => {
        if (data.status === "success") {
          setSubmitted(true);
        } else if (data.status === "error") {
          setError(data.message);
        }
      }
    );

    // Enable submit button
    // e.target.querySelector("[type=submit]").disabled = false;
  };
  return (
    <UpdateUserPassword className="acct_info">
      {/* <StatusMessage
        setMessageType={setMessageType}
        messageType={messageType}
        message={error.non_field_errors ? error.non_field_errors[0] : ""}
      /> */}
      <div className="create_bg">
        <div className="create_est">
          <Link to={"/"}>
              <img src={Images.logo} alt="Estate-IQ" />
          </Link>
          <form action="" onSubmit={handleSubmit} className="fade-in-bck">
            <div className="form_txt">
              <h1>Update password</h1>
              {/* <h4></h4> */}
              <p className="with_link">Enter a new Password</p>
            </div>

            <div className="password_v">
              <TextField
                labels="New Password"
                names="password1"
                defaultValue={formData.password1}
                types={showPassword ? "text" : "password"}
                onChange={(e) => {
                  handlePasswordChange(e);
                  setFormData({ ...formData, password1: e.target.value });
                }}
              />
              <p onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </p>
            </div>
            <TextField
              labels="Confirm Password"
              names="password2"
              types="password"
              defaultValue={formData.password2}
              onChange={(e) => {
                handleConfirmPasswordChange(e);
                setFormData({ ...formData, password2: e.target.value });
              }}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            {/* <TextField types="password" labels="Password" names="password" /> */}

            {/* <Link to="/"> */}
            <SubmitBtn
              types="submit"
              className="important-btn"
              labels="Reset Password"
            />
            {/* </Link> */}
          </form>
        </div>
      </div>
      <div className="create_img">
        <img src={Images.LoginPreview} alt="" />
      </div>
    </UpdateUserPassword>
  );
};

export default RequestPassword;

let UpdateUserPassword = styled.section`
  .password_v {
    position: relative;
    p {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 20px;
    }
  }
`;
