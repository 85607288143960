import { Link } from "react-router-dom";
import styled from "styled-components";
import { SVGs } from "../assets/svg/SVGs";

let Card = styled.div`
  @media screen and (max-width : 420px) {

    margin: 15px 0px !important;

  }
  .mark {
    img {
      margin-bottom: 20px;
    }
    .num {
      display: flex;
      justify-content: space-between;
    }
  }
`;
const Overview_card = ({data}) => {
  const PerOverview = (props) => {
    return (
      <Card className="grid-row per_overview">
        <Link to={props.link} className="mark">
          <img src={props.img} alt="" />
          <div className="num">
            <p>{props.type}</p>
            <h3>{props.count}</h3>
          </div>
        </Link>
      </Card>
    );
  };
  return (
    <div className="fourcard_grid all_overview">
      <PerOverview img={SVGs.Resident} type="Members" count={data && data.users && data.users.length}
      link="/resident/Residents"
      />
      <PerOverview
        img={SVGs.overview_svg}
        type="Domestic Staff"
        count={data && data.users && data.users.length}
        link="/resident/Residents"
      />
      <PerOverview
        img={SVGs.complains}
        type="Complaints"
        count={data && data.complaints && data.complaints.length}
        link="/resident/complaint"
      />
      <PerOverview img={SVGs.vendor} type="Access Log" count={data && data.logs && data.logs.length} 
      link="/resident/activitylog"
      />
    </div>
  );
};

export default Overview_card;
