import React from "react";
import { useState } from "react";
import {
  reqOptions,
  HOST_URL,
} from "../../assets/js/helpFunction";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import TextField from "../../components/form/TextField";
import StatusMessage from "../../components/form/StatusMessage";
import { Images } from "../../assets/images/Images";
import SubmitBtn from "../../components/form/SubmitBtn";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResetPasswordWithOTP = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false)
  const location = useLocation();
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: location.state.email,
  });


  useEffect(() => {
    if(passwordChanged) return navigate('/login', { state: {email : formData.email} });
  }, [passwordChanged]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestOptions = reqOptions("post", JSON.stringify(formData));
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;

    const api = `${HOST_URL()}auth/forgot_password/`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if(data.error){
            setData({...data, error : data.error, messagetype : "error"})
        }
        else if(data.message){
          setData({...data, error : data.message , messagetype : "success"})
          setTimeout(() => {
            setPasswordChanged(true)
          }, 1500)
        }else{
            setData({...data, error : data.detail ? data.detail : "Error verifying OTP", messagetype : "error"})
        }
        document.querySelector("[type=submit]").classList.remove("rolling");
        document.querySelector("[type=submit]").disabled = false;
      })
      .catch((err) => console.log(err));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="acct_info">
        <StatusMessage
            setMessageType={setMessageType}
            messageType={data && data.messagetype}
            message={data && data.error}
        />
      <div className="create_bg">
        <div className="create_est">
          <Link to={"/"}>
              <img src={Images.logo} alt="Estate-IQ" />
          </Link>
          <form action="" onSubmit={handleSubmit} className="fade-in-bck">
            <div className="form_txt">
              <h1>Reset password with OTP</h1>
              {/* <h4></h4> */}
              <p className="with_link">
                An OTP has been sent to {}. Please enter new password alongside the sent OTP.
              </p>
            </div>

            <TextField
              types="email"
              labels="Email Address"
              names="email"
              defaultValue={formData.email}
              readOnly={true}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
            <TextField
              types="number"
              labels="OTP"
              required={true}
              // defaultValue={formData.password}
              names="otp"
              onChange={(event) =>
                setFormData({ ...formData, otp: event.target.value })
              }
            />
            <div style={{position : 'relative'}}>
              <TextField
                types={showPassword ? "text" : "password"}
                labels="Password"
                required={true}
                // defaultValue={formData.password}
                names="password"
                onChange={(event) =>
                  setFormData({ ...formData, password: event.target.value })
                }
              />
              <p 
                style={{position : 'absolute', cursor : 'pointer', top : 7, right : 20}}
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </p>
            </div>
            {/* <TextField types="password" labels="Password" names="password" /> */}

            {/* <Link to="/"> */}
            <SubmitBtn
              types="submit"
              className="important-btn"
              labels="Reset Password"
            />

            <div className="reset-password">
                <Link to={"/login"}>Back to Login</Link>
            </div>
            {/* </Link> */}
          </form>
        </div>
      </div>
      <div className="create_img">
        <img src={Images.LoginPreview} alt="" />
      </div>
    </div>
  );
};

export default ResetPasswordWithOTP;
