import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { WithResidentAuth } from "./Dashboard";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { MssgContainer, getEstateID, titleCase } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";

function Residents() {
  const [residents, setResidents] = useState([]);
  const [data, setData] = useState({})
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false); // state for Modal
  const [activeIndex, setActiveIndex] = useState(1);
  const [mssg, setMssg] = useState({error : false, mssg : ""})

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    //
    const api = `${HOST_URL()}estate_users/user_list/`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setResidents(data.results.filter((item) => item.user_category === "HOUSEHOLD"));
      })
      .catch((err) => setMssg({...mssg, mssg : err.message, error : true}));
  }, []);


  const checkActive = (index) => (activeIndex === index ? "active" : "");

  const filterResident = (index, name) => {
    setActiveIndex(index);
    document
      .querySelectorAll("tab")
      .forEach((item) =>
        item.classList.includes(activeIndex)
          ? item.classList.add("active")
          : item.classList.remove("active")
      );

      setResidents(data.results.filter((item) => item.user_category == name.toUpperCase()));
      if (name == "HOUSEHOLD"){
        setResidents(data.results.filter((item) => item.user_category === "HOUSEHOLD"));
      }else{
        setResidents(data.results.filter((item) => item.user_category !== "HOUSEHOLD"));
      }

  }

  const addResident = resident => {
    const requestOptions = reqOptions("post", JSON.stringify(resident), true);
    //
    const api = `${HOST_URL()}estate_users/resident_invite/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setMssg({...mssg, mssg : "Resident Invited Successfully", error : false})
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      .catch((err) => setMssg({...mssg, mssg : err.message, error : true}));
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <WithResidentAuth>
      <div className="event-container">
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
        <HandleSearchAndTab>
        <div className="tabs">
            <span
              className={`tab 1 ${checkActive(1)}`}
              onClick={() => filterResident(1, "HOUSEHOLD")}
            >
              <button>Household</button>
            </span>

            <span
              className={`tab 2 ${checkActive(2)}`}
              onClick={() => filterResident(2, "STAFF")}
            >
              <button>Staff</button>
            </span>
          </div>
          <div className="event-input">
            <div class="search_set">
              <img src="https://www.svgrepo.com/show/13682/search.svg" alt="" />
              <input
                className="eventt"
                type="text"
                name="name"
                placeholder="Search"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
            </div>

            <button
              className="important-btn"
              onClick={() => setOpenModal(true)}
            >
              Register Household Members
            </button>
          </div>
        </HandleSearchAndTab>
      </div>

      <div className="panels" style={{overflowX : 'scroll'}}>
        <div>
          <TableResidents>
            <div>
              <div className="activity_table">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>User Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    { residents && residents.length ? residents.map(resident => (
                      <tr >
                        <td>{resident.estate_user_id}</td>
                      <td>{resident.user.first_name}</td>

                      <td>{resident.user.last_name}</td>

                      <td>{resident.user.email}</td>

                      <td>{resident.user.mobile}</td>
                      <td>
                        {resident.user_category}
                      </td>
                    </tr>
                    )) : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </TableResidents>
        </div>
      </div>
      {/* <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationButtons"}
        previousLinkClassName={"previousButton"}
        nextLinkClassName={"nextButton"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
      <Modal open={openModal} onClose={handleCloseModal} addResident={addResident}/>
    </WithResidentAuth>
  );
}

export default Residents

const Modal = ({ open, onClose, addResident,}) => {
  // const data = edit_data
  const USER_CATEGORY_OPTIONS = ['FAMILY_MEMBER','DOMESTIC_STAFF']
  const default_data = {
    first_name : "",
    last_name : "",
    email : "",
    mobile : "",
    user_category : USER_CATEGORY_OPTIONS[0]



  }
  const [resident, setResident] = useState(default_data)
  useEffect(() => {
    
    setResident(default_data)
  }, [])

  if (!open) return null;
  const handleChange = e => {
    const {name, value} = e.target
    setResident({...resident, [name] : value})

  }

  const handleSubmit = e => {
    e.preventDefault()

    addResident(resident)
    setResident(default_data)
    // onClose(false)
  }
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h4>Register Household Members</h4>
              </div>
              <input
                type="text"
                value={resident.first_name}
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
              />

              <input
                type="text"
                value={resident.last_name}
                name="last_name"
                placeholder="Last Name"
                onChange={handleChange}
              />

              <input
                type="email"
                value={resident.email}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />

              <input
                type="text"
                value={resident.mobile}
                name="mobile"
                placeholder="Mobile"
                onChange={handleChange}
              />

              <select onChange={handleChange} name="user_category" value={resident.user_category} className="form-control">
                {USER_CATEGORY_OPTIONS.map(option => <option value={option}>{titleCase(option.replace("_"," "))}</option>)}
              </select>


            </div>
          </div>
          <button className="btn btn-success btn-block">Invite</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};


const SwitchBtn = styled.div`
  .switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 25px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
  }
  input {
    display: none;
  }
  .switch::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
      0 0 0 20px rgba(128, 128, 128, 0.1);
  }
  input:checked + .switch {
    background: rgba(45, 75, 243, 1);
  }
  input:checked + .switch::before {
    left: 27px;
    background: #fff;
  }
  input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
  }
`;
const TableResidents = styled.div`
  .activity_heading {
    font-family: "Satoshi";
    margin: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 121%;
    color: #545454;
  }
  table {
    margin: 0 !important;
  }
  .panels {
    margin-top: 0 !important;
  }
  .activity_table {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 121%;
    color: #545454;
  }
  .user-name {
    margin-left: 10px;
  }
  .team-members {
    margin-top: 40px;
    width: 65%;
  }
  // .date-time {
  //     width: 81px;
  //     height: 36px;
  //     font-family: 'DM Sans';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 18px;
  //     display: flex;
  //     align-items: center;
  //     color: #545454;
  // }
  .Residents-address {
    width: 153px;
    height: 34px;
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 121%;
    /* or 17px */

    display: flex;
    align-items: center;

    /* Grey/2 */
    color: #545454;
  }

  .resume_data {
    margin: 15px;
    width: 32px;
    height: 18px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    letter-spacing: -0.06em;
  }

  .img-entry {
    margin: 5px;
  }
  .activity-time {
    font-weight: 700;
  }
`;
const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }

  @media (min-width: 760px) {
    .search_set {
      margin-right: 10px;
    }
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;

let Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;


  

  @media (max-width: 400px) {
    .eachAdminBlog {
      width: 100%;
    }
  }
  @media (min-width: 720px) {
    .perComponent {
      width: 48.5%;
    }
  }
  @media (min-width: 1200px) {
    .perComponent {
      width: 32.5%;
    }
  }
 
  }
`;


