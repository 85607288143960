import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  &:focus + .filtered-list {
    display: block;
  }
`;
let SearchPlusDropdown = styled.section`
  position: relative;
  img {
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    right: 20px;
    top: 12px;
    // background-color: yellow;
    z-index: 1;
  }
`;
const FilteredList = styled.div`
  display: none;
  background: white;
  position: absolute;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  // max-height: auto;
  max-height: 200px;
  // height: 100%;
  border: 1px solid #dfe3e7;
  overflow: auto;
  top: 60px;
  z-index: 50;
  div:only-child {
    max-height: initial;
  }
  div {
    padding: 2px 15px;
    margin: 0;
    cursor: pointer;
    &:hover {
      background: #f3f3f3;
    }
  }
`;

const SearchInput = ({
  jsonData,
  searchTerm,
  inputName,
  placeholder,
  onChange,
  required_field,
  defaultValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [focused, setFocused] = useState(false);
  const filteredData = jsonData.filter((item) =>
    item[searchTerm].toLowerCase().includes(searchValue.toLowerCase())
  );
  useEffect(() => {
    // if (searchValue) {
    //   console.log(searchValue);
    //   document.querySelector("#searchInput").value = searchValue;
    // }
  }, [searchValue]);

  const handleClick = (item) => {
    onChange(item);
    // console.log(item);
    setSearchValue(item);
    // console.log("Value: ", document.querySelector("#searchInput").value);
    document.querySelector("#searchInput").value = item;
    // console.log("Value2: ", document.querySelector("#searchInput").value);
    setFocused(false);
  };

  return (
    <SearchPlusDropdown>
      <StyledInput
        type="text"
        name={inputName}
        id="searchInput"
        placeholder={placeholder}
        defaultValue={defaultValues}
        onChange={(e) => {
          setSearchValue(e.target.value);
          handleClick(e.target.value);
        }}
        required={required_field}
        onFocus={() => setFocused(true)}
        // onBlur={() => setFocused(false)}
      />
      <img
        // onClick={(e) => {
        //   handleClick();
        // }}
        src="https://www.svgrepo.com/show/473106/chevron-down.svg"
        alt="V"
      />
      <FilteredList
        className="filtered-list"
        style={{
          display: focused ? "block" : "none",
        }}
      >
        {filteredData.length > 0 &&
          filteredData.map((item, index) => (
            <div key={index}>
              <p onClick={() => handleClick(item[searchTerm])}>
                {item[searchTerm]}
              </p>
            </div>
          ))}
      </FilteredList>
    </SearchPlusDropdown>
  );
};

export default SearchInput;
