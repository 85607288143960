import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate"; //  Using react-paginate from the react library
import styled from "styled-components";
import { ExternalAuth } from "./ExternalDashboard";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { getDate, getEstateID } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";


function ExternalActivityLog() {
  const [data, setData] = useState({});
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({
    name : "",
    mode : ""
  })

  const filterLog = () => {
    let search_string = ``
    let requestOptions = reqOptions("get", null, true);
    if(filter.name){
      search_string += "&search="+filter.name
    }

    if(filter.mode){
      search_string += "&status="+filter.mode
    }
    
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}${search_string}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        setLogs(data.results);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        setData(data)
        setLogs(data.results);
      })
      .catch((err) => console.log(err));
  }, [])


  const ChangeLogStatus = ({access, id, access_code}) => {
    const updatedAccess = access === "GRANT" ? "REVOKE" : "GRANT"
    let requestOptions = reqOptions("POST", JSON.stringify({access, access_code}), true);
    const api = `${HOST_URL()}estate_access_logs/modify/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(data => {
        console.log(data, "shjdshsdhjdsh")
      })
      .catch((err) => console.log(err));
    const newlogs = logs.map(log => {
      if (log.id === id) {
        return {...log, access: updatedAccess};
      }
      return log;
    });
    setLogs(newlogs);
  }


  const Mode = ({ selected, setSelected }) => {
    const [isActive, setIsActive] = useState(false);
    const options = ["Entry", "Exit"];
    return (
      <div className="select_me filter_drop">
        <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
          {selected}
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 9L12.5 15L18.5 9"
              stroke="#545454"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {isActive && (
          <div className="select_content">
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("Entry");
                setIsActive(false);
              }}
            >
              <p>Entry</p>
            </div>
            <div
              className="select_items"
              onClick={(e) => {
                setSelected("Exit");
                setIsActive(false);
              }}
            >
              <p>Exit</p>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <ExternalAuth>
      <div className="event-container">
        <HandleSearchAndTab>
          <h3>Access Log</h3>
          <div className="event-input">
            <div class="search_set">
              <input
                className="eventt"
                type="text"
                name="name"
                value={filter.name}
                placeholder="Search Name"
                onChange={(e) => setFilter({...filter, name : e.target.value})}
              />
            </div>
            <div className="select_me filter_drop">
              <select value={filter.mode} name="mode" id="" onChange={e => setFilter({...filter, mode : e.target.value})} className="form-control">
                <option value="">Filter</option>
                <option value="EXIT">Exit</option>
                <option value="ENTRY">Entry</option>
              </select>
            </div>
            <div>
              <button className="btn btn-primary" style={{background : "#2d4bf3"}} onClick={filterLog}>
                <img src={SVGs.filter} alt="filter" style={{maxWidth : "20px"}}/>
              </button>
            </div>
            {/* <Mode selected={entry} setSelected={setEntry} /> */}
          </div>
        </HandleSearchAndTab>
      </div>

      <div className="panels">
        <TableFrame>
          <div className="scrollable_table">
            <div className="activity_table">
              <table>
                <thead>
                  <tr>
                    <th>Host's Name</th>
                    <th>Guest's Name</th>
                    <th>Access Code</th>
                    <th>Destination</th>
                    <th>Date and Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {logs && logs.length
                    ? logs.map((log) => (
                      <tr key={log.id}>
                      <td>
                        {log.estate_user?.user?.first_name} {log.estate_user?.user?.last_name}
                      </td>
                      <td>
                        {log.first_name} {log.last_name}
                      </td>
                      <td>{log.access_code}</td>
                      <td>{log.address}</td>
                      <td className="defaultforDate">
                        {getDate(log.from_date)}
                      </td>
                      <td className="resume_data">
                        <p
                          className={
                            log.access === "GRANT" ? "entry" : "exit"
                          }
                        >
                          {log.access === "GRANT" ? "Granted" : "Declined"}
                        </p>
                      </td>
                      </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </TableFrame>
      </div>
    </ExternalAuth>
  );
}

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 10px;
  }
  .normal_tab {
    background: transparent;
  }
  h3 {
    margin: 0;

    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }
  .event-input {
    max-width: 675px;
  }
  @media (max-width: 420px) {
    .search_set,
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .search_set {
      width: 220px;
    }
    .outlined {
      width: 180px !important;
    }
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin: 0 10px;
        width: 135px;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;
      padding: 5px 20px;
      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;

const FilterBy = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);
  const options = ["Last 7days", "Last 14 days", "This month", "This year"];
  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        {selected}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content">
          {/* <h5>Duration</h5> */}
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                setSelected(option);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TableFrame = styled.div`
  @media (max-width: 900px) {
    .scroll {
      min-width: 950px;
      width: 100%;
    }
  }
  .activity_table {
    /* Grey/2 */
    color: #545454;
  }
  .user-name {
    margin-left: 10px;
  }
  .team-members {
    margin-top: 40px;
    width: 65%;
  }
  // .date-time {
  //     width: 81px;
  //     height: 36px;
  //     font-family: 'DM Sans';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 16px;
  //     line-height: 18px;
  //     display: flex;
  //     align-items: center;
  //     color: #545454;
  // }
  .Residents-address {
    width: 190px;
    height: 34px;
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .resume_data {
    margin: 15px;
    width: 32px;
    height: 18px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    letter-spacing: -0.06em;
  }

  .img-entry {
    margin: 5px;
  }
  .activity-time {
    font-weight: 700;
  }
`;

export default ExternalActivityLog;
