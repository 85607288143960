import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { SVGs } from "../../assets/svg/SVGs";
import ProfileType from "./E-ProfileType";


const EditSOS = ({ emergencyData, onFinish }) => {
  console.log('emergencyData', emergencyData)
  const [inputValue, setInputValue] = useState({
    address: emergencyData.address,
    phone_number: emergencyData.phone_number,
    whatsapp_number: emergencyData.whatsapp_number,
    description: emergencyData.description,
    title: emergencyData.title,
    type: emergencyData.type
  });
  const [selected, setSelected] = useState("Profile Type");



  const handleSubmit = (event) => {
    event.preventDefault();

    onFinish(emergencyData.id, inputValue)

    // Swal.fire({
    //   title: `<div>You created profile for <h4> ${title}</h4> </div>`,
    //   icon: "success",
    //   showConfirmButton: true,
    //   showCloseButton: true,
    // });

    // .then(function () {
    //   window.location = "/superadmin/tasks";
    // });
  };

  return (
    <form
      onSubmit={handleSubmit}
      // method="post"
      // action="/profile"
      className="new_estates_form fade-in-bck"
    >
      <div className="form-group">
        {/* <label htmlFor="title">Email address</label> */}

        <div className="create_est ">
          <div className="form_txt">
            <div className="capture_close">
              <h4>Edit</h4>
            </div>
          </div>

          {/* Create Account Type */}
          <ProfileType selected={selected} setSelected={setSelected} />
          <input
            value={inputValue.title}
            onChange={e => setInputValue(initValues => ({ ...initValues, title: e.target.value }))}
            type="text"
            id="title"
            name="title"
            placeholder="Emergency Service Name"
          />

          <input
            value={inputValue.description}
            onChange={e => setInputValue(initValues => ({ ...initValues, description: e.target.value }))}
            type="text"
            id="title"
            name="title"
            placeholder="Emergency Service Details"
          />
          <input
            value={inputValue.address}
            onChange={e => setInputValue(initValues => ({ ...initValues, address: e.target.value }))}
            type="text"
            id="address"
            name="address"
            placeholder="Address"
          />
          <input
            value={inputValue.whatsapp_number}
            onChange={e => setInputValue(initValues => ({ ...initValues, whatsapp_number: e.target.value }))}
            type="number"
            id="phone"
            name="phone"
            placeholder="Mobile"
          />
          <input
            value={inputValue.phone_number}
            onChange={e => setInputValue(initValues => ({ ...initValues, phone_number: e.target.value }))}
            type="email"
            id="email"
            name="email"
            placeholder="Email"
          />
        </div>
        <button type='submit'>Edit Emergency</button>
      </div>
    </form>
  );
};

const Modal = ({ open, onClose, emergencyData, onFinish }) => {
  if (!open) return null;
  return (
    <ModalWrapper onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <EditSOS emergencyData={emergencyData} onFinish={onFinish} />
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </ModalWrapper>
  );
};
export default Modal;

let ModalWrapper = styled.div`
  .modalContainer {
    max-width: 600px;
    .new_estates_form {
      max-width: 600px;
    }
  }
  .create_est {
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

let RevokeAccess = styled.div`
  .revokeOnes {
    height: 200px;
    overflow: auto;
  }
  h2 {
    font-size: 20px;

    font-weight: bold;
    margin-bottom: 0px;
  }
  .cntr {
    margin-top: 10px;
  }
`;
