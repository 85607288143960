import styled from "styled-components";
import { reqOptions } from "../../assets/js/helpFunction";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'

export const getEstateID = (estate_id = null) => {
  if (estate_id) {
    localStorage.setItem("estate_id", estate_id);
    return estate_id;
  }
  return localStorage.getItem("estate_id");
};

export const PaginationCompUI = ({ next, previous }) => {
  return (
    <div className="pag_nav_wrapper">
      {previous ? <PageBtn className="btn">Prev</PageBtn> : ''}
      {next ? <PageBtn className="btn">Next</PageBtn> : ''}
    </div>
  );
};

export function createEstateID(length) {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export const getData = (url, method, formData = null) => {
  let data = {};
  const requestOptions = reqOptions(method, formData, true);
  fetch(url, requestOptions)
    .then((res) => {
      data = { status: res.status };
      res.json();
    })
    .then((resp) => {
      data["data"] = resp;
    })
    .catch((err) => console.log(err));
  return data;
};

let PageBtn = styled.button`
    margin : 0px 5px;
    background-color: #2D4BF3;
`


export const getActiveTab = pathname => {
  const sidebarLinks = document.querySelectorAll(".ext-link")
  sidebarLinks.forEach(item => {
    if (pathname == item.pathname) {
      item.firstChild.classList.add("active_tab")
    } else {
      item.firstChild.classList.remove("active_tab")
    }
  })
}


export const MssgContainer = ({ mssg, close }) => {
  if (mssg.mssg) {
    // Auto close message after 10 seconds
    setTimeout(() => {
      close()
    }, 5000);
  }
  return (
    <MsgContainer>
      <div onClick={(e) => {
        e.stopPropagation();
      }}
        className="slide-in-top"
      >
        <span className={`valid_btn ${mssg.error ? 'red' : 'green'}`}>
          {mssg.mssg}
        </span>
      </div>
    </MsgContainer>
  )
}


export const DelayedLink = ({ delay, replace, state, to, estate, estate_id, ...props }) => {
  const navigate = useNavigate();
  const timerRef = useRef();

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const clickHandler = (e) => {
    e.preventDefault();
    localStorage.setItem("estate_id", estate_id)
    timerRef.current = setTimeout(navigate, delay, to, { replace, state });
  };

  console.log('estate', estate)

  let textToShow;
  if (estate?.status === 'PENDING') {
    textToShow = 'This estate is yet to be approved by the <a href="mailto:estateiqnigeria@gmail.com">EstateIQ Team</a>. You will be notified as soon as your request has been approved. Thank you.'
  } else {
    textToShow = 'This estate is being deactivated by the deactivated please contact the <a href="mailto:estateiqnigeria@gmail.com">EstateIQ Team</a>. '
  }

  return estate?.status === 'ACTIVE' ? (
    <Link to={to} {...props} onClick={clickHandler} />
  ) : (
    <div {...props} onClick={() => {
      Swal.fire({
        title: textToShow,
        icon: "info",
        showConfirmButton: true,
        showCloseButton: true,
      });
    }}></div>
  );
};

export const shareCode = (access) => {
  navigator.share({
    title: 'Access Code',
    text: `
Hi ${access.first_name} ${access.last_name},

Your ${access.access_type.toLowerCase().replace('_', " -")} code is 

${access.access_code}.

Address : ${access.address},

from ${access.from_date} to ${access.to_date}

Powered by: www.estateiq.ng
    `,
  })
    .then(() => { })
    .catch((error) => console.error('Error sharing:', error));
}


export const getDate = date => {
  const readableDate = new Date(date)
  return `${readableDate.toDateString()} ${readableDate.toLocaleTimeString()}`
}

export const getImage = (image = null) => {
  return image ? `https://api.estateiq.ng${image}` : "https://www.svgrepo.com/show/341256/user-avatar-filled.svg"
}

export const titleCase = str => {
  // Step 1. Lowercase the string
  str = str.toLowerCase();

  str = str.split(' ');

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }

  return str.join(' ');
}



export const AccessConfirmation = ({ open, onClose, access }) => {

  if (!open) return null;
  return (
    <HandleQuickAccess onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <AccessPin className="accessPin">
          <h3>Access Info</h3>
          <div className="accessInfo">
            <div className="eachUnit">
              <p>Guest Name</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.first_name} ${access.last_name}`}
              />
            </div>
            <div className="eachUnit">
              <p>Host Name</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.estate_user?.user?.first_name} ${access.estate_user?.user?.last_name}`}
              />
            </div>
            <div className="eachUnit">
              <p>Access Code</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.access_code}`}
              />
            </div>
            <div className="eachUnit">
              <p>Access Type</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${titleCase(access.access_type.replace("_", "-"))}`}
              />
            </div>
            <div className="eachUnit">
              <p>Validity From </p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${getDate(access.from_date)}`}
              />
              <p>Validity To</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${getDate(access.to_date)}`}
              />
            </div>
            <div className="eachUnit">
              <p>Destination</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.address}`}
              />
            </div>

          </div>
        </AccessPin>
        <img
          src="https://www.svgrepo.com/show/311932/close.svg"
          alt=""
          onClick={onClose}
        />
      </div>
    </HandleQuickAccess>
  );
};


export const genWhatsappLink = (phone, business_name) => {
  let tel = parseInt(phone)
  let name = business_name.replace(" ", "%20")
  let text = `https://api.whatsapp.com/send?phone=234${tel}&text=Hi%20${name}%2C%0A%0AI%20would%20like%20to%20enquire%20about%20your%20product%20or%20service%20as%20seen%20on%20EstateIQ.%0A%0AThanks.%0A%0APowered%20by%3A%C2%A0www.estateiq.ng`
  return text
}

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

let AccessPin = styled.form`
  background: #fff;
  text-align: center;
  padding: 50px;

  p {
    margin-top: 15px;
    text-align: center;
    color: #2d4bf3;
    font-weight: 500;
  }
  input {
    text-align: center;
  }
  button {
    padding: 20px;
    font-size: px;
    min-width: 200px;
  }
  h3 {
    font-size: 20px;

    font-weight: 700;
    line-height: 23px;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
  @media (min-width: 768px) {
    .mainPin {
      font-size: 64px;
      font-weight: 700;
      padding: 30px;
    }
  }
  .accessInfo {
    .eachUnit {
      margin: 20px 0;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #686868;
        margin-bottom: 5px;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
      .unitData {
        color: #111;
        border: 0;
        padding: 0;
        max-width: 300px;
        font-size: 25px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.06em;
        text-align: left;
      }
    }
  }
  .mainPin {
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    padding: 30px;
  }
`;

let HandleQuickAccess = styled.div`
  .modalContainer {
    margin-top: 80px;
    max-width: 650px;
  }
  @media (min-width: 600px) {
    .doubleInput {
      display: flex;
      justify-content: space-between;
      label {
        width: 100%;
      }
      input {
        width: 96%;
      }
    }
  }
`;

let MsgContainer = styled.div`
  margin: 0 auto;
  color: white;
  display: absolute;
  z-index: 9999;
  text-align: center;
  .slide-in-top {
    /* margin-top: 30px !important; */
    span {
      padding: 15px 20px;
      border-radius: 10px;
      font-weight: 400 !important;
      
    }
    .green{
      background: green !important;
    }
    .red {
      background: red !important;
    }
  }
`