import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAPI, HOST_URL, reqOptions } from "../assets/js/helpFunction";
import { SVGs } from "../assets/svg/SVGs";

const Packages = (props) => {
  const [items, setItems] = useState([]);

  const fetchItems = () => {
    const session = setItems;
    // const api = "https://example.com/items";
    const reqOption = reqOptions("GET", null);
    fetchAPI(session, HOST_URL() + "estate_plans/", reqOption, true);
    // .then(
    //   (response) => setItems(response.results)
    // );
  };

  useEffect(() => {
    fetchItems();
  }, []);

  if (items && items.results) {
    return (
      <div className="control_package fourcard_grid">
        {items.results.map((item) => (
          <div className="each_package grid-row">
            <img src="" alt="hello" />
            <div className="package_name">
              <h3>
                {item.plan_type.charAt(0).toUpperCase() +
                  item.plan_type.slice(1)}
              </h3>
              {/* <p>{props.permissions} Permisssions</p>
              <h4>{props.subscriber} Subsscribers</h4> */}

              <div className="inline">
                <Link to="/editpackage" className="edit">
                  Edit
                </Link>
                {/* <span to="" className="delete">
          Delete
        </span> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default Packages;
