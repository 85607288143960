import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SVGs } from "../assets/svg/SVGs";

let Card = styled.div`
  .mark {
    img {
      margin-bottom: 20px;
    }
    .num {
      display: flex;
      justify-content: space-between;
    }
  }
`;
const Overview_card = ({logs}) => {
  const granted = logs && logs.length && logs.filter(log => log.access ==="GRANT")
  const revoked = logs && logs.length && logs.filter(log => log.access ==="REVOKE")
  const PerOverview = (props) => {
    return (
      <Card className="grid-row per_overview">
        <Link to={props.link} className="mark">
          <img src={props.img} alt="" />
          <div className="num">
            <p>{props.type}</p>
            <h3>{props.count}</h3>
          </div>
        </Link>
      </Card>
    );
  };
  return (
    <div className="fourcard_grid all_overview">
      <PerOverview 
        img={SVGs.access_granted} type="Granted" count={granted.length ? granted.length : 0}
        link={"/external/accesslog"} 
        />
      <PerOverview 
        img={SVGs.access_denied} type="Declined" count={revoked.length ? revoked.length : 0} 
        link={"/external/accesslog"} 
      />
      <PerOverview 
        img={SVGs.vendor} type="Total" count={logs && logs.length} 
        link={"/external/accesslog"} 
      />
    </div>
  );
};

export default Overview_card;
