import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Images } from "../../assets/images/Images";
import { SVGs } from "../../assets/svg/SVGs";
import {
  getCookie,
  HOST_URL,
  loginRequired,
  SK_KEY,
  SK_VALUE,
} from "../../assets/js/helpFunction";
import { DelayedLink } from "../../components/partials/utils";

const Derivatives = () => {
  const [derivatives, setDerivatives] = useState(false);
  const navigate = useNavigate()
  // let { estate_id } = useParams();

  if (getCookie("access") === null) loginRequired(401);

  useEffect(() => {
    if (!derivatives) {
      // let myHeaders = new Headers();
      // myHeaders.append("EstateIQ-Sk-Header", SK_VALUE());
      // myHeaders.append("Authorization", "Bearer " + getCookie("access"));

      let myHeaders = {};
      myHeaders[SK_KEY] = SK_VALUE();
      myHeaders["Authorization"] = "Bearer " + getCookie("access");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(HOST_URL() + "estate_users/user_list/", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setDerivatives(JSON.parse(result));
          // console.log(requestOptions);
          console.log(JSON.parse(result));
        })
        .catch((error) => console.log("error", error));
    }
    // console.log(derivatives);
  }, [derivatives]);


  const get_urls = (user_type) => {
    const urls = {
      "RESIDENT": "/residents",
      "ADMIN": `/admin/`,
      'EXTERNAL': `/external`
    }
    return urls[user_type]
  }


  if (derivatives && derivatives.count >= 1) {
    return (
      <DerivativesPage>
        <div>
          <img src={Images.logo} alt="" className="logo-img" />
          {/* <img src={SVGs.completed} alt="" className="checked" /> */}
          <h1>Kindly Select The Estate That You Would Like To Sign Into</h1>
          <p>Your login was successful</p>

          {derivatives.results.map((derivative) => (
            <DelayedLink delay={1000} estate={derivative.estate} estate_id={derivative.estate.estate_id} to={get_urls(derivative.user_type)} key={derivative.id}>
              <div className="gratitude">
                <div className="svg">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ManageEstate"
                  >
                    <rect
                      x="0.327148"
                      y="0.296875"
                      width="39.4128"
                      height="39.4128"
                      rx="8.95745"
                      fill="#92B0FC"
                    />
                    <path
                      d="M24.4167 22.8832H26.0916V24.76H24.4167V22.8832ZM24.4167 19.1296H26.0916V21.0064H24.4167V19.1296ZM24.4167 15.376H26.0916V17.2528H24.4167V15.376ZM21.6865 15.376L22.7417 16.1642V15.376H21.6865Z"
                      fill="white"
                    />
                    <path
                      d="M18.5557 11.6221V13.0391L20.2306 14.2871V13.4989H27.7679V26.6365H24.418V28.5133H29.4428V11.6221H18.5557Z"
                      fill="white"
                    />
                    <path
                      d="M17.0223 14.1558L22.7422 18.4255V28.5133H11.0176V18.6413L17.0223 14.1558ZM18.5548 26.6365H21.0673V19.2794L17.0223 16.3985L12.6925 19.4859V26.6365H15.2049V21.0061H18.5548V26.6365Z"
                      fill="white"
                    />
                    <path
                      opacity="0.3"
                      d="M18.5547 26.6369H21.0671V19.2798L17.0221 16.3989L12.6924 19.4863V26.6369H15.2048V21.0065H18.5547V26.6369Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className="choose_txt">
                  <span>{derivative.estate.estate_id}</span>
                  <h4>{derivative.estate.name}</h4>
                  <p>
                    {derivative.estate.address}
                  </p>
                </div>
              </div>
            </DelayedLink>
            // <Link
            //   key={derivative.id}
            //   to={get_urls(derivative.user_type, derivative.estate.estate_id)}
            //   state={{estate_id : derivative.estate.estate_id}}
            //   onClick={clickHandler}
            // >
            //   <div className="gratitude">
            //     <div className="svg">
            //       <svg
            //         width="40"
            //         height="40"
            //         viewBox="0 0 40 40"
            //         fill="none"
            //         xmlns="http://www.w3.org/2000/svg"
            //         className="ManageEstate"
            //       >
            //         <rect
            //           x="0.327148"
            //           y="0.296875"
            //           width="39.4128"
            //           height="39.4128"
            //           rx="8.95745"
            //           fill="#92B0FC"
            //         />
            //         <path
            //           d="M24.4167 22.8832H26.0916V24.76H24.4167V22.8832ZM24.4167 19.1296H26.0916V21.0064H24.4167V19.1296ZM24.4167 15.376H26.0916V17.2528H24.4167V15.376ZM21.6865 15.376L22.7417 16.1642V15.376H21.6865Z"
            //           fill="white"
            //         />
            //         <path
            //           d="M18.5557 11.6221V13.0391L20.2306 14.2871V13.4989H27.7679V26.6365H24.418V28.5133H29.4428V11.6221H18.5557Z"
            //           fill="white"
            //         />
            //         <path
            //           d="M17.0223 14.1558L22.7422 18.4255V28.5133H11.0176V18.6413L17.0223 14.1558ZM18.5548 26.6365H21.0673V19.2794L17.0223 16.3985L12.6925 19.4859V26.6365H15.2049V21.0061H18.5548V26.6365Z"
            //           fill="white"
            //         />
            //         <path
            //           opacity="0.3"
            //           d="M18.5547 26.6369H21.0671V19.2798L17.0221 16.3989L12.6924 19.4863V26.6369H15.2048V21.0065H18.5547V26.6369Z"
            //           fill="white"
            //         />
            //       </svg>
            //     </div>

            //     <div className="choose_txt">
            //       <h4>{derivative.estate.name}</h4>
            //       <p>
            //         Plot 33, Abubakar Tafawa Balewa Way Central Business
            //         District, Cadastral Zone,, Abuja, {derivative.user_type}
            //       </p>
            //     </div>
            //   </div>
            // </Link>
          ))}
        </div>
      </DerivativesPage>
    );
  }
};

export default Derivatives;

let DerivativesPage = styled.section`
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
  display: block;
  @media (max-width: 400px) {
    padding: 0 20px;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0;
    color: rgba(23, 55, 229, 1);
    margin-top: 50px;
  }
  .logo-img {
    display: block;
    width: 150px !important;
    margin: 0 auto;
    margin-bottom: 50px;
    object-fit: cover;
    text-align: center !important;
  }
  p {
    text-align: center;
  }
  button {
    width: 100%;
  }
  .checked {
    width: 123px;
    height: 123px;
  }

  .gratitude {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    padding: 20px;
    background: #fbfbfb;
    transform: scale(1);
    transition: 0.4s;
    border: 1px solid rgba(44, 51, 58, 0.2);
    border-radius: 17.9149px;
    &:hover {
      transform: scale(1.02);
      background: #f2f6ff;
      border: 0.895745px solid #2d4bf3;
      border-radius: 17.9149px;
      .svg {
        transition: 0.4s;
        svg {
          path {
            fill: #ffffff;
          }
          rect {
            fill: #2d4bf3 !important;
          }
        }
      }
    }
    .svg {
      margin-top: 15px;
      transition: 0.4s;
      margin-right: 20px;
      svg {
        path {
          fill: #2d4bf3;
        }
        rect {
          fill: #f2f6ff !important;
        }
      }
    }
    .choose_txt {
      width: 90%;
      text-align: left;
      h4 {
        font-family: DM Sans;
        font-size: 22px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: -0.06em;
        text-align: left;
        color: #171717;
      }
      p {
        text-align: left;
      }
    }
  }
`;
