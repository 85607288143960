import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import {
  reqOptions,
  fetchAPI,
  HOST_URL,
  setCookie,
  // loginRequired,
} from "../../assets/js/helpFunction";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import TextField from "../../components/form/TextField";
import StatusMessage from "../../components/form/StatusMessage";
import { Images } from "../../assets/images/Images";
import SubmitBtn from "../../components/form/SubmitBtn";

const RequestPassword = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [messageType, setMessageType] = useState("");

  const [formData, setFormData] = useState({
    email: "",
  });

  const navigate = useNavigate()

  useEffect(() => {
    if (submitted && data && data.message && data.message.includes("Successfully")) {

      setTimeout(() => {
        return navigate('/otp-reset-password', { state: {email : formData.email} });
    }, 1000)
    } else if (submitted && data.status === "error") {
      setError(data.message);
      document.querySelector("[type=submit]").classList.remove("rolling");
      document.querySelector("[type=submit]").disabled = false;
    }
    console.dir(data)
  }, [submitted, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestOptions = reqOptions("post", JSON.stringify(formData));
    setSubmitted(true)
    // Enable Spinning button
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;
    fetch(HOST_URL() + "auth/request_otp/", requestOptions)
    .then(res => res.json())
    .then(response => {
      if(response.status == undefined){
        setData({...response,status : "error"})
      }else{
        setData(response)

      }
    })
    .catch(err => {
      setMessageType("error")
      e.target.querySelector("[type=submit]").classList.remove("rolling");
      e.target.querySelector("[type=submit]").disabled = false;
    })
    // fetchAPI(
    //   setData,
    //   HOST_URL() + "auth/request_otp/",
    //   requestOptions,
    //   true,
    //   setMessageType,
    //   setError
    // );
  };
  return (
    <div className="acct_info">
      <StatusMessage
        setMessageType={setMessageType}
        messageType={data && data.status}
        message={data && data.message}
      />
      <div className="create_bg">
        <div className="create_est">
          <Link to={"/"}>
              <img src={Images.logo} alt="Estate-IQ" />
          </Link>
          <form action="" onSubmit={handleSubmit} className="fade-in-bck">
            <div className="form_txt">
              <p className="with_link">
                <Link to="/login">Back to Login</Link>
              </p>
              <h1>Reset password</h1>
              {/* <h4></h4> */}
              <p className="with_link">
                Enter the email associated with your account and we’ll send an
                email with instructions to reset your password.
              </p>
            </div>

            <TextField
              types="email"
              labels="Email Address"
              names="email"
              defaultValue={formData.email}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
            {/* <TextField types="password" labels="Password" names="password" /> */}

            {/* <Link to="/"> */}
            <SubmitBtn
              types="submit"
              className="important-btn"
              labels="Reset Password"
            />
            {/* </Link> */}
          </form>
        </div>
      </div>
      <div className="create_img">
        <img src={Images.LoginPreview} alt="" />
      </div>
    </div>
  );
};

export default RequestPassword;
