import { useState, useEffect } from "react";
// // import Pagination from "rc-pagination";
import Modal from "./AddNew";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { WithSuperAuth } from "../S-Overview";
import { HOST_URL, reqOptions } from "../../../assets/js/helpFunction";
import { SVGs } from "../../../assets/svg/SVGs";
import { MssgContainer } from "../../../components/partials/utils";
// import TopNav from "../../../components/Navbar/TopNav";

const Estate = () => {
  const [estates, setEstates] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const [mssg, setMssg] = useState({ error: false, mssg: "" })


  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }
  const handleClick = (status, index) => {
    setActiveIndex(index)
    const requestOptions = reqOptions("get", null, true);
    // console.log('status', status)
    const estates_api = `${HOST_URL()}estates/super_admin/${status && "?status=" + status}`;
    fetch(estates_api, requestOptions)
      .then(res => res.json())
      .then(response => setEstates(response.results?.filter(res => !status ? true : res.status === status)))
      .catch(err => console.log(err))
  };
  const checkActive = (index, className) => {
    return activeIndex === index ? className : "";
  }

  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    const estates_api = `${HOST_URL()}estates/super_admin/`;
    fetch(estates_api, requestOptions)
      .then(res => res.json())
      .then(response => {
        console.log(response)
        setEstates(response.results)
      })
      .catch(err => console.log(err))
  }, [])


  const ActivateEstate = estate => {
    const modifyURL = `${HOST_URL()}estates/super_admin/${estate.estate_id}/`
    const status = estate.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
    const requestOptions = reqOptions('put',
      JSON.stringify({ status }), true)
    fetch(modifyURL, requestOptions)
      .then(res => res.json())
      .then(response => {
        if (response.id) {

          setMssg({ ...mssg, mssg: `Estate ${status.toLocaleLowerCase() === "active" ? "activated" : "deactivated"} successfully` })
          const edited_data = estates.map(item => {
            if (item.id == response.id) {
              return { ...item, status: response.status }
            }
            return item
          })
          setEstates(edited_data)
        } else {
          setMssg({ ...mssg, mssg: response.detail })
        }
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }

  return (
    <WithSuperAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
      <div className="estates_tab">
        <div className="adding_new">
          <div className="tabs">
            <button
              className={`tab ${checkActive(1, "active")}`}
              onClick={() => handleClick("", 1)}
            >
              All Estates
            </button>
            <button
              className={`tab ${checkActive(2, "active")}`}
              onClick={() => handleClick("ACTIVE", 2)}
            >
              Active Estates
            </button>
            <button
              className={`tab ${checkActive(3, "active")}`}
              onClick={() => handleClick("INACTIVE", 3)}
            >
              Inactive Estate
            </button>
            <button
              className={`tab ${checkActive(4, "active")}`}
              onClick={() => handleClick("PENDING", 4)}
            >
              Pending Estate
            </button>
            <button
            // className={`tab ${checkActive(4, "active")}`}
            // onClick={() => handleClick("PENDING", 4)}
            >
              No  ({estates.length})
            </button>
          </div>
          <button
            className="important-btn"
            onClick={() => setOpenModal(true)}
          >
            Add New Estate
          </button>
        </div>

        {/* RESULT FROM TAB */}
        <div className="panels flex_2">
          <div className={`panel active`}>
            <div className="threecards_grid estate_cards">
              {estates && estates.length ? estates.map(estate => (
                <div className="per_estate" key={estate.id}>
                  <Link to={`/superadmin/estates/${estate.estate_id}`}>
                    <button className="outlined-btn">
                      {estate.days_left}
                    </button>
                    <div className="name_id">
                      <h2>{estate.name}</h2>
                      <p>{estate.estate_id}</p>
                    </div>
                    <h3>{estate.estate_type}</h3>
                    <div className="location_before">
                      <p>
                        {estate.address}
                      </p>
                    </div>
                    <p>{estate.status}</p>
                  </Link>
                  <div>
                    <div class="checkbox_item citem_1">
                      <label class="checkbox_wrap">
                        <input
                          type="checkbox" name="checkbox"
                          class="checkbox_inp"
                          checked={estate.status === "ACTIVE" ? true : false}
                          onChange={e => ActivateEstate(estate)}
                        />
                        <span class="checkbox_mark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              )) : <p>No estate yet!</p>}
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} />
    </WithSuperAuth>
  );
};

export default Estate;



let Notification = styled.div`
  position: relative;
  display: none;
  .n_badge {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    right: 0;
    top: 56px;
    width: 360px;

    border-radius: 8px;
    .all_notification {
      height: 360px;
      overflow: auto;
      padding: 10px 15px;
      .each_noti {
        padding: 10px 5px;
        border-bottom: 1px solid rgba(33, 33, 33, 0.15);
        padding-left: 60px;
        cursor: pointer;
        &:hover {
          background-color: #f3f3f3;
        }
        p {
          margin: 0;
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.03em;
          text-align: left;

          span {
            color: #2d4bf3;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.06em;
            text-align: left;
            margin-left: 8px;
            &:before {
              content: "";
              display: inline-block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              margin-right: 5px;
              background: #2d4bf3;
            }
          }
        }
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center !important;
      letter-spacing: 0em;
      padding: 12px;
      margin: 0 !important;
      background-color: #ffffff;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 0px 0px;
      text-align: left;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      background: #ffffff;
      height: 20px;
      border-radius: 3px;
      transform: rotate(45deg);
      top: -10px;
      position: absolute;
      right: 25px;
    }
  }
`;
