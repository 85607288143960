import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Images } from "../../assets/images/Images";
import { SVGs } from "../../assets/svg/SVGs";
import { getImage } from "../partials/utils";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  position: relative;
  height: 100%;
  flex-flow: row nowrap;
  z-index: 10;
  li {
    padding: 14px 20px;
    color: #2c333a !important;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #e1e1e1;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 0;
  }
  li:hover {
    padding: 14px 20px;
    background: #f2f6ff;
  }

  @media (max-width: 480px) {
    max-width: auto !important;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #ffffff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
      0px 2px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 78px;
    right: 0;
    height: 100vh;
    max-width: 400px;
    width: 100%;
    padding-bottom: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

const RightNav = ({ open, user }) => {
  let { estate_id } = useParams();
  // const user = JSON.parse(localStorage.getItem("user"))
  return (
    <Ul open={open}>
      <div
        className="class_relative"
        style={{ position: "relative", height: "95%" }}
      >
        <div className="search_profile">
          <div className="violet">
            <Link to="/admin/settings/">
              <div className="user_mode">
                <img
                  src={user && getImage(user.profile_image)}
                  alt={user?.estate_user?.user?.first_name}
                />
                <div>
                <h3>{user?.estate_user?.user?.first_name} {user?.estate_user?.user?.last_name}</h3>
                  {/* <h3>{user.first_name} {user.last_name}</h3> */}
                  <p>Admin</p>
                </div>
              </div>
            </Link>
          </div>

          {/* <input type="text" placeholder="Search" /> */}
        </div>
        <Link to={`/admin/`}>
          <li className="before_list over_view">Dashboard</li>
        </Link>
        <Link to="/admin/residents/">
          <li className="before_list est">Residents</li>
        </Link>
        <Link to={`/admin/activitylog/`}>
          <li className="before_list tsk">Access Log</li>
        </Link>
        <Link to="/admin/complaint/">
          <li className="before_list comp">Complaints</li>
        </Link>
        <Link to={`/admin/utilities/`}>
          <li className="before_list psm">Utilities</li>
        </Link>
        <Link to="" style={{ opacity: "0.4" }}>
          <li className="before_list msg">Messages</li>
        </Link>
        <Link to="/admin/transaction">
          <li className="before_list psm">Transaction </li>
        </Link>

        <div className="loging_out">
          <Link to="/logout">
            <li className="before_list log_out">Logout</li>
          </Link>
        </div>
      </div>
    </Ul>
  );
};

export default RightNav;
