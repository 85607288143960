import React from "react";
import styled from "styled-components";
import { Images } from "../../assets/images/Images";
import { Link } from "react-router-dom";

const Blog1Content = () => {
  return (
    <HandleBg>
      <Link to='/blog1'>
        <section className="webContainer" style={{ cursor: 'pointer' }}>
          <h1>What to Consider When Choosing a Place to Live in a Residential Estate in Nigeria.</h1>
          <p>Are you looking to move to a new estate? These are a few things you should look out for before you make your decision.</p>
          <h1>1.Proximity </h1>
          <p>You should be sure you live in an estate close to your workplace and other locations of interest and hobbies because these are the places where you spend most of your time outside of the home. Think about how factors like travel time to and from the estate affect your ability to strike a work-life balance. The stress levels of some people can be elevated by the time they spend commuting in certain cities, such as Lagos, because of the chronic traffic congestion. This has a domino effect on your health and relationships, as it lowers your energy and makes you less productive at work. Living closer to your place of employment can also help you save money on commuting, which can be a burden due to the increasing cost of fuel. In some rare cases, residing in a nearby estate with a higher rental value may be more cost-effective over time than living in a distant estate with a lower rental value.</p>
          <h1>2.Security </h1>
          <p>For many, the peace of mind that comes with living in a gated community is paramount. Given the rise of organized crime in recent years—including kidnappings, drug trade, cybercrime, and armed robberies—it is clear that effective security measures go beyond gates and guards. The degree to which you and your family members are in danger depends on the estate's level of porosity. You want to ensure that the place you want to live has good security measures in place, such as trained guards, digital entry and exit logs (such as access control platforms like EstateIQ), and CCTV surveillance cameras that can enhance investigation should there be a security breach. </p>
          <h1>3.Functional Residents’ Association </h1>
          <p>This may not seem important at first glance, but it becomes a major consideration when residents are bound by some shared infrastructure such as water supply, central sewage system, security measures, and alternative power supply. It is a great asset in protecting the rights of all residents and finding solutions to problems that impact the entire estate. For instance, estates that have responsible and active and united associations tend to have better-maintained infrastructure and foster a feeling of community among residents, which makes them want to stay put.</p>
          <h1>4.Estate Type </h1>
          <p>There are different types of estate, which include strictly residential, commercial, and mixed (a combination of residential and commercial, such as business offices and short-let apartments). Each of these has its benefits. However, it is important to note that depending on the type of commercial activity, these activities may inhibit high levels of privacy and sanity, as some of them may cause nuisances like noise pollution, abuse of substances, and cybercrime. Recently, there have been reported cases of some users of short-let apartments throwing wild-night parties that disturb the peace of other residents.</p>
          <h1>5.Main Grid Power Supply </h1>
          <p>This is important because a lack of it can affect productivity and largely impact one’s overall well-being.
            Consistent power supply, especially from the main grid, enhances comfort and convenience in the home and is cost-effective.
            Alternative power supply sources like solar panels or generators provide resilience during grid outages, ensuring that essential services and
            appliances remain operational even during emergencies. However, it would be wise to consider the average power supply that the estate enjoys, say in a day,
            week, or month. It is quite discouraging to know that some estates don’t even enjoy as much as 3 hours of power supply per day from the main grid.
            We work with estates to provide additional layers of convenience and security for their residents. If you would like to know more about our digital platform for estates, you should check
            it out <a target="_blank" style={{ color: 'blue' }} href='https://estateiq.ng/#Features'> here  </a>
            or reach out <a target="_blank" style={{ color: 'blue' }} href='https://estateiq.ng/contact'> here</a>
          </p>
        </section>
      </Link>
    </HandleBg>
  );
};

export default Blog1Content;

let HandleBg = styled.section`
  //   background: #f2f6ff;
  padding: 80px 0;
  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  h1 {
    margin-top: 30px;
  }
`;
