import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate"; //  Using react-paginate from the react library
import styled from "styled-components";

import AdminTransactionTable from "../../components/AdminTransactionTable";
import WithAuth from "../../components/partials/withAuth";
import { useParams } from "react-router-dom";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { getEstateID, MssgContainer } from "../../components/partials/utils";

function AdminTransaction() {
  const [data, setData] = useState({})
  const [transactions, setTransactions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [search, setSearch] = useState("");
  const [mssg, setMssg] = useState({error : false, mssg : ""})

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  const FILTER_OPTIONS = ['All']

  const checkActive = (index) => (activeIndex === index ? "active" : "");

  
  useEffect(() => {
    let requestOptions = reqOptions('get', null, true)
    const api = `${HOST_URL()}estate_utilities/utility/estate_transaction/?estate_id=${getEstateID()}`
    fetch(api, requestOptions)
    .then(res => res.json())
    .then(data => {
      setTransactions(data.results)
      setData(data)
    })
    .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
  }, []);
  const filterTransaction = (index, name) => {
    setActiveIndex(index);
    document
      .querySelectorAll("tab")
      .forEach((item) =>
        item.classList.includes(activeIndex)
          ? item.classList.add("active")
          : item.classList.remove("active")
      );
    setSearch("")
    let requestOptions = reqOptions('get', null, true)
    if (name == "Utility") {
      const api = `${HOST_URL()}estate_utilities/utility/estate_transaction/?estate_id=${getEstateID()}`
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setTransactions(data.results)
        setData(data)
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
    } else {
      const api = `${HOST_URL()}estate_plans/transaction/list/?estate_id=${getEstateID()}`
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setTransactions(data.results)
        setData(data)
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    // const requestOptions = reqOptions("get", null, true);
    // const api = `${HOST_URL()}estate_complaints/?estate_id=${estate_id}&search=${value}`;
    // fetch(api, requestOptions)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setComplaints(data.results)
    //     setData(data);
    //   })
    //   .catch((err) => console.log(err));
  };
  const handleChange = e => {
    const { value, name } = e.target;
  }
  const getDate = date =>{
    const dateObj = new Date(date)
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    let newDate = `${days[dateObj.getDay()]}. ${dateObj.getDate()} ${months[dateObj.getMonth()]}, ${dateObj.getFullYear()}`
    return newDate
  }
  return (
    <WithAuth>
      <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
        <HandleSearchAndTab>
          <div className="tabs">
            <span
              className={`tab 1 ${checkActive(1)}`}
              onClick={() => filterTransaction(1, "Utility")}
            >
              <button>Utility</button>
            </span>

            <span
              className={`tab 2 ${checkActive(2)}`}
              onClick={() => filterTransaction(2, "Plan")}
            >
              <button>Plan</button>
            </span>
          </div>
          <div className="event-input">
          <select onChange={handleChange} default="All" className="form-control"
          style={{height : "3rem"}}
          >
                {FILTER_OPTIONS.map(option => <option value={option}>{option}</option>)}
          </select>
            <div class="search_set">
              <img src="https://www.svgrepo.com/show/13682/search.svg" alt="" />
              <input
                className="eventt"
                type="text"
                name="name"
                placeholder="Search"
                onChange={handleSearch}
                value={search}
              />
            </div>
          </div>
        </HandleSearchAndTab>
        {transactions && transactions.length ? (
          <table>
            <thead>
              <tr className="transaction-heading">
                <th className="checkmarking">
                  {/* CUSTOM CHECKBOX */}
                  <div class="cntr">
                    <label for="resume" class="label-cbx">
                      <input id="resume" type="checkbox" class="invisible" />
                      <div class="checkbox">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                          <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
                          <polyline points="4 11 8 15 16 6"></polyline>
                        </svg>
                      </div>
                    </label>
                  </div>
                </th>
                <th>Amount</th>
                <th>Payment Type</th>
                <th>Purpose</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                    />
                  </td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.payment_type}</td>
                  <td>{transaction.purpose}</td>
                  <td>{getDate(transaction.timestamp)}</td>
                  <td>{transaction.status}</td>
                </tr>
              ))}
            </tbody>
            <tbody></tbody>
          </table>
        ) : (
          <p>No Transaction made Yet</p>
        )}
      </div>
      {/* <AdminTransactionTable /> */}
    </WithAuth>
  );
}

export default AdminTransaction;

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (max-width: 420px) {
    .search_set,
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .search_set {
      width: 220px;
    }
    .outlined {
      width: 180px !important;
    }
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin: 0 10px;
        width: 135px;
      }
      button {
        margin-top: 0;
        height: 45px;
        margin-left: 10px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;
      padding: 5px 20px;
      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
const TableFrame = styled.div`
  .team-members {
    margin-top: 40px;
    h2 {
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .Utility_table {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn {
    display: flex;
  }
  .utils {
    margin-bottom: 10px;

    padding: 16px;
    width: 100%;
    border: 1px solid #2d4bf3;
    border-radius: 5px;
    .name_tag {
      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      button {
        margin: 0;
        font-weight: 400;
        padding: 2px 8px;

        font-size: 14px;
        margin-top: 10px;
        background: #f2f6ff;
        border: 1px solid #2d4bf3;
        border-radius: 5px;
      }
    }
  }

  .reason {
    width: 128px;
    height: 24px;
    background: #f2f6ff;
    border: 1px solid #2d4bf3;
    border-radius: 5px;
    padding: 4px 8px;
  }
  .price {
    margin-top: 10px;
  }
  .util-acct {
    margin-top: 10px;
  }

  .del-btn {
    color: #ff6969;
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-left: 15px;
    cursor: pointer;
  }
  .edit-btn {
    color: #2d4bf3;
    // display: flex;

    cursor: pointer;

    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 540px) {
    .utils {
      width: 48%;
    }
  }
  @media (min-width: 1024px) {
    .utils {
      width: 33%;
    }
  }
  @media (min-width: 1200px) {
    .utils {
      width: 24%;
    }
  }
`;
