import React, { useEffect, useState } from "react";
import TextField from "../../../components/form/TextField";
import { reqOptions, HOST_URL, getCookie } from "../../../assets/js/helpFunction";
import { faCaretLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { States } from "../../../assets/json/States";
import { useCookies } from "react-cookie";

import styled from "styled-components";
import { Images } from "../../../assets/images/Images";
import SearchInput from "../../../components/form/SearchInput";
import StatusMessage from "../../../components/form/StatusMessage";
import SubmitBtn from "../../../components/form/SubmitBtn";
import { Link } from "react-router-dom";
import StateSelect, { LGASelect } from "../../../components/form/SelectFields";
import { SVGs } from "../../../assets/svg/SVGs";
import Swal from 'sweetalert2'

function generateID(length) {
  let text = "";
  const possible = "123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return `EIQ${text}`;
}


const MasterForm = () => {
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailRegistered, setIsEmailRegistered] = useState(false);
  const [data, setData] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [cookies, setCookie] = useCookies(["userEmail"]);
  const isChecked = true;
  /*
    Show message for "success" or "error".
    The default state is ''.
    Render the StatusMessage below the Header
    props: [type, setType, message]
  */
  const [messageType, setMessageType] = useState("");
  const [error, setError] = useState([]);
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    mobile: "",
    estate_name: "",
    estate_address: "",
    estate_state: "Lagos",
    estate_country: "Nigeria",
    estate_lga: "",
    estate_choice: "CREATE",
    estate_id: "",
    user_house_address: ""
  });


  useEffect(() => {
    setFormData({
      ...formData,
      estate_id: `${generateID(5)}`,
    });
  }, []); // this will generate the random id when the component is loaded

  const FormTitles = ["Admin Info", "EstateInfo"];

  useEffect(() => {
    if (status === 201 || status === 200) {
      // window.location.href = "/checkemail";
      // setCookie("userEmail", formData.email, { path: "/checkemail" });
      // setCookie("firstName", formData.first_name, { path: "/checkemail" });
      Swal.fire({
        title: `You just created a new estate you named <h1>${formData.estate_name}</h1>Email has been sent to  <h4> ${formData.email} </h4></div>`,
        icon: "success",
        showConfirmButton: true,
        showCloseButton: true,
      });
    }

  }, [status, formData.email, formData.first_name]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Enable Spinning button
  //   e.target.querySelector("[type=submit]").classList.add("rolling");
  //   e.target.querySelector("[type=submit]").disabled = true;
  //   e.target.reset();

  //   let requestOptions = reqOptions("POST", JSON.stringify(formData));
  //   console.log(formData);
  //   fetch(HOST_URL() + "auth/registration/", requestOptions)
  //     .then((response) => {
  //       setStatus(response.status);
  //     })
  //     .catch((error) => {
  //       console.error("Request failed", error);
  //     });
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Enable Spinning button
    e.target.querySelector("[type=submit]").classList.add("rolling");
    e.target.querySelector("[type=submit]").disabled = true;
    e.target.reset();
    let requestOptions = reqOptions("POST", JSON.stringify(formData));
    try {
      const response = await fetch(
        HOST_URL() + "auth/registration/",
        requestOptions
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        let errorMessage = ''
        let index = 1
        for (const key in errorResponse) {
          errorMessage += `${index}). ${key}: ${''.concat(...errorResponse[key]).replace('.', '. ')}`
          index += 1
        }
        setFormError(errorMessage || "An error occurred.");
        e.target.querySelector("[type=submit]").disabled = false;
        e.target.querySelector("[type=submit]").classList.remove("rolling");
        return;
      }
      // console.log(response, "responseeeee")
      setStatus(response.status);
    } catch (error) {
      setFormError("An error occurred.");
      e.target.querySelector("[type=submit]").disabled = false;
      e.target.querySelector("[type=submit]").classList.remove("rolling");
      return;
    }
  };

  const PageDisplay = () => {
    if (page === 0) {
      return <AdminInfo formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return <EstateInfo formData={formData} setFormData={setFormData} />;
    } else {
      return <OtherInfo formData={formData} setFormData={setFormData} />;
    }
  };

  if (getCookie("access")) {
    // return window.location.replace("/my-estates")
  }

  const handleNext = (e) => {
    setFormError('')
    if (page === FormTitles.length - 1) {
      // alert("FORM SUBMITTED");
      e.target.type = "submit";
    } else {
      if (!formData.email || !formData.first_name || !formData.last_name || !formData.mobile || !formData.password1)
        return setFormError('Please complete the form')
      if (formData.password1 !== formData.password2)
        return setFormError("Password doesn't match")

      setPage((currPage) => currPage + 1);
    }
  }

  return (
    <HandleFormMultiStep>
      <StatusMessage
        setMessageType={setMessageType}
        messageType={messageType}
        message={error.non_field_errors ? error.non_field_errors[0] : ""}
      />
      {/* <h2>Whats Up</h2> */}
      <div className="form_frame form_frame_edit" style={{ width: '100%' }}>
        <div className="renovated">
          <div className="plus_img">
            <form onSubmit={handleSubmit} className=" fade-in-bck">
              <div className="progressbar">
                <div
                  style={{
                    width: page === 0 ? "50%" : page == 1 ? "100%" : "100%",
                  }}
                ></div>
              </div>
              <div className="form-container">
                <div className="body">{PageDisplay()}</div>

                <div className="take_actions">
                  <button
                    type="button"
                    disabled={page == 0}
                    className={page == 0 ? "hide" : "outlined-btn prev-btn"}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                    }}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} />
                  </button>
                  {/* <button
                    type="button"
                    className="next_btn important-btn"
                    onClick={(e) => {
                      if (page === FormTitles.length - 1) {
                        // alert("FORM SUBMITTED");

                        e.target.type = "submit";
                      } else {
                        setPage((currPage) => currPage + 1);
                      }
                    }}
                  >
                    {page === FormTitles.length - 1 ? "Create Estate" : "Next"}
                  </button> */}
                  <SubmitBtn
                    types="button"
                    add_class="next_btn important-btn"
                    disabled={page === (FormTitles.length - 1) ? isChecked ? false : true : false}
                    onClick={handleNext}
                    labels={
                      page === FormTitles.length - 1 ? "Create Estate" : "Next"
                    }
                  />
                </div>

                {formError && <p className="error">{formError}</p>}
              </div>
              {/* <div className="form_txt">
                <p className="with_link" style={{ textAlign: 'center' }}>
                  Existing user? <Link to="/login" style={{ color: 'blue', fontWeight: 'bolder' }}> Log in</Link> or <Link to="/join-estate" style={{ color: 'blue', fontWeight: 'bolder' }}> Join an estate</Link>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </HandleFormMultiStep>
  );
};


function AdminInfo({ formData, setFormData }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError(null);
    // setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value === "") {
      setError(null);
    } else if (event.target.value !== password) {
      setError("Passwords do not match");
    } else {
      setError(null);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="fade-in-bck">
      <div className="form_txt">
        <p>Step 1 of 2</p>
        <h1>Create Estate</h1>
        <h4>Admin Info</h4>
      </div>

      <TextField
        types="text"
        labels="First Name"
        names="first_name"
        defaultValue={formData.first_name}
        onChange={(event) =>
          setFormData({ ...formData, first_name: event.target.value })
        }
      />
      <TextField
        types="text"
        labels="Last Name"
        names="last_name"
        defaultValue={formData.last_name}
        onChange={(event) =>
          setFormData({ ...formData, last_name: event.target.value })
        }
      />
      <TextField
        types="email"
        labels="Email"
        names="email"
        defaultValue={formData.email}
        onChange={(event) =>
          setFormData({ ...formData, email: event.target.value })
        }
      />
      <TextField
        types="tel"
        labels="Phone Number"
        names="mobile"
        defaultValue={formData.mobile}
        onChange={(event) =>
          setFormData({ ...formData, mobile: event.target.value })
        }
      />
      <TextField
        types="hidden"
        labels=""
        names="estate_choice"
        defaultValue={formData.estate_choice}
        onChange={(event) =>
          setFormData({ ...formData, estate_choice: event.target.value })
        }
      />

      <div className="password_v">
        <TextField
          labels="Password"
          names="password1"
          defaultValue={formData.password1}
          types={showPassword ? "text" : "password"}
          onChange={(e) => {
            handlePasswordChange(e);
            setFormData({ ...formData, password1: e.target.value });
          }}
        />
        <p onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </p>
      </div>
      <div className="password_v">
        <TextField
          labels="Confirm Password"
          names="password2"
          types={showPassword ? "text" : "password"}
          defaultValue={formData.password2}
          onChange={(e) => {
            handleConfirmPasswordChange(e);
            setFormData({ ...formData, password2: e.target.value });
          }}
        />
        <p onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </p>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

function EstateInfo({ formData, setFormData }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [state, setState] = useState(States.find(state => state.state === "Lagos"))



  const handleRandomId = () => {
    setFormData({
      ...formData,
      estate_id: generateID(7),
    });
  };
  const handleEstateStateChange = e => {
    setFormData({ ...formData, estate_state: e });
    setState(States.find(item => item.state === e))
  };

  return (
    <div className="fade-in-bck">
      <div className="form_txt">
        <p>Step 2 of 2</p>
        <h1>Create Estate</h1>
        <h4>Estate Info</h4>
      </div>
      <TextField
        types="text"
        labels="Estate ID"
        id="estateId"
        names="estate_id"
        readOnly="readOnly"
        defaultValue={formData.estate_id}
        onChange={(event) =>
          setFormData({ ...formData, estate_id: event.target.value })
        }
      />
      <TextField
        types="hidden"
        labels=""
        names="estate_country"
        defaultValue={formData.estate_country}
        onChange={(event) =>
          setFormData({ ...formData, estate_country: event.target.value })
        }
      />
      <TextField
        types="text"
        labels="Estate Name"
        names="estate_name"
        onKeyUp={handleRandomId}
        defaultValue={formData.estate_name}
        onChange={(event) =>
          setFormData({ ...formData, estate_name: event.target.value })
        }
      />
      <TextField
        types="text"
        labels="Estate Address"
        names="estate_address"
        defaultValue={formData.estate_address}
        onChange={(event) =>
          setFormData({ ...formData, estate_address: event.target.value })
        }
      />

      <TextField
        types="text"
        labels="Home Address within the Estate"
        names="user_home_address"
        defaultValue={formData.user_house_address}
        onChange={(event) =>
          setFormData({ ...formData, user_house_address: event.target.value })
        }
      />


      {/* <select
        className="form-control"
        name="estate_state"
        value={formData.estate_state}
        style={{margin : "15px 0"}}
        onChange={(event) => {
          setFormData({ ...formData, estate_state: event.target.value })
          setState(States.find(state => state.state === event.target.value))
        }}
        >
        {States.map((state, key) => (
          <option value={state.state} key={key}>{state.state}</option>
        ))}
      </select> */}
      <div style={{ margin: "10px 0" }}>
        <label>State</label>
        <StateSelect options={States} selected={formData.estate_state} handleSelect={handleEstateStateChange} />
      </div>

      <div style={{ margin: "10px 0" }}>
        <label>Local Government Area</label>
        <LGASelect options={state.lgas} selected={formData.estate_lga ? formData.estate_lga : 'Select LGA'}
          handleSelect={e => setFormData({ ...formData, estate_lga: e })} />
      </div>

      {/* <select
        className="form-control"
        name="estate_state"
        value={formData.estate_lga}
        onChange={(event) => setFormData({ ...formData, estate_lga: event.target.value })}
        style={{margin : "15px 0"}}
        >
        {state?.lgas.map((lga, key) => (
          <option value={lga} key={key}>{lga}</option>
        ))}
      </select> */}

    </div>
  );
}

function OtherInfo({ formData, setFormData }) {
  return (
    <div className="other-info-container">
      <input
        type="text"
        placeholder="Nationality..."
        value={formData.nationality}
        onChange={(e) => {
          setFormData({ ...formData, nationality: e.target.value });
        }}
      />
      <input
        type="text"
        placeholder="Other..."
        value={formData.other}
        onChange={(e) => {
          setFormData({ ...formData, other: e.target.value });
        }}
      />
    </div>
  );
}

let HandleFormMultiStep = styled.section`
  .hide {
    display: none;
  }
  .error {
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
  }
  .take_actions {
    display: flex;
    justify-content: space-between;
  }
  .prev-btn {
    width: auto !important;
    padding: 10px 25px;
  }
  .next_btn {
    width: auto !important;
    padding: 10px 25px;
    background: var(--Brand-color) !important;
  }
  .password_v {
    position: relative;
    p {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 20px;
    }
  }
  .actions_on {
    display: flex;
    justify-content: space-between;
  }
  h1 {
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    margin-bottom: 8px;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 20px;
  }
  input {
    margin-bottom: 15px;
  }
  .switch_to {
    background-color: var(--Brand-color);
    color: #fff;
  }
  .important-btn {
    width: 100%;
    margin-top: 25px;
    background-color: var(--Brand-color);
  }
`;
const AllStates = States;


const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <MasterForm />
        {/* <img src={SVGs.close} alt="" onClick={onClose} /> */}
      </div>
    </div>
  );
};
export default Modal;
