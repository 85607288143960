import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  HOST_URL,
  reqOptions,
} from "../../assets/js/helpFunction";
import styled from "styled-components";
import WithAuth from "../../components/partials/withAuth";
import { SVGs } from "../../assets/svg/SVGs";
import { getEstateID, MssgContainer } from "../../components/partials/utils";


const AdminUtilities = () => {
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [utilities, setUtilities] = useState([]);
  const [data, setData] = useState({})
  const [editedUtility, setEditedUtility] = useState({})
  const [mssg, setMssg] = useState({error : false, mssg : ""})

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  useEffect(() => {
    const requestOptions = reqOptions('get', null, true)
    const api = `${HOST_URL()}estate_utilities/?estate_id=${getEstateID()}`
    fetch(api, requestOptions)
    .then(res => res.json())
    .then(data => {
      setUtilities(data.results)
      setData(data)
    })
    .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
  }, []);

  const editUtility = id => {
    const item =  utilities.find(element => element.id == id);
    setEditedUtility(item)
    setEdit(true)
    setOpenModal(true)
  };

  const deleteUtility = id => {
    const api = `${HOST_URL()}estate_utilities/${id}/?estate_id=${getEstateID()}`
      const requestOptions = reqOptions('delete', null, true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setMssg({...mssg, mssg : 'Utility Deleted Successfully', error : true})
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        // setUtilities(utilities.filter((utility) => id !== utility.id));
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
  };
  const addUtility = utility => {
    
    if (edit){
      const api = `${HOST_URL()}estate_utilities/${utility.id}/?estate_id=${getEstateID()}`
      const requestOptions = reqOptions('put', JSON.stringify(utility), true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data.id){
          setMssg({...mssg, mssg : "Utility Updated successfully"})
          const edited_data =  utilities.map(item => {
            if(item.id == editedUtility.id){
              return data
            }
            return item
          })
          setUtilities(edited_data)
        }else{
          setMssg({...mssg, mssg : "Error Updating Utility", error : true})
        }
        
      })
      .catch(err => setMssg({...mssg, mssg : err.message, error : true}))
      
    }else{
      const api = `${HOST_URL()}estate_utilities/?estate_id=${getEstateID()}`
      const requestOptions = reqOptions('post', JSON.stringify(utility), true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        if(data.id){
          setMssg({...mssg, mssg : "Utility Created successfully"})
          setUtilities([...utilities, data])
        }else{
          setMssg({...mssg, mssg : "Error Creating Utility", error : true})
        }
        
      })
      .catch(err => setMssg({...mssg, mssg : err.name, error : true}))

    }
    setEditedUtility({})
    setEdit(false)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setEditedUtility({})
    setEdit(false)
  }
  
  return (
    <WithAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
      <div>
        <button className="important-btn" onClick={() => setOpenModal(true)}>
          Add Utility
        </button>
      </div>
      <UtilityGrid>
        <div className="Utility_table">
          {utilities.length ? (
            utilities.map((utility) => (
              <div className="utils" key={utility.id}>
                <div className="util-payment">
                  <div className="name_tag">
                    <h4>{utility.name}</h4>
                    <button>{utility.name}</button>
                  </div>

                  <div className="util-acct">
                    <p>
                      Price: <b>{utility.price}</b>
                    </p>
                    <div className="price">
                      <p>
                        {utility.bank_name}: <b>{utility.account_number}</b>
                      </p>
                    </div>
                  </div>
                  <div>
                    <span 
                      className="edit-btn util-btn"
                      onClick={() => editUtility(utility.id)}
                    >
                      Edit
                    </span>
                    <span
                      className="del-btn util-btn"
                      onClick={() => deleteUtility(utility.id)}
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>
              No utility created at the moment. <a 
                onClick={() => setOpenModal(true)} 
                style={{cursor : 'pointer', color : '#2D4BF3', fontWeight : 'bolder'}}
              >
                  Create one
              </a>
            </p>
          )}
        </div>
      </UtilityGrid>
      <Modal open={openModal} onClose={handleCloseModal} addUtility={addUtility} edit_data={editedUtility} edit={edit}/>
    </WithAuth>
  );
};

export default AdminUtilities;

const Modal = ({ open, onClose, addUtility, edit_data, edit }) => {
  // const data = edit_data
  const FREQUENCY_OPTIONS = ['MONTHLY','QUARTERLY','HALF_YEARLY', 'YEARLY']
  const COLLECTION_TYPE = ['NOT_MANDATORY', 'MANDATORY']
  const default_utility = {
    name : "",
    price : 4000,
    minimum_purchase : 4000,
    payment_frequency : FREQUENCY_OPTIONS[0],
    account_name : "",
    bank_name : "",
    account_number : "",
    collection_type : COLLECTION_TYPE[0]

  }
  const [utility, setUtility] = useState(default_utility)
  useEffect(() => {
    
    setUtility(edit ? edit_data : default_utility)
  }, [edit_data])

  if (!open) return null;
  const handleChange = e => {
    const {name, value} = e.target
    setUtility({...utility, [name] : value})
  }

  const handleSubmit = e => {
    e.preventDefault()
    let add_data = utility
    // if (!edit){
    //   add_data['id'] = Math.floor(Math.random() * 100)
    // }
    addUtility(add_data)
    setUtility(default_utility)
    onClose(false)
  }
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Utility</h3>
              </div>
              <input
                type="text"
                value={utility.name}
                name="name"
                placeholder="Utility Name"
                onChange={handleChange}
              />
              <input
                type="number"
                placeholder="Amount"
                value={utility.price}
                name="price"
                onChange={handleChange}
              />
              <select onChange={handleChange} name="payment_frequency" value={utility.payment_frequency} className="form-control">
                {FREQUENCY_OPTIONS.map(option => <option value={option}>{option}</option>)}
              </select>

              <input
                type="number"
                placeholder="Minimum Purchase"
                value={utility.minimum_purchase}
                name="minimum_purchase"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Account Name"
                value={utility.account_name}
                name="account_name"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Bank Name"
                value={utility.bank_name}
                name="bank_name"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Account Number"
                value={utility.account_number}
                name="account_number"
                onChange={handleChange}
              />
              <select onChange={handleChange} name="collection_type" value={utility.collection_type} className="form-control">
                {COLLECTION_TYPE.map(option => <option value={option}>{option}</option>)}
              </select>
            </div>
          </div>
          <button className="btn btn-success btn-block">{edit ? "Edit" : "Add"} Utility</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (max-width: 420px) {
    .search_set,
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .search_set {
      width: 220px;
    }
    .outlined {
      width: 180px !important;
    }
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin: 0 10px;
        width: 135px;
      }
      button {
        margin-top: 0;
        height: 45px;
        margin-left: 10px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;
      padding: 5px 20px;
      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
const UtilityGrid = styled.div`
  .team-members {
    margin-top: 40px;
  }

  .Utility_table {
    display: flex;
    flex-wrap: wrap;
    
  }
  .btn {
    display: flex;
  }
  .utils {
    margin-bottom: 10px;
    margin: 10px;
    padding: 16px;
    width: 100%;
    border: 1px solid #2d4bf3;
    border-radius: 5px;
    .name_tag {
      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      button {
        margin: 0;
        font-weight: 400;
        padding: 2px 8px;

        font-size: 14px;
        margin-top: 10px;
        background: #f2f6ff;
        border: 1px solid #2d4bf3;
        border-radius: 5px;
      }
    }
  }

  .reason {
    width: 128px;
    height: 24px;
    background: #f2f6ff;
    border: 1px solid #2d4bf3;
    border-radius: 5px;
    padding: 4px 8px;
  }
  .price {
    margin-top: 10px;
  }
  .util-acct {
    margin-top: 10px;
  }

  .util-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    margin-right: 10px;
  }
  .del-btn {
    color: #ff6969;
  }
  .edit-btn {
    color: #2d4bf3;
  }
  @media (min-width: 540px) {
    .utils {
      width: 48%;
    }
  }
  @media (min-width: 1024px) {
    .utils {
      width: 33%;
    }
  }
  @media (min-width: 1200px) {
    .utils {
      width: 24%;
    }
  }
`;
