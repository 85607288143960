import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SVGs } from "../../assets/svg/SVGs";

const PageNotFound = () => {
  return (
    <NotHere>
      <div>
        <img src={SVGs.notfound} alt="" />
        <p>404</p>
        <h1>Page Not Found</h1>
      </div>
    </NotHere>
  );
};

export default PageNotFound;

let NotHere = styled.section`
  max-width: 400px;
  margin: 100px auto;
  padding: 0 30px;
  text-align: center;
  display: block;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.06em;
    color: rgba(23, 55, 229, 1);
    margin-top: 50px;
  }

  p {
    text-align: center;
    font-family: DM Sans;
    font-size: 128px;
    font-weight: 400;
    line-height: 127px;
    letter-spacing: -0.06em;
  }
  button {
    width: 100%;
  }
  .checked {
    width: 123px;
    height: 123px;
  }
`;
