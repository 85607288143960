import { React, useState } from "react";
import styled from "styled-components";
// import IMGs from "../../assets/images/Images";

const StatusMessage = (props) => {
  const successMsg = "Awesome! The update was made.";
  const errorMsg = "Something went wrong. Try again";

  const closeMessage = () => {
    props.setMessageType("");
  };

  if (props.messageType === "success") {
    // Auto close message after 10 seconds
    setTimeout(() => {
      props.setMessageType("");
    }, 10000);

    return (
      <ControlSuccess>
        <HandlingOverlay>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="slide-in-top"
          >
            <span className="valid_btn">
              {props.message ? props.message : successMsg}
            </span>
          </div>
        </HandlingOverlay>
      </ControlSuccess>
    );
  } else if (props.messageType === "error") {
    return (
      <ControlError>
        <HandlingOverlay>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="slide-in-top"
          >
            <span className="error_btn">
              {props.message ? props.message : errorMsg}
            </span>
          </div>
        </HandlingOverlay>
      </ControlError>
    );
  } else {
    return null;
  }
};

export default StatusMessage;

const ControlSuccess = styled.div`
  margin: 0 auto;
  color: white;
  .slide-in-top {
    margin-top: 30px !important;
    span {
      padding: 15px 20px;
      border-radius: 10px;
      font-weight: 400 !important;
      background: green !important;
    }
  }
`;
const ControlError = styled.div`
  margin: 0 auto;
  color: white;
  .slide-in-top {
    margin-top: 30px !important;
    span {
      padding: 15px 20px;
      border-radius: 10px;
      font-weight: 400 !important;
      background: red !important;
    }
  }
`;
const HandlingOverlay = styled.div`
  width: 100%;
  overflow: auto;
  position: absolute;
  z-index: 100;
 max-width: 100%;
 text-align: center;
 top : 20%;
.error_btn{
  text-align: center;
}
  .slide-in-top {
    position: relative;
    margin: 0px auto;
    padding: 20px;
    
  .valid_btn {
    margin: 0px auto;
    // max-width: 440px;
    text-align: center;
    .valid {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  span {
    margin: 0;
   
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
   
    }

    // font-size: 16px;
    // font-weight: 600;
    // line-height: 22px;
    // letter-spacing: 0em;
   

    @media (max-width: 700px) {
      padding-right: 25px !important;
    }
  }
  @media (min-width: 1200px) {
    .valid_btn {
      // max-width: 530px !important;
      text-align: center;
    }

  }
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    padding: 5px;
    cursor: pointer;
    right: 50px;
    transition: 0.3s;
    &:hover {
      background: #f3f3f3;
    }
  }

  @media (max-width: 488px) {
    .close-btn {
      top: 20px;
      right: 20px;
    }
  }
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  /*  */
`;
