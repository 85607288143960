export const SVGs = {
  Email: require("../svg/emailGroup.svg").default,
  Call: require("../svg/callGroup.svg").default,
  ActivitySvg: require("../svg/activitySVG.svg").default,
  ActivityEntry: require("../svg/Entry.svg").default,
  brand_name: require("../svg/brand_name.svg").default,
  brand_logo: require("../svg/brand_logo.svg").default,
  delete: require("../svg/delete.svg").default,
  inWhite: require("../svg/logoinwhite.svg").default,
  delete: require("../svg/delete.svg").default,
  notfound: require("../svg/notfound.svg").default,
  email: require("../svg/location.svg").default,
  GooglePlay: require("../svg/AppStore.svg").default,
  AppStore: require("../svg/GooglePlay.svg").default,
  Fire: require("../svg/FireFighters.svg").default,
  filter: require("../svg/filter.svg").default,
  visitors: require("../svg/visitors.svg").default,
  completed: require("../svg/Completed.svg").default,
  FrameImg: require("../svg/img_frame.svg").default,
  TransactionResidentsSvg: require("../svg/transactionResidentsSvg.svg")
    .default,
  close: require("../svg/close.svg").default,
  users: require("../svg/users-2.svg").default,
  security: require("../svg/security-guard.svg").default,
  access_granted: require("../svg/check-mark.svg").default,
  access_denied: require("../svg/access-denied.svg").default,
  caution: require("../svg/caution.svg").default,
  location: require("../svg/location.svg").default,
  overview_svg: require("../svg/estates_svg.svg").default,
  phone: require("../svg/phone.svg").default,
  notification: require("../svg/notification.svg").default,
  facebook: require("../svg/facebook.svg").default,
  Resident: require("../svg/residents.svg").default,
  vendor: require("../svg/vendor.svg").default,
  complains: require("../svg/complaints_b.svg").default,
  deactivate_user_white : require("../svg/deactivate-user-white.svg").default,
  user_white : require("../svg/user-white.svg").default,
  youtube: require("../svg/youtube.svg").default,
  linkedin: require("../svg/linkedin.svg").default,
  twitter: require("../svg/twitter.svg").default,
  instagram: require("../svg/instagram.svg").default,
  css: require("../svg/css.svg").default,
  django: require("../svg/django.svg").default,
  html: require("../svg/html.svg").default,
  js: require("../svg/js.svg").default,
  w_facebook: require("../svg/w_facebook.svg").default,
  w_twitter: require("../svg/w_twitter.svg").default,
  w_linkedin: require("../svg/w_linkedin.svg").default,
  w_whatsapp: require("../svg/w_whatsapp.svg").default,
  w_ig: require("../svg/w_ig.svg").default,
  php: require("../svg/php.svg").default,
  python: require("../svg/python.svg").default,
  react: require("../svg/react.svg").default,
  accessable: require("../svg/accessable.svg").default,
  affordable: require("../svg/affordable.svg").default,
  community: require("../svg/community.svg").default,
  support: require("../svg/support.svg").default,
  group: require("../svg/group.svg").default,
  clock: require("../svg/clock.svg").default,
  brief: require("../svg/brief.svg").default,
  creation: require("../svg/creation.svg").default,
  handoff: require("../svg/handoff.svg").default,
  reviews: require("../svg/reviews.svg").default,
  bulb: require("../svg/bulb.svg").default,
  call: require("../svg/call.svg").default,
  sterling: require("../svg/sterling.svg").default,
  critical_thinking: require("../svg/critical_thinking.svg").default,
  problem_solving: require("../svg/problem_solving.svg").default,
  star_gif: require("../svg/stargif.svg").default,
  mail: require("../svg/mail.svg").default,
  dollar: require("../svg/dollar.svg").default,
  growth: require("../svg/growth_chart.svg").default,
  plane: require("../svg/plane.svg").default,
  workstation: require("../svg/workstation.svg").default,
  team: require("../svg/team.svg").default,
  freePlan: require("../svg/free.svg").default,
  essentialPlan: require("../svg/essential.svg").default,
  standardPlan: require("../svg/standard.svg").default,
  premiumPlan: require("../svg/premium.svg").default,
};
