import React, { useState } from "react";

import styled from "styled-components";
import Swal from "sweetalert2";
import { SVGs } from "../assets/svg/SVGs";

const DialEmergency = () => {
  return (
    <section className="emergencySection">
      <div className="all_emerge">
        <div className="perEmergency">
          <a href="tel:6031112298">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.9998 30.8346C30.6629 30.8346 31.2988 30.5712 31.7676 30.1024C32.2364 29.6336 32.4998 28.9977 32.4998 28.3346C32.4998 27.6716 32.2364 27.0357 31.7676 26.5669C31.2988 26.098 30.6629 25.8346 29.9998 25.8346C29.3368 25.8346 28.7009 26.098 28.2321 26.5669C27.7632 27.0357 27.4998 27.6716 27.4998 28.3346C27.4998 28.9977 27.7632 29.6336 28.2321 30.1024C28.7009 30.5712 29.3368 30.8346 29.9998 30.8346ZM32.4998 15.8346H28.3332V20.0013H35.7665L32.4998 15.8346ZM9.99984 30.8346C10.6629 30.8346 11.2988 30.5712 11.7676 30.1024C12.2364 29.6336 12.4998 28.9977 12.4998 28.3346C12.4998 27.6716 12.2364 27.0357 11.7676 26.5669C11.2988 26.098 10.6629 25.8346 9.99984 25.8346C9.3368 25.8346 8.70091 26.098 8.23207 26.5669C7.76323 27.0357 7.49984 27.6716 7.49984 28.3346C7.49984 28.9977 7.76323 29.6336 8.23207 30.1024C8.70091 30.5712 9.3368 30.8346 9.99984 30.8346ZM33.3332 13.3346L38.3332 20.0013V28.3346H34.9998C34.9998 29.6607 34.4731 30.9325 33.5354 31.8702C32.5977 32.8079 31.3259 33.3346 29.9998 33.3346C28.6738 33.3346 27.402 32.8079 26.4643 31.8702C25.5266 30.9325 24.9998 29.6607 24.9998 28.3346H14.9998C14.9998 29.6607 14.4731 30.9325 13.5354 31.8702C12.5977 32.8079 11.3259 33.3346 9.99984 33.3346C8.67376 33.3346 7.40199 32.8079 6.4643 31.8702C5.52662 30.9325 4.99984 29.6607 4.99984 28.3346H1.6665V10.0013C1.6665 8.1513 3.14984 6.66797 4.99984 6.66797H28.3332V13.3346H33.3332ZM13.3332 10.0013V15.0013H8.33317V18.3346H13.3332V23.3346H16.6665V18.3346H21.6665V15.0013H16.6665V10.0013H13.3332Z"
                fill="black"
              />
            </svg>

            <h5>Ambulance</h5>
          </a>
        </div>
        <div className="perEmergency">
          <a href="tel:6031112293">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6665 35C10.2776 35 9.09706 34.5139 8.12484 33.5417C7.15262 32.5694 6.6665 31.3889 6.6665 30H1.6665V18.3333H19.9998V8.33333H26.6665V5H31.6665V8.33333H34.9998L38.3332 18.3333V30H33.3332C33.3332 31.3889 32.8471 32.5694 31.8748 33.5417C30.9026 34.5139 29.7221 35 28.3332 35C26.9443 35 25.7637 34.5139 24.7915 33.5417C23.8193 32.5694 23.3332 31.3889 23.3332 30H16.6665C16.6665 31.3889 16.1804 32.5694 15.2082 33.5417C14.2359 34.5139 13.0554 35 11.6665 35ZM11.6665 31.6667C12.1387 31.6667 12.5348 31.5067 12.8548 31.1867C13.1737 30.8678 13.3332 30.4722 13.3332 30C13.3332 29.5278 13.1737 29.1322 12.8548 28.8133C12.5348 28.4933 12.1387 28.3333 11.6665 28.3333C11.1943 28.3333 10.7982 28.4933 10.4782 28.8133C10.1593 29.1322 9.99984 29.5278 9.99984 30C9.99984 30.4722 10.1593 30.8678 10.4782 31.1867C10.7982 31.5067 11.1943 31.6667 11.6665 31.6667ZM28.3332 31.6667C28.8054 31.6667 29.2009 31.5067 29.5198 31.1867C29.8398 30.8678 29.9998 30.4722 29.9998 30C29.9998 29.5278 29.8398 29.1322 29.5198 28.8133C29.2009 28.4933 28.8054 28.3333 28.3332 28.3333C27.861 28.3333 27.4654 28.4933 27.1465 28.8133C26.8265 29.1322 26.6665 29.5278 26.6665 30C26.6665 30.4722 26.8265 30.8678 27.1465 31.1867C27.4654 31.5067 27.861 31.6667 28.3332 31.6667ZM23.3332 18.3333H34.8332L32.5832 11.6667H23.3332V18.3333ZM1.6665 16.6667V14.1667H3.33317V10.8333H1.6665V8.33333H18.3332V10.8333H16.6665V14.1667H18.3332V16.6667H1.6665ZM5.83317 14.1667H8.74984V10.8333H5.83317V14.1667ZM11.2498 14.1667H14.1665V10.8333H11.2498V14.1667ZM19.9998 21.6667V26.6667H15.3748C14.9026 26.1389 14.3471 25.7289 13.7082 25.4367C13.0693 25.1456 12.3887 25 11.6665 25C10.9443 25 10.2637 25.1456 9.62484 25.4367C8.98595 25.7289 8.43039 26.1389 7.95817 26.6667H4.99984V21.6667H19.9998ZM23.3332 21.6667H34.9998V26.6667H32.0415C31.5693 26.1389 31.0137 25.7289 30.3748 25.4367C29.736 25.1456 29.0554 25 28.3332 25C27.611 25 26.9304 25.1456 26.2915 25.4367C25.6526 25.7289 25.0971 26.1389 24.6248 26.6667H23.3332V21.6667Z"
                fill="#2D4BF3"
              />
            </svg>

            <h5>Fire Fighters</h5>
          </a>
        </div>
        <div className="perEmergency">
          <a href="tel:6031112292">
            <svg
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8333 0V5H22.1667V0H18.8333ZM13.6333 2.43333L11.2667 4.78333L14.8167 8.33333L17.1667 5.96667L13.6333 2.43333ZM27.3667 2.43333L23.8333 5.96667L26.1833 8.33333L29.7333 4.8L27.3667 2.43333M20.5 8.33333C19.6159 8.33333 18.7681 8.68452 18.143 9.30964C17.5179 9.93476 17.1667 10.7826 17.1667 11.6667V13.3333H11.3333C10.2333 13.3333 9.3 14.0333 8.96667 15L5.5 25V38.3333C5.5 38.7754 5.67559 39.1993 5.98816 39.5118C6.30072 39.8244 6.72464 40 7.16667 40H8.83333C9.27536 40 9.69928 39.8244 10.0118 39.5118C10.3244 39.1993 10.5 38.7754 10.5 38.3333V36.6667H30.5V38.3333C30.5 38.7754 30.6756 39.1993 30.9882 39.5118C31.3007 39.8244 31.7246 40 32.1667 40H33.8333C34.2754 40 34.6993 39.8244 35.0118 39.5118C35.3244 39.1993 35.5 38.7754 35.5 38.3333V25L32.0333 15C31.7 14.0333 30.7667 13.3333 29.6667 13.3333H23.8333V11.6667C23.8333 10.7826 23.4821 9.93476 22.857 9.30964C22.2319 8.68452 21.3841 8.33333 20.5 8.33333M11.3333 15.8333H29.6667L32.1667 23.3333H8.83333L11.3333 15.8333ZM11.3333 26.6667C11.9964 26.6667 12.6323 26.9301 13.1011 27.3989C13.5699 27.8677 13.8333 28.5036 13.8333 29.1667C13.8333 29.8297 13.5699 30.4656 13.1011 30.9344C12.6323 31.4033 11.9964 31.6667 11.3333 31.6667C10.6703 31.6667 10.0344 31.4033 9.56557 30.9344C9.09673 30.4656 8.83333 29.8297 8.83333 29.1667C8.83333 28.5036 9.09673 27.8677 9.56557 27.3989C10.0344 26.9301 10.6703 26.6667 11.3333 26.6667M29.6667 26.6667C30.3297 26.6667 30.9656 26.9301 31.4344 27.3989C31.9033 27.8677 32.1667 28.5036 32.1667 29.1667C32.1667 29.8297 31.9033 30.4656 31.4344 30.9344C30.9656 31.4033 30.3297 31.6667 29.6667 31.6667C29.0036 31.6667 28.3677 31.4033 27.8989 30.9344C27.4301 30.4656 27.1667 29.8297 27.1667 29.1667C27.1667 28.5036 27.4301 27.8677 27.8989 27.3989C28.3677 26.9301 29.0036 26.6667 29.6667 26.6667V26.6667Z"
                fill="#111111"
              />
            </svg>

            <h5>Burglary</h5>
          </a>
        </div>
      </div>
    </section>
  );
};

const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <ModalWrapper onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <DialEmergency />
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </ModalWrapper>
  );
};
export default Modal;

let ModalWrapper = styled.div`
  .modalContainer {
    max-width: 600px;
    margin-top: 120px;
    .new_estates_form {
      max-width: 600px;
    }
  }
  .emergencySection {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;

    .all_emerge {
      max-width: 450px;
      margin: 50px auto;
      margin-top: 70px;
      @media (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .perEmergency {
          width: 100%;
          max-width: 200px;
        }
      }
      .perEmergency {
        text-align: center;
        margin-top: 30px;
        border: 1.5px solid #545454;
        border-radius: 5px;
        padding: 20px;
        transition: 0.4s;
        transform: scale(1);

        @media (max-width: 540px) {
          max-width: 200px;
          margin: 20px auto;
        }
        &:hover {
          border: 1.5px solid red;
          transform: scale(0.98);
          background: rgba(255, 0, 0, 0.1);
          h5 {
            color: red;
          }
          svg {
            path {
              fill: red;
            }
          }
        }
        h5 {
          margin: 0;
        }
        svg {
          path {
            fill: black;
          }
        }
      }
    }
  }
`;
