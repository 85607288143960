import { Link } from "react-router-dom";
import styled from "styled-components";
import { AccessConfirmation } from "./partials/utils";
import { useState } from "react";

const LatestAccess = ({access_logs, modifyAccess}) => {

  const [open, setOpen] = useState(false)
  const [currentAccess, setCurrentAccess] = useState(null)

  const handleModify = (access, access_code) => {
    modifyAccess(access, access_code)
  }

  const getAccessModifier = (access) =>{
    return access === "GRANT" ? "REVOKE" : "GRANT"
  }

  const handleOpen = access =>{
    setCurrentAccess(access)
    setOpen(true)
  }

  return (
    <Container className="latestAccess">
      <div className="header">
        <h3>Quick Access List</h3>
        {/* <Link to="/">See More </Link> */}
      </div>
      <div className="each_tempo">
        {access_logs && access_logs.length ? access_logs.map(log => (
          <div className="listOfAccess">
          <div className="name_mobile" key={log.id}>
            <h4>
              {log.first_name} {log.last_name}
            </h4>
            <p>{log.mobile}</p>
          </div>
          <p onClick={() => handleOpen(log)}>
            View Details
          </p>
        </div>
        )) : 'No Access Given Out Yet'}
      </div>
      <AccessConfirmation open={open} onClose={() => setOpen(false) } access={currentAccess}/>
    </Container>
  );
};

export default LatestAccess;

let Container = styled.section`
  background: #ffffff;
  padding: 15px;
  border: 2px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 50px;
  a,p {
      font-weight: 400;
      font-size: 16px;
      padding: 0;
      color: #2d4bf3;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  .listOfAccess {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 0 !important;
    align-items: center;
    .select_me {
      width: 150px;
      margin-top: 15px;
      .select-btn {
        height: 37px;
        padding: 0 15px !important;
        padding-right: 0 !important;
        span {
          left: 15px;
        }
      }
    }
  }
  .each_tempo {
    .name_mobile {
      h4 {
        /* font-weight: 600; */
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    h3{
      font-weight: bolder;
    }
    
  }
  @media screen and (max-width : 420px){
    margin: 20px 0px;
  }
`;

