import {useEffect, useState} from "react";
import GNavbar from "../../components/Navbar/ResidentNavigator";
import Mobile from "../../components/Navbar/ResidentMobile";
import TopNav from "../../components/Navbar/ResidentNav";
import QuickAccess from "../../components/QuickAccess";
import ResidentCards from "../../components/ResidentCard";
import Adverts from "../../components/CreateAds";
import LatestAccess from "../../components/LatestAccess";
import styled from "styled-components";
import { HOST_URL, getCookie, reqOptions, loginRequired } from "../../assets/js/helpFunction";
import { useNavigate } from "react-router-dom";
import { getEstateID } from "../../components/partials/utils";
// import LatestResidentsAndChart from "../../components/AdminChart";

export const WithResidentAuth = ({children}) => {
  const [user, setUser]  = useState({})
  const navigate = useNavigate();

  if(getCookie("access") === null){
    loginRequired(401)  
  }  

  useEffect(() => {
    if(!localStorage.getItem("estate_id")) navigate('/my-estates') 
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(data.code === "user_not_found") loginRequired(401) 
        setUser(data)
      })
      .catch((err) => console.log(err));
  }, [])
  return (
      <section className="change_ratio">
    <GNavbar/>
    <Mobile user={user}/>
    <section className="selected_tab">
      <TopNav user={user}/>
      <div className="dashboard_container">
          {children}
      </div>
    </section>
  </section>
  )
}


const Overview = () => {
  const [data, setData] =  useState({
    estate : {},
    users : [], complaints : [], logs : []
  })
  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const user_api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`
    const residents_api = `${HOST_URL()}estate_users/resident_list/?estate_id=${getEstateID()}`;
    const complaints_api = `${HOST_URL()}estate_complaints/?estate_id=${getEstateID()}`;
    const access_logs_api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    Promise.all([
      fetch(residents_api, requestOptions),
      fetch(complaints_api, requestOptions),
      fetch(access_logs_api, requestOptions),
      fetch(user_api, requestOptions),
    ]).then(function (responses) {
      return Promise.all(responses.map(function (response) {
        return response.json();
      }));
    }).then(function (data) {
      console.log(data)
      setData(
        {
          ...data, users : data[0].results, complaints : data[1].results, logs : data[2].results, user : data[3]
        }
      );
    }).catch(function (error) {
      console.log(error);
    });
  }, [])

  const modifyAccess = (access, access_code) => {
    const requestOptions = reqOptions("post", JSON.stringify({access, access_code}), true);
    const api = `${HOST_URL()}estate_access_logs/modify/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then(response => {
        if(response.message){
          // setMssg({...mssg, mssg : "Access Modified successfully"})
          const edited_data =  data.logs.map(item => {
            if(item.access_code == access_code){
              return {...item, access }
            }
            return item
          })
          setData({...data, logs : edited_data})
        }else{
          // setMssg({...mssg, mssg : "Error Modifying Access Log", error : true})
        }
      })
      .catch((err) => {});
  }

  return (
    <WithResidentAuth>
      <QuickAccess datas={data} />
      <ResidentCards data={data}/>
      <AdvertContainer>
        <Adverts />
        <LatestAccess access_logs={data.logs.slice(0, 4)} modifyAccess={modifyAccess}/>
      </AdvertContainer>
    </WithResidentAuth>
  );
};

export default Overview;

let AdvertContainer = styled.section`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    .handleCarouselBanner {
      width: 63%;
    }
    .latestAccess {
      width: 35%;
    }
  }
`;
