import {useEffect, useState} from "react";
import GNavbar from "../../components/Navbar/ExternalNavigator.jsx";
import Mobile from "../../components/Navbar/ExternalNavbar";
import TopNav from "../../components/Navbar/ExternalNotify";
import ExternalCards from "../../components/ExternalCards";
import styled from "styled-components";
import VerifyButton from "../../components/VerifyButton";
import RecentActivity from "../../components/RecentActivity.jsx";
import { getEstateID } from "../../components/partials/utils.jsx";
import { HOST_URL, reqOptions, getCookie, loginRequired } from "../../assets/js/helpFunction.js";
import { useLocation, useNavigate } from "react-router-dom";
// import LatestResidentsAndChart from "../../components/AdminChart";

export const ExternalAuth = ({children}) => {
  const [user, setUser]  = useState({})
  const navigate = useNavigate();

  if(getCookie("access") === null){
    loginRequired(401)  
  }  

  useEffect(() => {
    if(!localStorage.getItem("estate_id")) navigate('/my-estates') 
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(data.code === "user_not_found") loginRequired(401)   
        setUser(data)
      })
      .catch((err) => console.log(err));
  }, [])
  return (
    <section className="change_ratio">
      <GNavbar />
      <Mobile user={user}/>
      <section className="selected_tab">
        <TopNav user={user}/>

        <div className="dashboard_container">
          {children}
        </div>
      </section>
    </section>
  )
}

const Overview = () => {

  const {  state } = useLocation()
  const [logs, setLogs] = useState([])
  const [user, setUser] = useState(null)
  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_access_logs/?estate_id=${getEstateID()}`;
    const user_api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`
    Promise.all([
        fetch(api, requestOptions),
        fetch(user_api, requestOptions)
    ]).then(function (responses) {
        return Promise.all(responses.map(function (response) {
          return response.json();
    }));
    }).then(function (resp) {
        setUser(resp[1])
        setLogs(resp[0].results);

    }).catch(function (error) {
        console.log(error);
    });
  }, [])


  const handleLog = (newlog, access) => {
    console.log(newlog, access)
    const newlogs = logs.map(log => {
      if (log.id === newlog.id) {
        return {...log, access: access};
      }
      return log;
    });
    setLogs(newlogs);
  }
  return (
    <ExternalAuth>
      <VerifyButton user={user} handleLog={handleLog}/>
      <ExternalCards logs={logs}/>
      <RecentActivity logs={logs}/>
    </ExternalAuth>

  );
};

export default Overview;

let AdvertContainer = styled.section`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    .handleCarouselBanner {
      width: 63%;
    }
    .latestAccess {
      width: 35%;
    }
  }
`;
