import React, { useState, useEffect } from "react";
import GNavbar from "../../components/Navbar/S-Navigator";
import Mobile from "../../components/Navbar/Navbar";
import TopNav from "../../components/Navbar/SuperAdminNav";
import Overview_card from "../../components/Overview_card";
import D_Filter from "../../components/SuperAdminDownload";
import LatestEstate from "../../components/LatestEstate";
import { useNavigate } from "react-router-dom";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import Marketplace from "../../components/Marketplace/SuperAdminOnboarding";
import HomeService from "../../components/HomeService/SuperAdminOnboarding";

export const WithSuperAuth = ({ children }) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  // if(getCookie("access") === null){
  //     loginRequired(401)
  // }

  useEffect(() => {
    // let requestOptions = reqOptions("get", null, true);
    // const api = `${HOST_URL()}estate_users/estate_user_detail/?estate_id=${getEstateID()}`;
    // fetch(api, requestOptions)
    // .then((res) => res.json())
    // .then((data) => {
    //     if(data.code === "user_not_found"){
    //         loginRequired(401)
    //     }else if(data.estate_user.user_type !== "ADMIN"){
    //         navigate("/my-estates")
    //     }else{
    //         setUser(data)
    //     }
    // })
    // .catch((err) => console.log(err));
  }, []);

  return (
    <section className="change_ratio">
      <GNavbar overview="active_tab" />
      <Mobile />
      <section className="selected_tab">
        <TopNav />
        <div className="dashboard_container">{children}</div>
      </section>
    </section>
  );
};

const Overview = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    const estates_api = `${HOST_URL()}estates/super_admin/`;

    Promise.all([fetch(estates_api, requestOptions)])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (resp) {
        setData({ ...data, estates: resp[0] });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <WithSuperAuth>
      <D_Filter />
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}>
        <Marketplace />
        <HomeService />
      </div>
      <Overview_card data={data} />
    </WithSuperAuth>
  );
};

export default Overview;
