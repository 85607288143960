import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import WithAuth from "../../components/partials/withAuth";
import {
  HOST_URL,
  reqOptions,
} from "../../assets/js/helpFunction";
import { SVGs } from "../../assets/svg/SVGs";
import { MssgContainer, getDate, getEstateID, getImage, titleCase } from "../../components/partials/utils";


const AdminComplaint = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [editedComplaint, setEditedComplaint] = useState({})
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0); // state representing the page we are on
  const [activeIndex, setActiveIndex] = useState(1);
  const [mssg, setMssg] = useState({ error: false, mssg: "" })
  const checkActive = (index) => (activeIndex === index ? "active" : "");

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  useEffect(() => {
    const requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_complaints/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setComplaints(data.results)
        setData(data);
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  }, []);

  const filterComplaints = (index, name) => {
    setActiveIndex(index);
    document
      .querySelectorAll("tab")
      .forEach((item) =>
        item.classList.includes(activeIndex)
          ? item.classList.add("active")
          : item.classList.remove("active")
      );
    if (name != "ALL") {
      setComplaints(data.results.filter((item) => item.status == name));
    } else {
      setComplaints(data.results);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    const requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_complaints/?estate_id=${getEstateID()}&search=${value}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setComplaints(data.results)
        setData(data);
      })
      .catch((err) => setMssg({ ...mssg, mssg: err.message, error: true }));
  };

  const modifyComplaint = (e, id) => {
    const { value, style } = e.target
    style.border = `0.1px solid ${value === "RESOLVED" ? "lightgreen" : "lightgrey"}`
    const api = `${HOST_URL()}estate_complaints/${id}/?estate_id=${getEstateID()}`
    const requestOptions = reqOptions('put', JSON.stringify({ status: value }), true)
    fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setMssg({ ...mssg, mssg: "Complaint Modified successfully" })
        const edited_data = complaints.map(item => {
          if (item.id == data.id) {
            return data
          }
          return item
        })
        setComplaints(edited_data)
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  };

  const addComplaint = complaint => {
    if (edit) {
      const api = `${HOST_URL()}estate_complaints/${complaint.id}/?estate_id=${getEstateID()}`
      const requestOptions = reqOptions('put', JSON.stringify(complaint), true)
      fetch(api, requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data && data.estate) {
            setMssg({ ...mssg, mssg: "Complaint Modified successfully" })
            const edited_data = complaints.map(item => {
              if (item.id == editedComplaint.id) {
                return complaint
              }
              return item
            })
            setComplaints(edited_data)
          } else {
            setMssg({ ...mssg, mssg: "Error Posting Complaint", error: true })
          }

        })
        .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))

    } else {
      const api = `${HOST_URL()}estate_complaints/?estate_id=${getEstateID()}`
      const requestOptions = reqOptions('post', JSON.stringify(complaint), true)
      fetch(api, requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data && data.estate) {
            setMssg({ ...mssg, mssg: "Complaint Posted successfully" })
            setComplaints([...complaints, data])
          } else {
            setMssg({ ...mssg, mssg: "Error Posting Complaint", error: true })
          }

        })
        .catch(err => {
          setMssg({ ...mssg, mssg: err.message, error: true })
        })

    }
    setEditedComplaint({})
    setEdit(false)
  }

  const eventsPerPage = 9;
  const pagesVisited = pageNumber * eventsPerPage;

  const pageCount = Math.ceil(complaints && complaints.length / eventsPerPage); // Rounding up

  const changePage = ({ selected }) => {
    // selected the number for the page we want to move too
    setPageNumber(selected);
  };

  const handleCloseModal = () => {
    setOpenModal(false)
    setEditedComplaint({})
    setEdit(false)
  }
  return (
    <WithAuth>
      <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
        <HandleSearchAndTab>
          <div className="tabs">
            <span
              className={`tab 1 ${checkActive(1)}`}
              onClick={() => filterComplaints(1, "ALL")}
            >
              <button>All</button>
            </span>

            <span
              className={`tab 2 ${checkActive(2)}`}
              onClick={() => filterComplaints(2, "PENDING")}
            >
              <button>Pending</button>
            </span>
            <span
              className={`tab 3 ${checkActive(3)}`}
              onClick={() => filterComplaints(3, "RESOLVED")}
            >
              <button>Resolved</button>
            </span>
          </div>

          <div className="event-input">
            <div class="search_set">
              <img src="https://www.svgrepo.com/show/13682/search.svg" alt="" />
              <input
                className="eventt"
                type="text"
                name="name"
                placeholder="Search"
                onChange={handleSearch}
                value={search}
              />
            </div>
            {/* <button
              className="important-btn"
              onClick={() => setOpenModal(true)}
            >
              Report an Issue
            </button> */}
          </div>
        </HandleSearchAndTab>
      </div>
      <div className="panels">
        <Wrapper>
          {complaints && complaints.length ? (
            complaints.map((complaint) => (
              <Complaint className="perComponent" key={complaint.id} style={{ maxWidth: 400 }}>
                <div className="name_position">
                  <img
                    src={complaint.estate_user && getImage(complaint.estate_user.profile_image)}
                    alt={complaint.estate_user?.user?.first_name}
                  />
                  <h3>
                    {complaint.estate_user?.user?.first_name} {complaint.estate_user?.user?.last_name}  <span>{complaint.estate_user?.user_type}</span>
                  </h3>
                </div>
                <div className="">
                  <p>{complaint.message}</p>
                </div>

                <span>{getDate(complaint.timestamp)}</span>

                <div className="top_level">
                  <div>
                    {/* <h2>Golden Gate Estate</h2>
                    <p className="estateid">{getEstateID()}</p> */}
                  </div>
                  <select onChange={e => modifyComplaint(e, complaint.id)} value={complaint.status} className="form-control"
                    style={{
                      width: '8rem', height: '3rem',
                      border: `1px solid ${complaint.status === "RESOLVED" ? "lightgreen" : "lightgrey"}`
                    }}>
                    <option value="PENDING">PENDING</option>
                    <option value="RESOLVED">RESOLVED</option>
                  </select>

                </div>
              </Complaint>
            ))
          ) : (
            <p>You have no complaints Yet!
              <a
                onClick={() => setOpenModal(true)}
                style={{ cursor: 'pointer', color: '#2D4BF3', fontWeight: 'bolder' }}
              > Add Complaint
              </a>
            </p>
          )}
        </Wrapper>
        <Modal open={openModal} onClose={handleCloseModal} addComplaint={addComplaint} edit_data={editedComplaint} edit={edit} />
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationButtons"}
        previousLinkClassName={"previousButton"}
        nextLinkClassName={"nextButton"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </WithAuth>
  );
};
export default AdminComplaint;

const Modal = ({ open, onClose, addComplaint, edit_data, edit }) => {
  // const data = edit_data
  const RECEIVERS_OPTIONS = ['CHIEF_SECURITY_OFFICER', 'ESTATE_MANAGER', 'EXCOS', 'FINANCIAL_SECRETARY']
  const REASONS_OPTIONS = ["Security", 'Infrastructure', "Environment", "Electricity", "Drainage", "Sewage", "Waste", "Pollution", "Payments"]
  const default_comp = {
    title: "",
    message: "",
    receivers: "",
    reason: ""

  }
  const [complaint, setComplaint] = useState({})
  useEffect(() => {
    setComplaint(edit ? edit_data : default_comp)
  }, [edit_data])

  if (!open) return null;
  const handleChange = e => {
    const { name, value } = e.target
    setComplaint({ ...complaint, [name]: value })

  }

  const handleSubmit = e => {
    e.preventDefault()
    addComplaint(complaint)
    setComplaint(default_comp)
    onClose()
  }

  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Report an Issue</h3>
              </div>
              <label htmlFor="">Issue Category</label>
              <select onChange={handleChange} name="reason" value={complaint.reason} className="form-control" style={{ margin: "10px 0" }}>
                <option value="">Select</option>
                {REASONS_OPTIONS.map(option => <option value={option}>{option}</option>)}
              </select>
              <textarea className="form-control" value={complaint.message} name="message" id="" cols="10" rows="2" placeholder="Message" onChange={handleChange}>
              </textarea>

              <select onChange={handleChange} name="receivers" value={complaint.receivers} className="form-control">
                {RECEIVERS_OPTIONS.map(option => <option value={option}>{titleCase(option.replaceAll("_", " "))}</option>)}
              </select>
            </div>
          </div>
          <button className="btn btn-success btn-block">Submit</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};




const HandleSearchAndTab = styled.section`
  @media screen and (max-width: 420px){
      .important-btn{
        width: 100%;
        margin: 20px 0;
      }
    }
  .important-btn{
    margin: 0 10px;
  }
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;

let Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;


  

  @media (max-width: 400px) {
    .eachAdminBlog {
      width: 100%;
    }
  }
  @media (min-width: 720px) {
    .perComponent {
      width: 48.5%;
    }
  }
  @media (min-width: 1200px) {
    .perComponent {
      width: 32.5%;
    }
  }
 
  }
`;

const Complaint = styled.div`
  background: #f6f6f6;
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  .handleFlow {
    height: 150px;
    overflow: auto;
    margin: 20px 0;
  }
  .top_level {
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: 700;

      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    .select_me {
      max-width: 120px;
      width: 100%;
      .select_content {
        top: 40px;
      }
      .select-btn {
        height: 35px;
        cursor: pointer;
        img {
          width: 15px;
          right: 10px;
        }
        span {
          left: 10px;
        }
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #999999;
  }
  .name_position {
    margin-top: 15px;
    display: flex;

    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      object-fit: cover;
      border-radius: 50%;
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      margin-top: 7px;
      span {
        font-weight: 700;
        color: #2d4bf3;
        font-size: 14px;
        margin-left: 10px;
        line-height: 13px;
        letter-spacing: -0.06em;
      }
    }
  }
  @media screen and (max-width : 420px){
    width: 100%;
  }
`;
