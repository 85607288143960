import React, { useEffect, useState } from "react";
import WithAuth from "../../components/partials/withAuth";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { MssgContainer, PaginationCompUI, getEstateID } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";
import { useParams } from "react-router-dom";

function AdminStreets() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [streets, setStreets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [mssg, setMssg] = useState({ error: false, mssg: "" })
  const [editData, setEditData] = useState("")

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estates/estates_street_no_zone/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setStreets(data.results);
        setData(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false)
        setMssg({ ...mssg, mssg: err.message, error: true })
      });
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditModal(false);
    setEditData("")
  };

  const handleCreateStreet = (street) => {
    let requestOptions = reqOptions("post", JSON.stringify({ names: street }), true);
    const api = `${HOST_URL()}estates/estates_street_no_zone/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log('data snssb', data)
        if (data?.message) {
          setMssg({ ...mssg, mssg: data.message, error: false })
          setStreets([...streets, { name: street, id: street.length + 1 }]);
        } else {
          setMssg({ ...mssg, mssg: JSON.stringify(data), error: true })
        }
      })
      .catch((err) => {
        setMssg({ ...mssg, mssg: err.message, error: true })
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditStreet = street => {

  }
  return (
    <WithAuth>
      <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
        {isLoading ? (
          <p>Loading...</p>
        ) : streets && streets.length ? (
          <>
            <h2>Streets</h2>
            <div style={{ textAlign: 'right' }}>
              <button style={{ margin: '0 5px', background: '#2D4BF3', color: 'white' }}
                onClick={() => setOpenModal(true)}
              >Add Street</button>
            </div>
            <table>
              <thead>
                <tr className="transaction-heading">
                  <th className="checkmarking">S/N</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {streets.map((street, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{street.name}</td>
                    <td>
                      <p data-name={street.name} style={{ cursor: "pointer" }} onClick={e => {
                        console.log(e.target.dataset.name)
                        setEditData(e.target.dataset.name)
                        setEditModal(true)
                      }}>Edit</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <PaginationCompUI data={data} />
          </>
        ) : (
          <p>
            No street found! <a
              onClick={() => setOpenModal(true)}
              style={{
                cursor: "pointer",
                color: "#2D4BF3",
                fontWeight: "bolder",
              }}
            >
              Add street
            </a>
          </p>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        createStreet={handleCreateStreet}
      />
      <EditModal
        open={editModal}
        onClose={handleCloseModal}
        editStreet={handleEditStreet}
        data={editData}
      />
    </WithAuth>
  );
}

export default AdminStreets;

const Modal = ({ open, onClose, createStreet }) => {
  // const data = edit_data
  const [street, setStreet] = useState("");

  if (!open) return null;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStreet(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createStreet(street);
    onClose(false);
    setStreet("")
  };
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Add Street</h3>
              </div>
              <input
                type="text"
                value={street}
                name="zone"
                placeholder=""
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="btn btn-success btn-block">Add Street</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};


const EditModal = ({ open, onClose, editStreet, data }) => {
  // const data = edit_data
  const [street, setStreet] = useState("");

  if (!open) return null;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStreet(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editStreet(street);
    onClose(false);
    setStreet("")
  };


  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Edit Street</h3>
              </div>
              <input
                type="text"
                value={street}
                name="zone"
                placeholder=""
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="btn btn-success btn-block">Edit Street</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};