import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminProfile from "../../components/AdminProfileTest";
import AdminEmail from "../../components/AdminEmail";
import AdminPassword from "../../components/AdminPassword";
import AdminNotice from "../../components/AdminNotification";
import {
  getCookie,
  SK_VALUE,
  HOST_URL,
  reqOptions,
} from "../../assets/js/helpFunction";
import WithAuth from "../../components/partials/withAuth";
import { MssgContainer, getEstateID, titleCase } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";


const AdminSettings = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";
  const [userdata, setUserData] = useState({});
  const [plans, setPlans] = useState([]);
  const [mssg, setMssg] = useState({ error: false, mssg: "" });
  const closeMssg = () => {
    setMssg({ error: false, mssg: "" });
  };

  const [openModal, setOpenModal] = useState(false);
  const [activePackage, setActivePackage] = useState(null)
  useEffect(() => {
    const req = reqOptions("GET", null, "access");
    const user_api =
      HOST_URL() +
      `estate_users/estate_user_detail/?estate_id=${getEstateID()}`;
    const plan_api = HOST_URL() + `estate_plans/`;
    Promise.all([fetch(user_api, req), fetch(plan_api, req)])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (resp) {
        console.log(resp)
        setUserData(resp[0]);
        setPlans(resp[1].results);
      })
      .catch(function (error) {
        setMssg({ ...mssg, mssg: error.message, error: true });
      });
  }, [getEstateID()]);
  return (
    <WithAuth>
      <div className="event-container">
        {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
        <HandleSearchAndTab>
          <div className="tabs">
            <span
              className={`tab ${checkActive(1, "active")}`}
              onClick={() => handleClick(1)}
            >
              <button>Profile</button>
            </span>

            {/* <span
              className={`tab ${checkActive(2, "active")}`}
              onClick={() => handleClick(2)}
            >
              <button>Email</button>
            </span> */}
            <span
              className={`tab ${checkActive(3, "active")}`}
              onClick={() => handleClick(3)}
            >
              <button>Password</button>
            </span>
            {/* <span
              className={`tab ${checkActive(4, "active")}`}
              onClick={() => handleClick(4)}
            >
              <button>Notification</button>
            </span>
            <span
              className={`tab ${checkActive(5, "active")}`}
              onClick={() => handleClick(5)}
            >
              <button>Packages</button>
            </span> */}
          </div>
        </HandleSearchAndTab>
      </div>
      <div className="panels">
        {userdata && (
          <>
            <div className={`panel ${checkActive(1, "active")}`}>
              <AdminProfile
                userdata={userdata}
                setUserData={setUserData}
                setMssg={setMssg}
              />
            </div>
            <div className={`panel ${checkActive(2, "active")}`}>
              <AdminEmail
                userdata={userdata}
                setUserData={setUserData}
                setMssg={setMssg}
              />
            </div>
            <div className={`panel ${checkActive(3, "active")}`}>
              <AdminPassword setMssg={setMssg} />
            </div>
            <div className={`panel ${checkActive(4, "active")}`}>
              <AdminNotice
                userdata={userdata}
                setUserData={setUserData}
                setMssg={setMssg}
              />
            </div>
            <div className={`panel ${checkActive(5, "active")}`}>
              <OverviewRap className="fourcard_grid all_overview" style={{ justifyContent: "left" }}>
                {plans.length ? [...plans].reverse().map(plan => (
                  <div className="grid-row per_overview mx-2" key={plan.id} style={{ textAlign: 'center' }}>
                    <h3>{titleCase(plan.plan_type)}</h3>
                    <h5>N{plan.price}</h5>
                    <a onClick={() => {
                      setOpenModal(true)
                      setActivePackage(plan)
                    }}>See Details</a>
                  </div>
                )) : ""}
              </OverviewRap>
            </div>
          </>
        )}
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} apackage={activePackage} />

    </WithAuth>
  );
};

export default AdminSettings;


const Modal = ({ open, onClose, apackage }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck">
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Package Info</h3>
              </div>
              <label><b>Name</b></label>
              <p>
                {apackage && apackage.plan_type}
              </p>
              <label><b>Description</b></label>
              <p>
                {apackage && apackage.description}
              </p>
            </div>
            <button>Subscribe</button>
          </div>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};


const OverviewRap = styled.div`
  .mx-2{
    margin: 10px 15px;
  }
  a{
    color: blue;
    cursor: pointer;
  }
  
  @media (min-width: 1320px) {
    .per_overview {
      width: 19.2%;
    }
  }
`;
const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }
  @media (min-width: 760px) {
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;

    .tab {
      margin: 0;
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 10px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 5px 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
