import { useState, useEffect } from "react";
import WithAuth from "../../components/partials/withAuth";
import { SVGs } from "../../assets/svg/SVGs";
import { MssgContainer, getEstateID, getImage, titleCase } from "../../components/partials/utils";
import styled from "styled-components";
import { HOST_URL, SK_VALUE, getCookie, reqOptions } from "../../assets/js/helpFunction";
import { WithSuperAuth } from "../SuperAdmin/S-Overview";
import { useParams } from "react-router";

const AdminResidents = () => {
  const { estate_id } = useParams()
  const [data, setData] = useState({})
  const [residents, setResidents] = useState([])
  const [mssg, setMssg] = useState({ error: false, mssg: "" })
  const [search, setSearch] = useState("");
  const [activeIndex, setActiveIndex] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [actionOpen, setActionOpen] = useState(false);
  const [actionResident, setActionResident] = useState(null)
  const [tabsValues, setTabsValues] = useState(false);
  const [bulkErrorText, setBulkErrorText] = useState(null)
  const checkActive = (index) => (activeIndex === index ? "active" : "");

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }

  useEffect(() => {
    const requestOptions = reqOptions('get', null, true)
    const api = `${HOST_URL()}estate_users/admin_list?estate_id=${estate_id}`
    fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        console.log('data.results', data.results)
        setResidents(data.results)
        setData(data)
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }, [])


  const filterResident = (index, name) => {
    setActiveIndex(index);
    setTabsValues(name)
    document
      .querySelectorAll("tab")
      .forEach((item) =>
        item.classList.includes(activeIndex)
          ? item.classList.add("active")
          : item.classList.remove("active")
      );

    if (name != "ALL") {
      setResidents(data.results?.filter((item) => item.user_type == name));
    } else {
      setResidents(data.results);
    }
  }

  const addUser = (user, onEnd, clearField) => {
    const api = `${HOST_URL()}estate_users/admin_invite/?estate_id=${estate_id}`
    if (!user?.email || !user?.first_name || !user?.last_name || !user?.mobile) {
      onEnd()
      return setMssg({ ...mssg, mssg: 'Please complete the form', error: true })
    }
    const requestOptions = reqOptions('post', JSON.stringify(user), true)
    fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        console.log('data', data)
        if (data.error) {
          onEnd()
          return setMssg({ ...mssg, mssg: data.error, error: true })
        } else {
          setMssg({ ...mssg, mssg: data.message })
          onEnd()
          clearField()
          return window.location.reload()
        }

      })
      .catch(err => {
        console.log('err', err)
        setMssg({ ...mssg, mssg: err.message, error: true })
      })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleActivate = resident => {
    const modifyURL = `${HOST_URL()}estate_users/modify_user/?estate_id=${estate_id}`
    const status = resident.status === "INACTIVE" ? "ACTIVE" : "INACTIVE"
    const requestOptions = reqOptions('post',
      JSON.stringify({ status: status, estate_user_id: resident.id }), true)
    fetch(modifyURL, requestOptions)
      .then(res => res.json())
      .then(response => {
        setResidents(residents.map(item => {
          if (item.id === response.id) {
            return { ...item, status: response.status }
          }
          return item
        }))
        setMssg({ ...mssg, mssg: `Account ${status.toLocaleLowerCase() === "active" ? "activated" : "deactivated"} successfully` })
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }
  const addMultipleUser = (data) => {
    const header = {
      method: "post",
      headers: {
        "EstateIQ-Sk-Header": SK_VALUE(),
        "X-CSRFToken": getCookie("CSRF-TOKEN"),
        Origin: window.location.origin,
        "Authorization": "Bearer " + getCookie("access")
      },
      body: data
    }

    const bulk_upload_api = `${HOST_URL()}estate_users/estate_user_bulk_upload/?estate_id=${estate_id}`
    fetch(bulk_upload_api, header)
      .then(res => res.json())
      .then(response => {
        console.log('response', response)
        // if (response.error_exist) {
        //   let error_text = ""
        //   response.errors.forEach(error => error_text += `${error}`)
        //   setBulkErrorText(error_text)
        // } else {
        //   setMssg({ ...mssg, mssg: response.message })
        // }
        // console.log(response)
        // setTimeout(() => window.location.reload(), 1000)
      })
      .catch(err => setMssg({ ...mssg, mssg: err.message, error: true }))
  }

  const handleDetailsModal = (resident) => {
    setCurrentDetail(resident)
    setOpenDetailsModal(true)
  }
  return (
    <WithSuperAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}

      <HandleSearchAndTab>
        <div className="tabs">
          <span
            className={`tab 1 ${checkActive(1)}`}
            onClick={() => filterResident(1, "ALL")}
          >
            <button>All</button>
          </span>

          <span
            className={`tab 1 ${checkActive(2)}`}
            onClick={() => filterResident(2, "ADMIN")}
          >
            <button>Admin</button>
          </span>

          <span
            className={`tab 2 ${checkActive(3)}`}
            onClick={() => filterResident(3, "RESIDENT")}
          >
            <button>Resident</button>
          </span>
          <span
            className={`tab 3 ${checkActive(4)}`}
            onClick={() => filterResident(4, "SECURITY")}
          >
            <button>Security</button>
          </span>
        </div>
        <div className="event-input">
          <div class="search_set">
            <img src="https://www.svgrepo.com/show/13682/search.svg" alt="" />
            <input
              className="eventt"
              type="text"
              name="name"
              placeholder="Search"
              onChange={setSearch}
            />
          </div>

          <button
            className="important-btn"
            onClick={() => setOpenModal(true)}
          >
            Add User
          </button>
        </div>
      </HandleSearchAndTab>
      {bulkErrorText && (
        <div style={{ color: 'red' }}>
          {bulkErrorText}
        </div>
      )}
      <TableFrame>
        <div className="scrollable_table">
          <table>
            <thead>
              <tr>
                <th>Residents</th>
                <th>ID</th>
                <th>Address</th>
                <th>Contact</th>
                <th>User Type</th>
                <th>Action</th>
              </tr>
            </thead>
            {residents && residents.length ? residents.map((resident, index) => (
              <tr key={index}>

                <td>
                  <div className="img-avatar">
                    <img
                      src={getImage(resident.profile_image)}
                      alt=""
                    />
                    <div className="user-name">
                      {resident.user.last_name} {resident.user.first_name}
                    </div>
                  </div>
                </td>
                <td>{resident.estate_user_id}</td>
                <td>{resident.estate.address}</td>

                <td className="contact_data">
                  <div className="contact">
                    <a href={`tel:${resident.user.mobile}`}>
                      <img src={SVGs.Call} alt="" />
                    </a>
                    <a href={`mailto:${resident.user.email}`}>
                      <img src={SVGs.Email} alt="" />
                    </a>
                    <a style={{ background: 'black', borderRadius: '100px', padding: '5px' }}>
                      <div style={{ cursor: 'pointer' }} onClick={() => handleDetailsModal(resident)}>
                        <img src={SVGs.user_white} alt="" />
                      </div>
                    </a>
                  </div>
                </td>
                <td> {titleCase(resident.user_type)}</td>
                <td className="resume_data">
                  <div class="checkbox_item citem_1">
                    <label class="checkbox_wrap">
                      <input
                        type="checkbox" name="checkbox"
                        class="checkbox_inp"
                        checked={resident.status === "INACTIVE" ? false : true}
                        // onChange={() =>handleActivate(resident)}
                        onChange={() => {
                          setActionResident(resident)
                          setActionOpen(true)
                        }}
                      />
                      <span class="checkbox_mark"></span>
                    </label>
                  </div>
                </td>
              </tr>
            )) : ""}
          </table>
        </div>
      </TableFrame>
      <Modal open={openModal} onClose={handleCloseModal} addUser={addUser} addMultipleUser={addMultipleUser} />
      <DetailsModal currentDetail={currentDetail} open={openDetailsModal} onClose={() => setOpenDetailsModal(false)} />
      <ConfirmAction open={actionOpen} onClose={() => { setActionResident(null); setActionOpen(false) }} resident={actionResident} handleActivate={handleActivate} />
    </WithSuperAuth>
  );
};

export default AdminResidents;

const Modal = ({ open, onClose, addUser, addMultipleUser }) => {
  const USER_TYPES = ['ADMIN', 'RESIDENT', 'SECURITY']
  const USER_CATEGORIES = ["EXCOS", "HEAD_OF_SECURITY", "FINANCIAL_SECURITY", "PRESIDENT", "VICE_PRESIDENT", "CHIEF_SECURITY_OFFICER", "FINANCIAL_SECRETARY", "ESTATE_MANAGER", "SECURITY",]
  let default_value = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    user_type: USER_TYPES[1],
    user_category: USER_CATEGORIES[0]
  }
  const [user, setUser] = useState(default_value)
  const [activeIndex, setActiveIndex] = useState(1);
  const [file, setFile] = useState("")
  const [isuploading, setIsUploading] = useState(false)
  const checkActive = (index) => (activeIndex === index ? "active" : "");
  const handleClick = (index) => setActiveIndex(index);

  if (!open) return null;
  const handleChange = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })

  }

  const handleSubmit = e => {
    e.preventDefault()
    addUser(user, () => onClose(), () => setUser(default_value))
    // onClose()
  }

  const handleUpload = e => {
    e.preventDefault();
    console.log(file)
    const formData = new FormData();
    formData.append(
      "csv_file",
      file,
      file.name
    );
    addMultipleUser(formData)
    // onClose()
  }

  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="new_estates_form fade-in-bck">
          <HandleSearchAndTab style={{ marginBottom: '0' }}>
            <div className="tabs" style={{ marginBottom: '0' }}>
              <span className={`tab ${checkActive(1)}`} onClick={() => handleClick(1)}>Single User</span>
              <span className={`tab ${checkActive(2)}`} onClick={() => handleClick(2)}>Bulk Upload</span>
            </div>
          </HandleSearchAndTab>
          <div className="panels">
            <div className={`panel ${checkActive(1)} fade-in-bck`}>
              <form className="" onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="create_est ">
                    <div className="form_txt">
                      <h3>Add User</h3>
                    </div>

                    <input
                      type="text"
                      value={user.first_name}
                      name="first_name"
                      placeholder="First Name"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      value={user.last_name}
                      name="last_name"
                      placeholder="Last Name"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      value={user.mobile}
                      name="mobile"
                      placeholder="Mobile phone"
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      value={user.email}
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                    <p style={{ marginTop: '20px' }}>User Type</p>
                    <select onChange={handleChange} name="user_type" value={user.user_type} className="form-control" placeholder="User type">
                      {USER_TYPES.map(option => <option value={option}>{option.replaceAll('_', ' ')}</option>)}
                    </select>
                  </div>
                </div>
                <button className="btn btn-success btn-block">Add User</button>
              </form>
            </div>
            <div className={`panel ${checkActive(2)} fade-in-bck`}>
              <form onSubmit={handleUpload}>
                <div className="inputplabel">
                  <input
                    type="file"
                    id="imageChanger"
                    onChange={e => setFile(e.target.files[0])}
                    required
                  />
                  <button className="important-btn" style={{ margin: 0 }} disabled={isuploading}>{isuploading ? "Uploading file" : "Add Users"}</button>
                  <div className="dummy_holder" style={{ textAlign: "center" }}>
                    <label htmlFor="imageChanger">
                      <span><a href={`${process.env.PUBLIC_URL}/bulk-residents.csv`} download style={{ color: "blue", fontWeight: "bolder" }}>Click Here</a> to download sample file.</span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};

const DetailsModal = ({ currentDetail, open, onClose }) => {
  console.log('currentDetail', currentDetail)

  if (!open) return null;

  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >

        <div className="new_estates_form fade-in-bck">
          <div className="panels">
            <div className={`panel active fade-in-bck`}>
              <form className="">
                <div className="form-group">
                  <div className="create_est ">
                    <div className="form_txt">
                      <h3>User Details</h3>
                    </div>
                    <div>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>User ID</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.estate_user_id}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>Email</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.user?.email}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>First Name</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.user?.first_name}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>Last Name</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.user?.last_name}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>Mobile</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.user?.mobile}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>User Type</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.user_type}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>Address</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.estate?.address}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>State</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.estate?.state}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>LGA</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.estate?.lga}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                      <span style={{ marginTop: '20px' }}>Country</span>
                      <span style={{ marginTop: '20px' }}>{currentDetail?.estate?.country}</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};

export const ConfirmAction = ({ open, onClose, resident, handleActivate }) => {


  if (!open) return null;


  const confirm = () => {
    handleActivate(resident)
    onClose()
  }


  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <div className="new_estates_form fade-in-bck">
          <h3>{resident.status === "INACTIVE" ? "Activate" : "Deactivate"} Resident</h3>
          <p>
            Are you sure you want to {resident.status === "INACTIVE" ? "activate" : "deactivate"} this resident?
          </p>
          <button onClick={confirm}>Yes, Please</button>
        </div>


        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};

const HandleSearchAndTab = styled.section`
  .container {
    margin-bottom: 20px;
  }
  .normal_tab {
    background: transparent;
  }
  .event-input {
    max-width: 675px;
  }

  @media (min-width: 760px) {
    .search_set {
      margin-right: 10px;
    }
    display: flex;
    justify-content: space-between;
    .event-input {
      display: flex;
      justify-content: space-between;
      //   width: 57%;
      .select_me {
        margin-left: 10px;
        width: auto !important;
      }
      button {
        margin-top: 0;
        height: 45px;
      }
      .event-select {
        width: 20%;
      }
    }
  }
  @media (max-width: 540px) {
    .event-input {
      .event-select {
        display: none;
      }
    }
  }
  .tabs {
    margin: 0;
    margin-bottom: 30px;
    .tab {
      margin: 0;
      height: 45px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      min-width: 100px;

      text-align: center;
      margin-right: 15px;
      border-bottom: 3px solid #c0c0c0;
      button {
        margin: 0;
        padding: 0 !important;
        padding: 10px;
        //styleName: Web/Small Copy;

        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .active {
      background: transparent;
      border-bottom: 3px solid #2d4bf3;
      color: #2d4bf3;
      button {
        color: #2d4bf3;
      }
    }
  }
  .normal_tab.add_tape {
    border-bottom: 2px solid yellow;
  }
`;
const TableFrame = styled.div`
  // .scrollable_table {
  //   font-family: "Satoshi";
  //   margin-left: 40px;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 121%;
  //   display: flex;
  //   align-items: center;
  //   /* Grey/2 */
  //   color: #545454;
  // }

  @media (max-width: 900px) {
    .scroll {
      min-width: 950px;
      width: 100%;
    }
  }

  .select_me {
    width: 150px;
    .select-btn {
      padding-left: 14px;
    }
  }

  .scrollable_table tr {
    margin-left: 20px;
  }

  .img-avatar img {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
  }
  .img-avatar {
    display: flex;
    align-items: center;
  }
  .user-name {
    margin-left: 10px;
    width: 134px;
    height: 23px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.06em;

    /* Grey/1 */
    color: #111111;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .team-members {
    // display: flex;
    // justify-content: space-between;
    margin-top: 40px;
  }

  .contact {
    margin: 5px;
    display: flex;
  }
  .contact a {
    margin: 8px;
    width: 30px;
    height: 30px;
  }
`;
