import { useState } from "react";
import styled from "styled-components";
import { MssgContainer, getDate, getEstateID, titleCase } from "./partials/utils";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";

const VerifyButton = ({user}) => {
  const [openModal, setOpenModal] = useState(false);
  const [Accessible, setAccessible] = useState(false);
  const [mssg, setMssg] = useState({ error: false, mssg: "" });
  const [access, setAccess] = useState({});

  const handleVerifyCode = (code) => {
    let requestOptions = reqOptions("post", JSON.stringify(code), true);
    const api = `${HOST_URL()}estate_access_logs/verify/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(data.error){
          setMssg({
            error: true,
            mssg: data.error,
          });
        }else{

          setMssg({
            error: data.access ? false : true,
            mssg: data.access ? "Access Verified" : "Result Not Found",
          });
          setAccess(data);
          setAccessible(true);
        }
      })
      .catch((err) => setMssg({ error: true, mssg: err.message }));
  };

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" });
  };

  return (
    <OnboardingTag>
      <div className="download_filter">
        <div className="ff-right">
          <h4>Welcome {user?.estate_user?.user?.first_name}</h4>
          <p>#{user?.estate_user?.estate_user_id}</p>
        </div>
        <div className="filter_download">
          <button className="important-btn" onClick={() => setOpenModal(true)}>
            Verify Access Code
          </button>
        </div>
      </div>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}

      <VerifyCodeModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        verifyCode={handleVerifyCode}
      />
      <AccessConfirmation
        open={Accessible}
        onClose={() => setAccessible(false)}
        access={access}
      />
      {/* <SetEmergency open={ViewSOS} onClose={() => setViewSOS(false)} /> */}
    </OnboardingTag>
  );
};

export default VerifyButton;

const VerifyCodeModal = ({ open, onClose, verifyCode }) => {
  const [accesscode, setAccesscode] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    verifyCode({ access_code: accesscode });
    setAccesscode("");
    onClose();
  };
  if (!open) return null;
  return (
    <HandleQuickAccess onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <AccessPin 
          onSubmit={handleSubmit} 
          className="accessPin"
          >
          <h3>Input Access Code</h3>
          {/* <h1>PQY-3091</h1> */}
          <input
            type="text"
            className="mainPin"
            required
            value={accesscode}
            onChange={(e) => setAccesscode(e.target.value)}
          />
          <p>Code only valid for 12 Hours</p>

          <button className="important-btn" type="submit">
            Verify
          </button>
        </AccessPin>
        <img
          src="https://www.svgrepo.com/show/311932/close.svg"
          alt=""
          onClick={onClose}
        />
      </div>
    </HandleQuickAccess>
  );
};

const AccessConfirmation = ({ open, onClose, access }) => {
  const [accesscode, setAccesscode] = useState("");

  const declineAccess = () => {
    let requestOptions = reqOptions("POST", JSON.stringify({access : "REVOKE", access_code : access.access_code}), true);
    const api = `${HOST_URL()}estate_access_logs/modify/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
    .then((res) => res.json())
    .then(data => {
      console.log(data)
    })
    .catch((err) => console.log(err));
  }

  if (!open) return null;
  return (
    <HandleQuickAccess onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <AccessPin className="accessPin">
          <h3>Access Info</h3>
          <div className="accessInfo">
            <div className="eachUnit">
              <p>Guest Name</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.first_name} ${access.last_name}`}
              />
            </div>
            <div className="eachUnit">
              <p>Host Name</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.estate_user?.user?.first_name} ${access.estate_user?.user?.last_name}`}
              />
            </div>
            <div className="eachUnit">
              <p>Access Code</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.access_code}`}
              />
            </div>
            <div className="eachUnit">
              <p>Access Type</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${titleCase(access.access_type.replace("_", "-"))}`}
              />
            </div>
            <div className="eachUnit">
              <p>Validity From </p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${getDate(access.from_date)}`}
              />
              <p>Validity To</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${getDate(access.to_date)}`}
              />
            </div>
            <div className="eachUnit">
              <p>Destination</p>
              <input
                readOnly
                type="text"
                className="unitData"
                value={`${access.address}`}
              />
            </div>
            <button className="important-btn" onClick={onClose}
              style={{margin : "10px 10px"}}
            >
              Grant Access
            </button>
            <button className="important-btn" 
              onClick={() => {
                declineAccess()
                onClose()
              }} 
              style={{background : 'red', margin : "10px 10px"}}>
              Decline Access
            </button>
          </div>
        </AccessPin>
        <img
          src="https://www.svgrepo.com/show/311932/close.svg"
          alt=""
          onClick={onClose}
        />
      </div>
    </HandleQuickAccess>
  );
};

let OnboardingTag = styled.div`
  .ff-right {
    margin-bottom: 30px;
  }
  @media (max-width: 414px) {
    button {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .download_filter {
      height: 60px;
    }
  }
  .filter_download {
    @media (min-width: 768px) {
      .outlined-btn {
        margin-right: 10px;
      }
    }
    .outlined-btn {
      margin-right: 10px;
      color: #ff0000;
      border-color: #ff0000;
      &:hover {
        background-color: #ff0000;
        color: #ffffff;
      }
    }
    button {
      margin-top: 0;
      padding: 10px 20px;
      height: 45px;
      // border: 2px solid #1737e6;
      font-weight: 500;
      // background: #1737e6;
      // color: #ffffff;
      transition: 0.4s;
      transform: scale(1);
      &:hover {
        transform: scale(0.95);
      }
    }
  }
  @media (min-width: 768px) {
    .outlined {
      margin-right: 10px;
    }
  }
`;

let AccessPin = styled.form`
  background: #fff;
  text-align: center;
  padding: 50px;

  p {
    margin-top: 15px;
    text-align: center;
    color: #2d4bf3;
    font-weight: 500;
  }
  input {
    text-align: center;
  }
  button {
    padding: 20px;
    font-size: px;
    min-width: 200px;
  }
  h3 {
    font-size: 20px;

    font-weight: 700;
    line-height: 23px;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
  @media (min-width: 768px) {
    .mainPin {
      font-size: 64px;
      font-weight: 700;
      padding: 30px;
    }
  }
  .accessInfo {
    .eachUnit {
      margin: 20px 0;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #686868;
        margin-bottom: 5px;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
      .unitData {
        color: #111;
        border: 0;
        padding: 0;
        max-width: 300px;
        font-size: 25px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.06em;
        text-align: left;
      }
    }
  }
  .mainPin {
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    padding: 30px;
  }
`;

let HandleQuickAccess = styled.div`
  .modalContainer {
    margin-top: 80px;
    max-width: 650px;
  }
  @media (min-width: 600px) {
    .doubleInput {
      display: flex;
      justify-content: space-between;
      label {
        width: 100%;
      }
      input {
        width: 96%;
      }
    }
  }
`;
