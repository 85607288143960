import { useState } from "react";
import styled from "styled-components";
import { getEstateID } from "./partials/utils";
import { HOST_URL, reqOptions } from "../assets/js/helpFunction";



const AdminNotice = ({ userdata, setUserData, setMssg }) => {

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userdata,
      [name]: value,
    });
    console.log(name, value)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const api = `${HOST_URL()}estate_users/estate_user_profile_update/?estate_id=${getEstateID()}`;
    const requestOptions = reqOptions('put', JSON.stringify(userdata), true)
      fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setMssg({mssg : data.data ? data.message : data.detail, error : data.data ? false : true})
      })
      .catch(err => setMssg({mssg : err.message, error : true}))
  };
  return (
    <AdminNotificationForm onSubmit={handleSubmit}>
      <p>
        Get notification to find out what’s going even when you’re not
        online. You can turn them off anytime.
      </p>
      {userdata && userdata.estate_user? (
        <div>
          {/* REMINDERS */}
          <div className="notifcation_settings">
            <div className="ff-note">
              <h5>Reminder</h5>
              <p>Receive the reminders</p>
            </div>
            <div className="ss-note">
              {/* I dont want notification */}
              <div class="radio-buttons">
                <div class="form-group">
                  <input
                    type="radio"
                    id="allreminder"
                    name="reminders"
                    value="ALL_REMINDERS"
                    checked={userdata.reminders === "ALL_REMINDERS"}
                    onChange={handleChange}
                  />
                  <label for="allreminder">All Reminder</label>
                  <p></p>
                </div>

                <div class="form-group">
                  <input
                    type="radio"
                    id="importantreminder"
                    name="reminders"
                    value="IMPORTANT_REMINDERS"
                    checked={userdata.reminders === "IMPORTANT_REMINDERS"}
                    onChange={handleChange}
                  />
                  <label for="importantreminder">
                    Important Reminders only
                  </label>
                </div>

                <div class="form-group">
                  <input
                    type="radio"
                    id="noreminder"
                    name="reminders"
                    value="NO_REMINDERS"
                    checked={userdata.reminders === "NO_REMINDERS"}
                    onChange={handleChange}
                  />
                  <label for="noreminder">No Reminder</label>
                </div>
              </div>
            </div>
          </div>
          {/* MENTIONS */}
          <div className="notifcation_settings">
            <div className="ff-note">
              <h5>Mentions</h5>
              <p>
                These are notifications to reminds you of updates you might have
                missed.
              </p>
            </div>
            <div className="ss-note">
              {/* I dont want notification */}
              <div class="radio-buttons">
                <div class="form-group">
                  <input
                    type="radio"
                    id="allmention"
                    name="mentions"
                    value="ALL_MENTIONS"
                    checked={userdata.mentions === "ALL_MENTIONS"}
                    onChange={handleChange}
                  />
                  <label for="allmention">All Mentions</label>
                  <p></p>
                </div>

                <div class="form-group">
                  <input
                    type="radio"
                    id="mentionsOnly"
                    value="IMPORTANT_MENTIONS"
                    checked={userdata.mentions === "IMPORTANT_MENTIONS"}
                    name="mentions"
                    onChange={handleChange}
                  />
                  <label for="mentionsOnly">Important Mentions</label>
                </div>

                <div class="form-group">
                  <input
                    type="radio"
                    id="nomentions"
                    value="NO_MENTIONS"
                    name="mentions"
                    checked={userdata.mentions === "NO_MENTIONS"}
                    onChange={handleChange}
                  />
                  <label for="nomentions">No Mentions</label>
                </div>
              </div>
            </div>
          </div>
          <div className="action-btn">
            <button type="submit" className="important-btn">
              Save Changes
            </button>
          </div>
        </div>
      ) : (
        <p>oops</p>
      )}
    </AdminNotificationForm>
  )
};

export default AdminNotice;

let AdminNotificationForm = styled.form`
  max-width: 678px;
  @media (min-width: 700px) {
    .notifcation_settings {
      display: flex;
      justify-content: space-between;
      .ff-note {
        width: 45%;
      }
      .ss-note {
        width: 50%;
        #handleChecks {
          margin-top: 0 !important;
          margin-bottom: 20px !important;
        }
      }
    }
  }
  p {
    max-width: 567px;
  }

  .notifcation_settings {
    margin: 30px 0;

    .ff-note {
      h5 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .ss-note {
      #handleChecks {
        margin-top: 20px;

        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
        p {
          margin: 0 auto;
          margin-left: 27px;
        }
      }
    }
  }

  .radio-buttons {
    .form-group {
      margin-bottom: 20px;
      margin-top: 10px;
      p {
        margin-left: 35px;
      }
    }
    input[type="radio"] {
      display: none;
    }

    label {
      cursor: pointer;
      position: relative;
      font-size: 18px;
      margin-left: 35px;
    }

    label::before {
      content: "";
      position: absolute;
      width: 20px;
      left: -35px;
      height: 20px;
      background-color: transparent;
      border: 1px solid #2d4bf3;
      border-radius: 50%;
      top: 50%;

      transform: translateY(-50%);
      transition: border-color 400ms ease;
    }

    label::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -35px;
      background-color: #2d4bf3;
      border: 1px solid #2d4bf3;
      border-radius: 50%;
      top: 50%;

      transform: translateY(-50%) scale(0);
      transition: transform 400ms ease;
    }

    input[type="radio"]:checked + label::before {
      border-color: #2d4bf3;
    }

    input[type="radio"]:checked + label::after {
      transform: translateY(-50%) scale(0.55);
    }
  }
`;
