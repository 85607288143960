import React, { useState } from "react";

const ProfileType = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);

  const options = [
    "GROCERIES_SHOPPING",
    "HEALTH_AND_WELLNESS",
    "REAL_ESTATE",
    "FASHION_AND_LIFESTYLE",
    "AUTOMOBILE",
    "DELIVERY",
  ]

  return (
    <div className="select_me filter_drop">
      <div className="select-btn" onClick={(e) => setIsActive(!isActive)}>
        <input type="text" value={selected} readOnly placeholder="Marketplace Type" />
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 9L12.5 15L18.5 9"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isActive && (
        <div className="select_content">
          {/* <h5>Duration</h5> */}
          {options.map((option) => (
            <div
              className="select_items"
              onClick={(e) => {
                setSelected(option);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfileType;
