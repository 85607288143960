import {Link, useNavigate} from "react-router-dom"
import {

  HOST_URL,
  setCookie,
  getCookie,
  removeCookie,
} from "../assets/js/helpFunction";
import styled from "styled-components";
import SubmitBtn from "./form/SubmitBtn";
import { Images } from "../assets/images/Images";
import { useEffect } from "react";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(getCookie("access") === null){
            return navigate("/login")
        }
    }, [])
    const logout = () => {
        removeCookie("access")
        return navigate("/")
    }
  return (
    <Auth className="acct_info" >
      <div className="create_bg" style={{width : "100%"}}>
        <div className="create_est" style={{textAlign : "center"}}>
          <Link to={"/"}>
            <img src={Images.logo} alt="Estate-IQ" />
          </Link>
          <form onSubmit={logout} className="fade-in-bck">
            <div className="form_txt">
              <h1>Logout</h1>
              <p className="with_link" style={{textAlign : "center"}}>
                Are you sure you want to logout?
              </p>
            </div>

            {/* <Link to="/"> */}
            <SubmitBtn
              types="submit"
              add_class="important-btn"
              labels="Yes, Logout"
            />
            {/* <SubmitBtn /> */}
            {/* </Link> */}
          <Link style={{fontWeight : 'bolder', color : "blue"}} onClick={() => navigate(-1)}>No, Go back</Link>
          </form>
        </div>
      </div>
    </Auth>
  );
};

export default Logout;

let Auth = styled.section`
  .pointer{
    cursor: pointer;
  }
  .important-btn {
    color: #fff !important;
  }
  .password_v {
    position: relative;
    p {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 20px;
    }
  }
`;
