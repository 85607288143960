import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  fetchAPI,
  getCookie,
  HOST_URL,
  reqOptions,
  SK_VALUE,
} from "../assets/js/helpFunction";
import { SVGs } from "../assets/svg/SVGs";
import TextField from "./form/TextField";
import { getEstateID, getImage } from "./partials/utils";

const AdminProfile = ({ userdata, setUserData, setMssg }) => {
  console.log(userdata, 'datatat')
  const [formData, setFormData] = useState({});
  const [uploading, setUploading] = useState(false)
  let { estate_id } = useParams();
  const handleSearch = (event) => {
    console.log(event);
    setFormData(event);
  };

  const handleChange = event => {
    const { name, value } = event.target
    if (['last_name', 'first_name', 'mobile', 'email'].includes(name)) {
      // console.log('included')
      setUserData(current => {
        return {
          ...current,
          estate_user: {
            ...current.estate_user,
            user: {
              ...current.estate_user.user,
              [name]: value
            }
          }
        }
      })
    } else if (['estate_type'].includes(name)) {
      setUserData(current => {
        return {
          ...current,
          estate_user: {
            ...current.estate_user,
            estate: {
              ...current.estate_user.estate,
              [name]: value
            }
          }
        }
      })
    }
    else {
      setUserData({
        ...userdata,
        [name]: value
      })
    }
  }

  const handleSubmitPage = (event) => {
    event.preventDefault();
    const api = `${HOST_URL()}estate_users/estate_user_profile_update/?estate_id=${getEstateID()}`;
    let updatedData = userdata
    delete updatedData["profile_image"];
    let requestOptions = reqOptions('put', JSON.stringify(updatedData), true)
    fetch(api, requestOptions)
      .then(res => res.json())
      .then(data => {
        setMssg({ mssg: data.data ? data.message : data.detail, error: data.data ? false : true })
        if (data.data) {
          setUserData(data.data)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch(err => setMssg({ mssg: err.message, error: true }))
  };

  const handleImage = e => {
    setUploading(true)
    const formData = new FormData();
    formData.append('profile_image', e)
    const header = {
      method: "post",
      headers: {
        "EstateIQ-Sk-Header": SK_VALUE(),
        "X-CSRFToken": getCookie("CSRF-TOKEN"),
        Origin: window.location.origin,
        "Authorization": "Bearer " + getCookie("access"),
      },
      body: formData
    }

    const api = `${HOST_URL()}estate_users/estate_user_profile_image_update/?estate_id=${getEstateID()}`
    fetch(api, header)
      .then(res => res.json())
      .then(response => {
        setUploading(false)
        setMssg({ mssg: response.message })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      .catch(err => console.log(err))
    // setUserData(
    //   {...userdata, profile_image : e}
    // )

  }

  const _handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFormData({
        ...formData,
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    // console.log(file);
    reader.readAsDataURL(file);
  };

  let { imagePreviewUrl } = formData;
  let $imagePreview = null;
  if (imagePreviewUrl) {
    $imagePreview = <img src={imagePreviewUrl} />;
  } else {
    $imagePreview = (
      <div className="previewText">
        <img
          src={userdata && getImage(userdata.profile_image)}
          alt={userdata && userdata.first_name}
        />
      </div>
    );
  }
  const date = new Date(userdata.timestamp);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const daysAgo = Math.floor((Date.now() - date) / (1000 * 60 * 60 * 24));
  return (
    <AdminProfileForm onSubmit={handleSubmitPage}>
      <p>
        Manage your personal information, and control which information other
        people see and apps may access.
      </p>

      {/* {userdata && userdata.estate_user && userdata.estate_user.user ? (
        <div>
          <h3>
            {userdata.estate_user?.user?.first_name}{" "}
            {userdata.estate_user?.user?.last_name}
          </h3>
          <p>
            {userdata.estate_user.user_type.charAt(0).toUpperCase() +
              userdata.estate_user.user_type.slice(1).toLowerCase()}
          </p>
        </div>
      ) : (
        ""
      )} */}
      {userdata && userdata.estate_user && userdata.estate_user.user ? (
        <div>
          <div className="handleImgDisplay">
            <div className="preview_me">
              <img src={userdata && getImage(userdata.profile_image)} alt="" />
            </div>
            <div className="inputplabel">
              <input
                type="file"
                id="imageChanger"
                onChange={e => handleImage(e.target.files[0])}
                accept="image/png, image/gif, image/jpeg"
              />
              <div className="dummy_holder">
                <label htmlFor="imageChanger">
                  <img src={SVGs.FrameImg} alt="" />

                  <p>
                    <span>Click to replace</span>
                  </p>
                </label>
              </div>
            </div>
          </div>

          {
            uploading && (
              <div style={{ background: "#0f0061", color: "white", padding: "3px", }}>
                <p style={{ textAlign: "center" }}>Uploading Image...</p>
              </div>
            )
          }

          <h5>Estate Name</h5>
          <input
            type="text"
            placeholder="Building Name"
            defaultValue={userdata.estate_user.estate.name}
            name="name"
            readOnly
          />
          <div className="residentid">
            <h4>Estate ID</h4>
            <p>{userdata.estate_user.estate.estate_id}</p>
          </div>

          {
            userdata.estate_user.user_type == "ADMIN" ? (
              <>
                <label>Type of Estate</label>
                <select className="form-control"
                  onChange={handleChange} name="estate_type"
                  value={userdata.estate_user.estate.estate_type}
                >
                  <option>Select Estate type</option>
                  <option value={"PUBLIC_RESIDENTIAL_HOUSING"}>Public Residential Housing</option>
                  <option value={"PRIVATE_ESTATE"}>Private Estate</option>
                  <option value={"CORPORATE_RESIDENTIAL_ESTATE"}>Corporate Residential Housing</option>
                </select>
              </>
            ) : (
              <TextField
                types="text"
                labels="Estate type"
                names="estate_type"
                defaultValue={userdata.estate_user.estate.estate_type}
                readOnly={true}

              />
            )
          }
          {/* <div className="handleImgDisplay">...</div> */}
          {/* ... */}

          {/* CHANGE ADMIN FORM INPUTS AND DATA */}
          <h5>Personal Bio</h5>
          <div className="double_lens">
            <TextField
              types="text"
              labels="First Name"
              names="first_name"
              defaultValue={userdata.estate_user?.user?.first_name}
              onChange={handleChange}
            />
            <TextField
              types="text"
              labels="Last Name"
              names="last_name"
              defaultValue={userdata.estate_user?.user?.last_name}
              onChange={handleChange}
            />
          </div>

          <label>Gender</label>
          <select className="form-control" onChange={handleChange} name="gender" value={userdata.gender}>
            <option>Select gender</option>
            <option value={"MALE"}>Male</option>
            <option value={"FEMALE"}>Female</option>
          </select>
          {/* <div className="double_lens">
            <select className="form-control" 
              onChange={handleChange} name="nationality" 
              value={userdata.nationality}
            >
              <option>Country</option>
              <option value={"NIGERIA"}>Nigeria</option>
              <option value={"OTHERS"}>Others</option>
            </select>

            <select className="form-control" onChange={handleChange} name="gender" value={userdata.gender}>
              <option>Gender</option>
              <option value={"MALE"}>Male</option>
              <option value={"FEMALE"}>Female</option>
            </select>
          </div> */}

          <label htmlFor="DOB">
            <TextField
              types="date"
              labels="Date of Birth"
              names="date_of_birth"
              defaultValue={userdata.date_of_birth}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="Address">
            <TextField
              types="text"
              labels="Address"
              names="address"
              defaultValue={userdata.address}
              onChange={handleChange}
            />
          </label>

          <div className="contactInfo">
            <h5>Contact Information</h5>
            <label htmlFor="Email">
              <TextField
                types="email"
                labels="Email"
                names="email"
                defaultValue={userdata.estate_user?.user?.email}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="Mobile">
              <TextField
                types="number"
                labels="Mobile"
                names="mobile"
                defaultValue={userdata.estate_user?.user?.mobile}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="action-btn">
            <button type="submit" className="important-btn">
              Update
            </button>
          </div>

          <p className="timestamp">
            Account was created <i>{formattedDate}</i> that's{" "}
            <i>
              {daysAgo} {daysAgo === 1 ? "day" : "days"}
            </i>{" "}
            ago
          </p>
        </div>
      ) : (
        ""
      )}
    </AdminProfileForm>
  );
};

export default AdminProfile;

let AdminProfileForm = styled.form`
  max-width: 530px;
  .timestamp {
  }
  button {
    margin-bottom: 50px;
  }
  .double_lens > select{
    width: 45%;
  }
  @media (min-width: 600px) {
    .double_lens {
      display: flex;
      justify-content: space-between;
      div {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 420px) {
    /* .double_lens {
      display: block;
      width : 100% !important
    } */
    .double_lens > select{
      width: 100% !important;
      margin: 15px 0;
    }
    *{
      width: 100%;
    }
  }
  h5 {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  input {
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .residentid {
    margin: 15px 0;
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      margin: 0;
      align-items: center;
      color: #2d4bf3;
    }
  }
  .handleImgDisplay {
    @media (min-width: 700px) {
      display: flex;
      justify-content: space-between;
      .inputplabel {
        width: 65%;
      }
      .dummy_holder {
        background: #f2f6ff;

        border: 1.5px dashed rgba(44, 51, 58, 0.15);
        border-radius: 5px;
        text-align: center;
        p {
          text-align: center;
          width: 60%;
        }
      }
    }

    margin: 30px 0;
    input {
      display: none;
    }
    .preview_me {
      width: 140px;
      height: 140px;
      position: static;
      background: #f3f3f3;
      border-radius: 50%;
      margin-bottom: 30px;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        position: static;
        object-fit: cover;
      }
    }
    .dummy_holder {
      padding: 20px;
      background: #f2f6ff;
      border: 1.5px dashed rgba(44, 51, 58, 0.15);
      border-radius: 5px;
      text-align: center;
      img {
        width: 40px;
        height: 40px;
      }
      p {
        text-align: center;
        font-size: 18px;
        margin: 0 auto;
        span {
          cursor: pointer;
          font-size: 18px;
          color: #2d4bf3;
        }
      }
    }
  }
`;
