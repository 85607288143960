import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';


const CustomAccordion = ({
  header,
  defaultCheckedIndex,
  children,
  headerHoverStyle,
  ...rest
}) => {
  return (
    <Box w={'100%'} bg={'#fff'} {...rest}>
      <Accordion
        defaultIndex={defaultCheckedIndex ? defaultCheckedIndex : 1}
        allowToggle
        allowMultiple={false}
      >
        <AccordionItem borderTop={'none'}>
          <h2>
            <AccordionButton
              as='div'
              cursor={'pointer'}
              _expanded={headerHoverStyle}
            >
              {header}
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>{children}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default CustomAccordion;
