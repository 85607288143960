import React, { useEffect, useState } from "react";
import WithAuth from "../../components/partials/withAuth";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import { MssgContainer, PaginationCompUI, getEstateID } from "../../components/partials/utils";
import { SVGs } from "../../assets/svg/SVGs";
import { Link } from "react-router-dom";

function AdminZones() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [zones, setZones] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [mssg, setMssg] = useState({error : false, mssg : ""})

  const closeMssg = () => {
    setMssg({error : false, mssg : ""})
  }

  useEffect(() => {
    let requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estates/estates_zone/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setZones(data.results);
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false)
        setMssg({...mssg, mssg : err.message, error : true})
      });
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCreateZone = (zone) => {
    let requestOptions = reqOptions(
      "post",
      JSON.stringify({ names: zone }),
      true
    );
    const api = `${HOST_URL()}estates/estates_zone/?estate_id=${getEstateID()}`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setMssg({...mssg, mssg : data.names ? 'Zone Created Successfully' : 'Error creating Zone', error : data.name ? false : true})
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      .catch((err) => setMssg({...mssg, mssg : err.message, error : true}));
  };
  return (
    <WithAuth>
      <div className="event-container">
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg}/>}
        {isLoading ? (
          <p>Loading...</p>
        ) : zones && zones.length ? (
          <>
            <h2>Zones</h2>
            <div style={{textAlign : 'right'}}>
            <button style={{margin : '0 5px', background : '#2D4BF3', color : 'white'}}
            onClick={() => setOpenModal(true)}
            >Add Zone</button>
            </div>
            <table>
              <thead>
                <tr className="transaction-heading">
                  <th className="checkmarking">S/N</th>
                  <th>Name</th>
                  <th>Streets</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {zones.map((zone, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{zone.name}</td>
                    <td><Link to={`/admin/zones/${zone.id}/`}>View Streets</Link></td>
                    <td>Edit</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <PaginationCompUI data = {data}/>
          </>
        ) : (
          <p>
            No Zone added yet!
            <a
              onClick={() => setOpenModal(true)}
              style={{
                cursor: "pointer",
                color: "#2D4BF3",
                fontWeight: "bolder",
              }}
            >
              Create one
            </a>
          </p>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        createZone={handleCreateZone}
      />
    </WithAuth>
  );
}

export default AdminZones;

const Modal = ({ open, onClose, createZone }) => {
  // const data = edit_data
  const [zone, setZone] = useState("");

  if (!open) return null;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setZone(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createZone(zone);
    onClose(false);
    setZone("")
  };
  return (
    <div onClick={onClose} className="bills_on_me">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <form className="new_estates_form fade-in-bck" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="create_est ">
              <div className="form_txt">
                <h3>Create Zone</h3>
              </div>
              <input
                type="text"
                value={zone}
                name="zone"
                placeholder=""
                onChange={handleChange}
              />
            </div>
          </div>
          <button className="btn btn-success btn-block">Add Zone</button>
        </form>
        <img src={SVGs.close} alt="" onClick={onClose} />
      </div>
    </div>
  );
};
