import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Edit from "./EditMarketplace";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import Swal from "sweetalert2";

const TableFrame = styled.div`
  .emergency_table {
    margin: 20px;
  }

  .date-time {
    width: 81px;
    height: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #545454;
  }

  .emergency-action {
    display: flex;
    min-width: 100px !important;
    align-items: center;
    justify-content: space-between;
  }

  .del {
    font-weight: 400;
    font-size: 16px;
    color: #ff6969;
    cursor: pointer;
  }
  .edit {
    font-weight: 400;
    font-size: 16px;
    color: #2d4bf3;
    cursor: pointer;
  }

  table td {
    min-width: 60px;
  }

  .emergency-time {
    font-weight: 700;
  }
`;

function EmergencyList() {
  const [events, setEvents] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currEmer, setCurrEmer] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    const requestOptions = reqOptions("get", null, true);
    const api = `${HOST_URL()}estate_adverts/marketplace/`;
    fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log('data', data)
        setEvents(data.results)
      })
      .catch((err) => { });
  }

  const deleteEmergency = (id) => {
    const requestOptions = reqOptions("delete", null, true);
    const api = `${HOST_URL()}estate_adverts/marketplace/${id}/`;
    setLoading(true)
    fetch(api, requestOptions)
      .then(async (res) => {
        console.log('res', res)
        if (res.ok) {
          Swal.fire({
            title: `<div>You deleted profile</h4> </div>`,
            icon: "success",
            showConfirmButton: true,
            showCloseButton: true,
          });
          return fetchData()
        } else {
          const errResponse = await res.json()
          console.log('errResponse', JSON.stringify(errResponse))
          Swal.fire({
            title: JSON.stringify(errResponse),
            icon: "error",
            showConfirmButton: true,
            showCloseButton: true,
          });
          throw new Error()
        }
      })
      .then((dataReturned) => { })
      .catch((err) => { })
      .finally(() => setLoading(false))
  }

  const updateEmergency = (id, data) => {
    const requestOptions = reqOptions("put", JSON.stringify(data), true);
    const api = `${HOST_URL()}estate_adverts/marketplace/${id}/`;
    setLoading(true)
    fetch(api, requestOptions)
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const errResponse = await res.json()
          console.log('errResponse', JSON.stringify(errResponse))
          Swal.fire({
            title: JSON.stringify(errResponse),
            icon: "error",
            showConfirmButton: true,
            showCloseButton: true,
          });
          throw new Error()
        }
      })
      .then((dataReturned) => {
        console.log('dataReturned', dataReturned)
        Swal.fire({
          title: `<div>You edited profile for <h4> ${data.title}</h4> </div>`,
          icon: "success",
          showConfirmButton: true,
          showCloseButton: true,
        });
      })
      .catch((err) => { })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])


  const displayEvents = events.map((event, i) => {
    const {
      id,
      title,
      description,
      type,
      image,
      phone_number,
      whatsapp_number,
    } = event;

    return (
      <tr>
        <td>
          <div>{i + 1}.</div>
        </td>

        <td className="emergency-row">
          <div> {title} </div>
        </td>
        <td>{description}</td>
        <td>{type}</td>
        <td>
          <div className="date-time">
            <p>Call:{phone_number}</p>
          </div>
          <div className="emergency-time">
            <p>Whatsapp:{whatsapp_number}</p>
          </div>
        </td>
        <td>
          <div className="emergency-action ">
            <span aria-disabled={loading} className="edit" onClick={() => { setCurrEmer(event); setEditing(true) }}>
              Edit
            </span>
            <span aria-disabled={loading} className="del" onClick={() => deleteEmergency(id)}>
              Delete
            </span>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <section className="change_ratio">
        <Edit onFinish={updateEmergency} emergencyData={currEmer} open={editing} onClose={() => setEditing(false)} />
        <TableFrame>
          <div className="scrollable_table">
            <div className="emergency_table">
              <table>
                <thead>
                  <tr className="emergency-table-row">
                    <th></th>
                    <th>Title</th>
                    <th>Business description</th>
                    <th>Business type</th>
                    <th>Contact</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody> {displayEvents} </tbody>
              </table>
            </div>
          </div>
        </TableFrame>
      </section>
    </>
  );
}

export default EmergencyList;
