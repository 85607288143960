import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Images } from "../../assets/images/Images";
import { SVGs } from "../../assets/svg/SVGs";
import { useEffect } from "react";
import { HOST_URL } from "../../assets/js/helpFunction";
import { useState } from "react";

const CheckYourEmail = ({ userId }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [verified, setVerified] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("EstateIQ-Sk-Header", "22-wchvhshcjbdnvjb-2244-vsjdjbd");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(HOST_URL() + "auth/verify_account/?user_id=" + searchParams.get("user_id"), requestOptions)
      .then(res => {
        if (res.status === 200) {
          setVerified(true)
        }
      })
      .then(response => {
        setMessage(response.message)
      })
      .catch(err => {

      })

  }, [])
  return (
    <ConfirmPage>
      <div>
        {verified ? (
          <>
            <img src={Images.logo} alt="" className="logo-img" />
            <img src={SVGs.completed} alt="" className="checked" />
            <h1>Congratulations !!!</h1>
            <p>You have successfully registered your account.</p>
            <a href="https://play.google.com/store/apps/details?id=com.estateiq.estateiq">
              <button className="important-btn">Download App</button>
            </a>
          </>
        ) : (
          <>
            <img src={Images.logo} alt="" className="logo-img" />
            <h1>Error !!!</h1>
            <p>Loading...</p>
            <a href="https://play.google.com/store/apps/details?id=com.estateiq.estateiq">
              <button className="important-btn">Download App</button>
            </a>
          </>
        )}
      </div>
    </ConfirmPage>
  );
};

export default CheckYourEmail;

let ConfirmPage = styled.section`
  max-width: 400px;
  margin: 100px auto;
  padding: 0 30px;
  text-align: center;
  display: block;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.06em;
    color: rgba(23, 55, 229, 1);
    margin-top: 50px;
  }
  .logo-img {
    display: block;
    width: 150px !important;
    margin: 0 auto;
    margin-bottom: 50px;
    object-fit: cover;
    text-align: center !important;
  }
  p {
    text-align: center;
  }
  button {
    width: 100%;
  }
  .checked {
    width: 123px;
    height: 123px;
  }
`;
