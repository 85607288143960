import { useEffect, useState } from "react";
import AdminCards from "../../components/AdminCards";
import Onboarding from "../../components/Emergency/AdminOnboarding";
import LatestResidentsAndChart from "../../components/AdminChart";
import WithAuth from "../../components/partials/withAuth";
import { useParams } from "react-router-dom";
import { MssgContainer, getEstateID } from "../../components/partials/utils";
import { HOST_URL, reqOptions } from "../../assets/js/helpFunction";
import Adverts from "../../components/CreateAds";



const Overview = () => {
  const [mssg, setMssg] = useState({ error: false, mssg: "" })
  const [data, setData] = useState({
    estate: {}, zones: [], streets: []
  })

  const closeMssg = () => {
    setMssg({ error: false, mssg: "" })
  }
  useEffect(() => {
    const requestOptions = reqOptions('get', null, true)
    const estate_api = `${HOST_URL()}estates/estate_detail/${getEstateID()}`
    const streets_api = `${HOST_URL()}estates/estates_street_no_zone/?estate_id=${getEstateID()}`
    // const analytics_api = `${HOST_URL()}estate_users/estate_user_analytics/?estate_id=${getEstateID()}`
    const residents_api = `${HOST_URL()}estate_users/admin_list?estate_id=${getEstateID()}`
    Promise.all([
      fetch(estate_api, requestOptions),
      fetch(streets_api, requestOptions),
      // fetch(analytics_api, requestOptions),
      fetch(residents_api, requestOptions),
    ]).then(function (responses) {
      return Promise.all(responses.map(function (response) {
        return response.json();
      }));
    }).then(function (resp) {
      // console.log('resp', resp)
      setData({
        ...data,
        estate: resp[0],
        streets: resp[1].results,
        // analytics: resp[2],
        residents: resp[2].results
      });
    }).catch(function (error) {
      console.log('error at dashboard', error);
    });
  }, [])

  const handleSetResidents = (resident) => {
    setData({
      ...data, residents: data.residents.map(item => {
        if (item.id === resident.id) {
          return { ...item, status: resident.status }
        }
        return item
      })
    })
  }
  return (
    <WithAuth>
      {mssg.mssg && <MssgContainer mssg={mssg} close={closeMssg} />}
      <Onboarding />
      <AdminCards data={data} is_admin={true} />
      <LatestResidentsAndChart analytics={data && data.analytics} residents={data && data.residents && data.residents.slice(0, 3)} setResidents={handleSetResidents} />
      <Adverts />
    </WithAuth>
  );
};

export default Overview;
